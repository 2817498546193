<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleShelvesCamerasStores />
    <CardShelvesCamerasStores />
    <CardShelvesCamerasStoresSecond />
    <CareerShelvesCamerasStores />
    <CardShelvesCamerasStoresThird />
    <ClientsShelvesCamerasStores />
    <BlogShelvesCamerasStores />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../Layout/Navbar.vue";
import PageTitleShelvesCamerasStores from "../../../ShelvesByAICamerasInStores/PageTitleShelvesCamerasStores.vue";
import CardShelvesCamerasStores from "../../../ShelvesByAICamerasInStores/CardShelvesCamerasStores.vue";
import CardShelvesCamerasStoresSecond from "../../../ShelvesByAICamerasInStores/CardShelvesCamerasStoresSecond.vue";
import CareerShelvesCamerasStores from "../../../ShelvesByAICamerasInStores/CareerShelvesCamerasStores.vue";
import CardShelvesCamerasStoresThird from "../../../ShelvesByAICamerasInStores/CardShelvesCamerasStoresThird.vue";
import ClientsShelvesCamerasStores from "../../../ShelvesByAICamerasInStores/ClientsShelvesCamerasStores.vue";
import BlogShelvesCamerasStores from "../../../ShelvesByAICamerasInStores/BlogShelvesCamerasStores.vue";
import Footer from "../../../Layout/Footer";

export default {
  name: "ShelvesByAICamerasInStores",
  components: {
    Navbar,
    PageTitleShelvesCamerasStores,
    CardShelvesCamerasStores,
    CardShelvesCamerasStoresSecond,
    CareerShelvesCamerasStores,
    CardShelvesCamerasStoresThird,
    ClientsShelvesCamerasStores,
    BlogShelvesCamerasStores,
    Footer,
  },
};
</script>
