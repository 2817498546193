<template>
    <div>
      <Navbar class="bg-white" />
      <PageTitleForAcademyEntry/>
      <PIXAcademyForm  pageTitle="PIXSelect Academy Kayıt Formu" />
      <Footer />
    </div>
  </template>
  
  <script>
  import Navbar from "../Layout/Navbar";
  import PageTitleForAcademyEntry from "../PIXSelectAcademy/PageTitleForAcademyEntry.vue";
  import PIXAcademyForm from "../PIXSelectAcademy/PIXAcademyForm.vue";
  import Footer from "../Layout/Footer";
  
  export default {
    name: "PIXAcademyEntry",
    components: {
      Navbar,
      PageTitleForAcademyEntry,
      PIXAcademyForm,
      Footer,
    },
  };
  </script>
  