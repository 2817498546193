<template>
  <div class="container pt-10">
    <div class="section-title section-title-xl pb-30">
      <h2>{{ $t("aiVisionTrainer.keyFeatures") }}</h2>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-6">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <div>
              <h5>{{ $t("aiVisionTrainer.dataLabeling") }}</h5>
              <p>
                {{ $t("aiVisionTrainer.dataLabelingTitle") }}
              </p>
              <br />
            </div>
          </div>

          <img
            src="../../assets/images/1.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <div>
              <h5>{{ $t("aiVisionTrainer.modelTraining") }}</h5>
              <p>
                {{ $t("aiVisionTrainer.modelTrainingTitle") }}
              </p>
              <br />
            </div>
          </div>
          <img
            src="../../assets/images/2.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <div>
              <h5>{{ $t("aiVisionTrainer.deployment") }}</h5>
              <p>
                {{ $t("aiVisionTrainer.deploymentTitle") }}
              </p>
              <br />
            </div>
          </div>
          <img
            src="../../assets/images/3.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <div>
              <h5>{{ $t("aiVisionTrainer.integration") }}</h5>
              <p>
                {{ $t("aiVisionTrainer.integrationTitle") }}
              </p>
              <br />
            </div>
          </div>
          <img
            src="../../assets/images/4.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "AiDeveloperAwaitYou",
};
</script>
  