<template>
  <div class="container">
    <!-- <div class="section-title section-title-xl">
      <h2>LATEST POSTS & COMPANY NEWS</h2>
    </div>

    <div class="row">
      <div class="col-12 col-md-12 col-lg-4 mb-20">
        <div class="home-page-latest-post-card">
          <img
            src="../../assets/images/homepage/latest-post-1.png"
            alt="shape"
          />
          <h5>OpenAI upgrades GPT-3 with impressive</h5>
          <p>
            OpenAI’s latest upgrade for GPT-3 has given the generalised
            language...
          </p>
          <div class="d-flex align-items-center">
            <img
              src="../../assets/images/homepage/calendar.png"
              alt="shape"
              class="mr-5"
            />
            <p class="m-0 p-0">03.12.2022</p>
            <img
              src="../../assets/images/homepage/file-img.png"
              alt="shape"
              class="ml-10 mr-5"
            />
            <p class="p-0 m-0">Artificial Intelligence</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-4 mb-20">
        <div class="home-page-latest-post-card">
          <img
            src="../../assets/images/homepage/latest-post-2.png"
            alt="shape"
          />
          <h5>OpenAI upgrades GPT-3 with impressive</h5>
          <p>
            OpenAI’s latest upgrade for GPT-3 has given the generalised
            language...
          </p>
          <div class="d-flex align-items-center">
            <img
              src="../../assets/images/homepage/calendar.png"
              alt="shape"
              class="mr-5"
            />
            <p class="m-0 p-0">03.12.2022</p>
            <img
              src="../../assets/images/homepage/file-img.png"
              alt="shape"
              class="ml-10 mr-5"
            />
            <p class="p-0 m-0">Artificial Intelligence</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-4 mb-20">
        <div class="home-page-latest-post-card">
          <img
            src="../../assets/images/homepage/latest-post-3.png"
            alt="shape"
          />
          <h5>OpenAI upgrades GPT-3 with impressive</h5>
          <p>
            OpenAI’s la.test upgrade for GPT-3 has given the generalised
            language....
          </p>
          <div class="d-flex align-items-center">
            <img
              src="../../assets/images/homepage/calendar.png"
              alt="shape"
              class="mr-5"
            />
            <p class="m-0 p-0">03.12.2022</p>
            <img
              src="../../assets/images/homepage/file-img.png"
              alt="shape"
              class="ml-10 mr-5"
            />
            <p class="p-0 m-0">Artificial Intelligence</p>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "LatestPosts",
};
</script>
