<template>
  <div class="service-section pt-min-90 pb-70 bg-off-white">
    <div class="container">
      <br />
      <div class="section-title section-title-lg">
        <h2>{{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.title") }}</h2>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h5>{{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.softwareInd.title") }}</h5>
            </div>
            <div class="academy-card-brief">
              <p>
                {{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.softwareInd.info") }}
              </p>
              <div style="
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    top: 165px;
                    left: 363px;
                  ">
                <img src="../../assets/images/1.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h5>{{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.talents.title") }}</h5>
            </div>
            <div class="academy-card-brief">
              <p>
                {{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.talents.info") }}
              </p>
              <div style="
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    top: 165px;
                    left: 363px;
                  ">
                <img src="../../assets/images/2.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h5>{{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.delegateProcess.title") }}</h5>
            </div>
            <div class="academy-card-brief">
              <p>
                {{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.delegateProcess.info1") }}
              
                {{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.delegateProcess.info2") }}
              
                {{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.delegateProcess.info3") }}
              </p>
              <div style="
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    top: 165px;
                    left: 363px;
                  ">
                <img src="../../assets/images/3.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h5>{{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.alumniNetwork.title") }}</h5>
            </div>
            <div class="academy-card-brief">
              <p>
                {{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.alumniNetwork.info") }}
              </p>
              <div style="
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    top: 165px;
                    left: 363px;
                  ">
                <img src="../../assets/images/4.png" alt="shape" />
              </div>
            </div>
          </div>
        </div> <br><br>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h5>{{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.bootcamp.title") }}</h5>
            </div>
            <div class="academy-card-brief">
              <p>
                {{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.bootcamp.info") }}
              </p>
              <div style="
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    top: 165px;
                    left: 363px;
                  ">
                <img src="../../assets/images/5.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h5>{{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.cvPools.title") }}</h5>
            </div>
            <div class="academy-card-brief">
              <p>
                {{ $t("pixAcad.pixCoding.frontBackTraining.cartAcademy.cvPools.info") }}
              </p>
              <div style="
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    top: 165px;
                    left: 363px;
                  ">
                <img src="../../assets/images/6.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>
