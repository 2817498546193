<template>
    <div class="container pt-60 pb-60">
      <div class="section-title section-title-lg">
        <h2>{{ $t("solutionByIndustries.finance.cardPricing.title") }}</h2>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="pricing-card-visionAi-img">
            <img src="../../assets/images/pricing-1.png" alt="shape" />
          </div>
        </div>
        <div class="col-lg-6 p-0">
          <div class="pricing-card-visionAi">
            <div class="pricing-card-visionAi-title">
              <h5>
                {{ $t("solutionByIndustries.finance.cardPricing.bank") }}
              </h5>
            </div>
          </div>
          <div class="pricing-card-visionAi-list pt-20">
            <div class="pricing-card-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                {{ $t("solutionByIndustries.finance.cardPricing.bank1") }}
              </p>
            </div>

            <div class="pricing-card-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                {{ $t("solutionByIndustries.finance.cardPricing.bank2") }}
              </p>
            </div>
            <div class="pricing-card-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                {{ $t("solutionByIndustries.finance.cardPricing.bank3") }}
              </p>
            </div>
            <div class="pricing-card-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                {{ $t("solutionByIndustries.finance.cardPricing.bank4") }}
              </p>
            </div>
            <div class="pricing-card-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                {{ $t("solutionByIndustries.finance.cardPricing.bank5") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Pricing",
};
</script>
