<template>
<div class="container">
        <div class="section-title section-title-lg">
        <h2>{{ $t("pixAcad.pixCoding.frontBackTraining.academyBlog.title") }}</h2>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h3>{{ $t("pixAcad.pixCoding.frontBackTraining.academyBlog.title1") }}</h3>
            </div>
            <div class="academy-card-brief">
              <p>
                {{ $t("pixAcad.pixCoding.frontBackTraining.academyBlog.info1") }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h3>{{ $t("pixAcad.pixCoding.frontBackTraining.academyBlog.title2") }}</h3>
            </div>
            <div class="academy-card-brief">
              <p>
                {{ $t("pixAcad.pixCoding.frontBackTraining.academyBlog.info2") }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h3>{{ $t("pixAcad.pixCoding.frontBackTraining.academyBlog.title3") }}</h3>
            </div>
            <div class="academy-card-brief">
              <p>{{ $t("pixAcad.pixCoding.frontBackTraining.academyBlog.info3") }}
              </p>
            </div>
          </div>
        </div>


  
      </div>
</div>
      
</template>

<script>
export default {

}
</script>

<style>

</style>