<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleShelvesCamerasWarehouses />
    <CardShelvesCamerasWarehouses />
    <CardShelvesCamerasWarehousesSecond />
    <CareerShelvesCamerasWarehouses />
    <CardShelvesCamerasWarehousesThird />
    <ClientsShelvesCamerasWarehouses />
    <BlogShelvesCamerasWarehouses />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../Layout/Navbar.vue";
import PageTitleShelvesCamerasWarehouses from "../../../ShelvesByAICamerasInWarehouses/PageTitleShelvesCamerasWarehouses.vue";
import CardShelvesCamerasWarehouses from "../../../ShelvesByAICamerasInWarehouses/CardShelvesCamerasWarehouses.vue";
import CardShelvesCamerasWarehousesSecond from "../../../ShelvesByAICamerasInWarehouses/CardShelvesCamerasWarehousesSecond.vue";
import CareerShelvesCamerasWarehouses from "../../../ShelvesByAICamerasInWarehouses/CareerShelvesCamerasWarehouses.vue";
import CardShelvesCamerasWarehousesThird from "../../../ShelvesByAICamerasInWarehouses/CardShelvesCamerasWarehousesThird.vue";
import ClientsShelvesCamerasWarehouses from "../../../ShelvesByAICamerasInWarehouses/ClientsShelvesCamerasWarehouses.vue";
import BlogShelvesCamerasWarehouses from "../../../ShelvesByAICamerasInWarehouses/BlogShelvesCamerasWarehouses.vue";
import Footer from "../../../Layout/Footer";

export default {
  name: "ShelvesByAICamerasInWarehouses",
  components: {
    Navbar,
    PageTitleShelvesCamerasWarehouses,
    CardShelvesCamerasWarehouses,
    CardShelvesCamerasWarehousesSecond,
    CareerShelvesCamerasWarehouses,
    CardShelvesCamerasWarehousesThird,
    ClientsShelvesCamerasWarehouses,
    BlogShelvesCamerasWarehouses,
    Footer,
  },
};
</script>
