<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/goods-barcode-warehouses/photo-card-1.png"
          alt="shape"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>
              {{ $t( "products.PIXAIASol.pixCountGoodsByBarcodeAndQR.inWarehouses.cardGoodsBar.question1" )}}
            </h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              {{ $t( "products.PIXAIASol.pixCountGoodsByBarcodeAndQR.inWarehouses.cardGoodsBar.aswer1-1" )}}
            </p>
            <br />
            <p>
              {{ $t( "products.PIXAIASol.pixCountGoodsByBarcodeAndQR.inWarehouses.cardGoodsBar.aswer1-2" )}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>
              {{ $t( "products.PIXAIASol.pixCountGoodsByBarcodeAndQR.inWarehouses.cardGoodsBar.question2" )}}
            </h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              {{ $t( "products.PIXAIASol.pixCountGoodsByBarcodeAndQR.inWarehouses.cardGoodsBar.aswer2-1" )}}
            </p>
            <br />
            <p>
              {{ $t( "products.PIXAIASol.pixCountGoodsByBarcodeAndQR.inWarehouses.cardGoodsBar.aswer2-2" )}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/goods-cameras-warehouses/photo-card-2.png"
          alt="shape"
          style="padding: 0px 0px 0px 18px"
        />
      </div>
    </div>

    <!-- <div class="row pt-60">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/goods-cameras-warehouses/photo-card-3.png"
          alt="shape"
          style="padding: 0px 18px 0px 0px"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="text">
            <p>
              Another problem is when accepting goods manually, a warehouse
              worker has to stand and monitor all pallettes, packages. This is
              sometimes a problematic process; i.e your worker has many tasks at
              the same time. So entire counting goods processes may not be
              completed with your warehouse workers. Also sometimes because of
              trust, no one may stand at work during these processes.
            </p>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row pt-60 pb-60">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/goods-cameras-warehouses/photo-card-3.png"
          alt="shape"
          style="padding: 0px 18px 0px 0px"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6 d-grid justify-content-center">
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-1.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t( "products.PIXAIASol.pixCountGoodsByBarcodeAndQR.inWarehouses.cardGoodsBar.info-1" )}}
              </h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-2.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t( "products.PIXAIASol.pixCountGoodsByBarcodeAndQR.inWarehouses.cardGoodsBar.info-2" )}}
              </h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-3.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t( "products.PIXAIASol.pixCountGoodsByBarcodeAndQR.inWarehouses.cardGoodsBar.info-3" )}}
              </h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-4.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t( "products.PIXAIASol.pixCountGoodsByBarcodeAndQR.inWarehouses.cardGoodsBar.info-4" )}}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardGoodsBarcodeWarehouses",
};
</script>
