<template>
  <div class="container pt-60">
    <div class="section-title section-title-xl pb-30">
      <h2>{{  $t('partners.authorizedResellers.title') }}</h2>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features">
            <h5>{{  $t('partners.authorizedResellers.title1') }}</h5>
            <p>
              {{  $t('partners.authorizedResellers.info1') }}
            </p>
          </div>
          <img
            src="../../assets/images/1.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features">
            <h5>{{  $t('partners.authorizedResellers.title2') }}</h5>
            <p>
              {{  $t('partners.authorizedResellers.info2') }}
            </p>
          </div>
          <img
            src="../../assets/images/2.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features">
            <h5>{{  $t('partners.authorizedResellers.title3') }}</h5>
            <p>
              {{  $t('partners.authorizedResellers.info3') }}
            </p>
          </div>
          <img
            src="../../assets/images/3.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>

    <div class="row pb-30">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features">
            <h5>{{  $t('partners.authorizedResellers.title4') }}</h5>
            <p>
              {{  $t('partners.authorizedResellers.info4') }}
            </p>
          </div>
          <img
            src="../../assets/images/4.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features">
            <h5>{{  $t('partners.authorizedResellers.title5') }}</h5>
            <p>
              {{  $t('partners.authorizedResellers.info5') }}
            </p>
          </div>
          <img
            src="../../assets/images/5.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features">
            <h5>{{  $t('partners.authorizedResellers.title6') }}</h5>
            <p>
              {{  $t('partners.authorizedResellers.info6') }}
            </p>
          </div>
          <img
            src="../../assets/images/6.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BenefitsResellers",
};
</script>
