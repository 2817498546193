<template>
  <div class="faq-section pt-100 pb-70">
    <div class="section-title section-title-xl pb-30">
      <h2>{{ $t("aiVisionTrainer.FAQ") }}</h2>
    </div>
    <div class="container">
      <accordion>
        <div class="row">
          <div class="col-lg-6 pb-30">
            <accordion-item>
              <template v-slot:accordion-trigger>
                <button class="accordion-title">
                  {{ $t("aiVisionTrainer.whatisAIVISIONTRAINER") }}
                </button>
              </template>
              <template v-slot:accordion-content>
                <div class="accordion-body">
                  {{ $t("aiVisionTrainer.aIVISIONTRAINERisaplatform") }}
                </div>
              </template>
            </accordion-item>
            <accordion-item>
              <template v-slot:accordion-trigger>
                <button class="accordion-title">
                  {{ $t("aiVisionTrainer.doNeedTechnicalKnowledge") }}
                </button>
              </template>
              <template v-slot:accordion-content>
                <div class="accordion-body">
                  {{ $t("aiVisionTrainer.aIVISIONTRAINERhasauserfriendly") }}
                </div>
              </template>
            </accordion-item>
            <accordion-item>
              <template v-slot:accordion-trigger>
                <button class="accordion-title">
                  {{ $t("aiVisionTrainer.whichDataTypescanI") }}
                </button>
              </template>
              <template v-slot:accordion-content>
                <div class="accordion-body">
                  {{ $t("aiVisionTrainer.aIVISIONTRAINERAallowsYouToWork") }}
                </div>
              </template>
            </accordion-item>
          </div>
          <div class="col-lg-6 pb-30">
            <accordion-item>
              <template v-slot:accordion-trigger>
                <button class="accordion-title">
                  {{ $t("aiVisionTrainer.canIUseAIVISIONTRAINER") }}
                </button>
              </template>
              <template v-slot:accordion-content>
                <div class="accordion-body">
                  {{ $t("aiVisionTrainer.aIVISIONTRAINEROffersFreeTrial") }}
                </div>
              </template>
            </accordion-item>
            <accordion-item>
              <template v-slot:accordion-trigger>
                <button class="accordion-title">
                  {{ $t("aiVisionTrainer.howCanIEvaluateResults") }}
                </button>
              </template>
              <template v-slot:accordion-content>
                <div class="accordion-body">
                  {{ $t("aiVisionTrainer.aIVISIONTRAINERProvidesMetrics") }}
                </div>
              </template>
            </accordion-item>
            <accordion-item>
              <template v-slot:accordion-trigger>
                <button class="accordion-title">
                  {{ $t("aiVisionTrainer.howCanITrainAnObject") }}
                </button>
              </template>
              <template v-slot:accordion-content>
                <div class="accordion-body">
                  {{ $t("aiVisionTrainer.aIVISIONTRAINERMakes") }}
                </div>
              </template>
            </accordion-item>
          </div>
        </div>
      </accordion>
    </div>
  </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
  name: "Faq",
  components: {
    Accordion,
    AccordionItem,
  },
};
</script>