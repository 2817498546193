<template>
  <div class="container pt-60 pb-60">
    <div class="section-title section-title-lg">
      <h2>
        {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.title')}}
      </h2>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 pb-30">
        <div class="academy-card">
          <div class="academy-card-title">
            <h3>
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.service1-1')}}
            </h3>
          </div>
          <div class="academy-card-brief">
            <p>
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.service1-2')}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 pb-30">
        <div class="academy-card">
          <div class="academy-card-title">
            <h3>
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.service2-1')}}
            </h3>
          </div>
          <div class="academy-card-brief">
            <p>
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.service2-2')}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 pb-30">
        <div class="academy-card">
          <div class="academy-card-title">
            <h3>
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.service3-1')}}
            </h3>
          </div>
          <div class="academy-card-brief">
            <p>
              - {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.service3-2.1')}} <br />
              - {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.service3-2.2')}} <br />
              - {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.service3-2.3')}}
            </p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4 pb-30">
        <div class="academy-card">
          <div class="academy-card-title">
            <h3>
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.service4-1')}}
            </h3>
          </div>
          <div class="academy-card-brief">
            <p>
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.service4-2')}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 pb-30">
        <div class="academy-card">
          <div class="academy-card-title">
            <h3>
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.service5-1')}}
            </h3>
          </div>
          <div class="academy-card-brief">
            <p>
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.service5-2')}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 pb-30">
        <div class="academy-card">
          <div class="academy-card-title">
            <h3>
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.service6-1')}}
            </h3>
          </div>
          <div class="academy-card-brief">
            <p>
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.serviceOfferings.service6-2')}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogBankCctv",
};
</script>
