<template>
  <div class="pb-130">
    <!-- <div class="retail-section-card-our-solution mb-80">
      <div class="container pt-60">
        <div class="section-title section-title-xl">
          <h2>
            {{ $t("pixAcad.pixAI.AI-Developer.aiDeveloperContributions.title1") }}<br />
            {{ $t("pixAcad.pixAI.AI-Developer.aiDeveloperContributions.title2") }}
            <br />
            {{ $t("pixAcad.pixAI.AI-Developer.aiDeveloperContributions.title3") }}
          </h2>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8 pb-30">
            <div class="contact-info-item mb-40">
              <div class="goods-ai-warehouses-info-header">
                <img
                  src="../../assets/images/goods-cameras-warehouses/difference-1.png"
                  alt="shape"
                />
                <div class="goods-ai-warehouses-info-header-text">
                  <h6>
                    {{ $t("pixAcad.pixAI.AI-Developer.aiDeveloperContributions.info1") }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="contact-info-item mb-40">
              <div class="goods-ai-warehouses-info-header">
                <img
                  src="../../assets/images/goods-cameras-warehouses/difference-2.png"
                  alt="shape"
                />
                <div class="goods-ai-warehouses-info-header-text">
                  <h6>
                    {{ $t("pixAcad.pixAI.AI-Developer.aiDeveloperContributions.info2") }}
                  </h6>
                </div>
              </div>
            </div>
            <div class="contact-info-item mb-40">
              <div class="goods-ai-warehouses-info-header">
                <img
                  src="../../assets/images/goods-cameras-warehouses/difference-3.png"
                  alt="shape"
                />
                <div class="goods-ai-warehouses-info-header-text">
                  <h6>{{ $t("pixAcad.pixAI.AI-Developer.aiDeveloperContributions.info3") }}</h6>
                </div>
              </div>
            </div>
            <div class="contact-info-item">
              <div class="goods-ai-warehouses-info-header">
                <img
                  src="../../assets/images/goods-cameras-warehouses/difference-4.png"
                  alt="shape"
                />
                <div class="goods-ai-warehouses-info-header-text">
                  <h6>
                    {{ $t("pixAcad.pixAI.AI-Developer.aiDeveloperContributions.info4") }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <BlogAiDeveloper />
  </div>
</template>

<script>
import BlogAiDeveloper from "../SingleBlog/BlogAiDeveloper.vue";
export default {
  name: "AiDeveloperContributions",
  components: {
    BlogAiDeveloper,
  },
};
</script>
