<template>
  <div class="home-page-header">
    <div class="container">
      <div class="partner-carousel">
        <carousel :autoplay="4000" :settings="settings" :wrap-around="true">
          <slide v-for="slide in carouselItems" :key="slide.id">
            <div class="row">
              <div
                class="col-12 col-md-6 col-lg-6 d-flex justify-content-start"
              >
                <img class="home-page-header-img" :src="slide.image" />
              </div>
              <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                <div class="inner-header-content-home">
                  <h1>
                    {{ slide.header1 }}
                    <br />
                    {{ slide.header2 }}
                  </h1>
                  <br />
                  <h3>
                    {{ slide.text1 }}
                    {{ slide.text2 }}
                    {{ slide.text3 }}
                  </h3>
                  <br />
                  <div class="pt-30 d-flex">
                    <router-link :to="slide.url" class="btn main-btn-academy">
                      {{ slide.btnTitle }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";
import i18n from "../../plugins/i18n";

export default defineComponent({
  name: "MainBanner",
  components: {
    Carousel,
    Slide,
  },
  data: () => ({
    name: "aaa",
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    carouselItems: [
      {
        id: 1,
        image: require("../../assets/images/homepage/header.png"),
        header1: i18n.global.t('home.mainBanner.carouselItems.pixsvmsBankAndATM.header1'),
        header2: i18n.global.t('home.mainBanner.carouselItems.pixsvmsBankAndATM.header2'),
        text1: i18n.global.t('home.mainBanner.carouselItems.pixsvmsBankAndATM.text1'),
        text2: i18n.global.t('home.mainBanner.carouselItems.pixsvmsBankAndATM.text2'),
        text3: i18n.global.t('home.mainBanner.carouselItems.pixsvmsBankAndATM.text3'),
        url: i18n.global.t('home.mainBanner.carouselItems.pixsvmsBankAndATM.url'),
        btnTitle: i18n.global.t('home.mainBanner.carouselItems.pixsvmsBankAndATM.btnTitle'),
      },
      {
        id: 2,
        image: require("../../assets/images/homepage/header-2.png"),
        header1: i18n.global.t('home.mainBanner.carouselItems.pixsvmsWarehouseAndFactory.header1'),
        header2: i18n.global.t('home.mainBanner.carouselItems.pixsvmsWarehouseAndFactory.header2'),
        text1: i18n.global.t('home.mainBanner.carouselItems.pixsvmsWarehouseAndFactory.text1'),
        text2: i18n.global.t('home.mainBanner.carouselItems.pixsvmsWarehouseAndFactory.text2'),
        text3: i18n.global.t('home.mainBanner.carouselItems.pixsvmsWarehouseAndFactory.text3'),
        url: i18n.global.t('home.mainBanner.carouselItems.pixsvmsWarehouseAndFactory.url'),
        btnTitle: i18n.global.t('home.mainBanner.carouselItems.pixsvmsWarehouseAndFactory.btnTitle'),
      },
      {
        id: 3,
        image: require("../../assets/images/homepage/header-3.png"),
        header1: i18n.global.t('home.mainBanner.carouselItems.pixCountGoodsBy.header1'),
        header2: i18n.global.t('home.mainBanner.carouselItems.pixCountGoodsBy.header2'),
        text1: i18n.global.t('home.mainBanner.carouselItems.pixCountGoodsBy.text1'),
        text2: i18n.global.t('home.mainBanner.carouselItems.pixCountGoodsBy.text2'),
        text3: i18n.global.t('home.mainBanner.carouselItems.pixCountGoodsBy.text3'),
        url: i18n.global.t('home.mainBanner.carouselItems.pixCountGoodsBy.url'),
        btnTitle: i18n.global.t('home.mainBanner.carouselItems.pixCountGoodsBy.btnTitle'),
      },
      {
        id: 4,
        image: require("../../assets/images/homepage/header-4.png"),
        header1: i18n.global.t('home.mainBanner.carouselItems.aboutUs.header1'),
        header2: i18n.global.t('home.mainBanner.carouselItems.aboutUs.header2'),
        text1: i18n.global.t('home.mainBanner.carouselItems.aboutUs.text1'),
        text2: i18n.global.t('home.mainBanner.carouselItems.aboutUs.text2'),
        text3: i18n.global.t('home.mainBanner.carouselItems.aboutUs.text3'),
        url: i18n.global.t('home.mainBanner.carouselItems.aboutUs.url'),
        btnTitle: i18n.global.t('home.mainBanner.carouselItems.aboutUs.btnTitle'),
      },
    ],
  }),
});
</script>
