<template>
    <div class="inner-page-header pb-0">
      <div class="container">
        <div class="row">
          <div class="col-sm d-flex align-items-center">
            <div class="inner-header-content-academy">
              <h1>{{ $t("pixAcad.pixAI.SAAS-CV-Member.pageTitle.title") }}</h1>
              <h2>{{ $t("pixAcad.pixAI.SAAS-CV-Member.pageTitle.title2") }}</h2>
              <br />
              <h3>{{ $t("pixAcad.pixAI.SAAS-CV-Member.pageTitle.info") }}</h3>
              <br />
              <!-- <div>
                <router-link
                  to="/pixacademy-registration"
                  class="btn main-btn-academy"
                >
                {{ $t("pixAcad.pixAI.SAAS-CV-Member.pageTitle.joinNow") }}
                </router-link>
              </div> -->
            </div>
          </div>
          <div class="col-sm d-flex justify-content-end">
            <img
              class="goods-ai-warehouses-page-header-img"
              src="../../assets/images/academy1.png"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PageTitleSaasCvMembership",
  };
  </script>
  