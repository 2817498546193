<template>
  <div class="pt-60 pb-60 bg-off-white">
    <div class="container">
      <div class="section-title section-title-lg">
        <h2>
          {{ $t("solutionByIndustries.logistic.visionLogistic4.title") }}
        </h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase">
            <img src="../../assets/images/logistic/usecase-1.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase-title">
            <h4>
              {{ $t("solutionByIndustries.logistic.visionLogistic4.secLog.title") }}
            </h4>
          </div>
          <div class="logistic-card-usecase-problem">
            <div class="logistic-card-usecase-title">
              <h6>
                {{ $t("solutionByIndustries.logistic.visionLogistic4.secLog.problem") }}
              </h6>
            </div>
            <div class="logistic-card-usecase-security">
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic4.secLog.problemInfo") }}
              </p>
            </div>
          </div>
          <div class="logistic-card-usecase-solution">
            <div class="logistic-card-usecase-title">
              <h6>
                {{ $t("solutionByIndustries.logistic.visionLogistic4.secLog.solution") }}
              </h6>
            </div>
            <div class="logistic-card-usecase-security">
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic4.secLog.solutionInfo") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase">
            <img src="../../assets/images/logistic/usecase-2.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase-title">
            <h4>
              {{ $t("solutionByIndustries.logistic.visionLogistic4.rampBarc.title") }}
            </h4>
          </div>
          <div class="logistic-card-usecase-problem">
            <div class="logistic-card-usecase-title">
              <h6>
                {{ $t("solutionByIndustries.logistic.visionLogistic4.rampBarc.problem") }}
              </h6>
            </div>
            <div class="logistic-card-usecase-ramp-barcode">
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic4.rampBarc.problemInfo") }}
              </p>
            </div>
          </div>
          <div class="logistic-card-usecase-solution">
            <div class="logistic-card-usecase-title">
              <h6>
                {{ $t("solutionByIndustries.logistic.visionLogistic4.rampBarc.solution") }}
              </h6>
            </div>
            <div class="logistic-card-usecase-ramp-barcode">
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic4.rampBarc.solutionInfo") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase">
            <img src="../../assets/images/logistic/usecase-3.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase-title">
            <h4>
              {{ $t("solutionByIndustries.logistic.visionLogistic4.rampAı.title") }}
            </h4>
          </div>
          <div class="logistic-card-usecase-problem">
            <div class="logistic-card-usecase-title">
              <h6>
                {{ $t("solutionByIndustries.logistic.visionLogistic4.rampAı.problem") }}
              </h6>
            </div>
            <div class="logistic-card-usecase-ramp-cameras">
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic4.rampAı.problemInfo") }}
              </p>
            </div>
          </div>
          <div class="logistic-card-usecase-solution">
            <div class="logistic-card-usecase-title">
              <h6>
                {{ $t("solutionByIndustries.logistic.visionLogistic4.rampAı.solution") }}
              </h6>
            </div>
            <div class="logistic-card-usecase-ramp-cameras">
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic4.rampAı.solutionInfo1") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase">
            <img src="../../assets/images/logistic/usecase-4.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase-title">
            <h4>{{ $t("solutionByIndustries.logistic.visionLogistic4.wareOp.title") }}</h4>
          </div>
          <div class="logistic-card-usecase-problem">
            <div class="logistic-card-usecase-title">
              <h6>{{ $t("solutionByIndustries.logistic.visionLogistic4.wareOp.problem") }}</h6>
            </div>
            <div class="logistic-card-usecase-warehouse">
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic4.wareOp.problemInfo") }}.
              </p>
            </div>
          </div>
          <div class="logistic-card-usecase-solution">
            <div class="logistic-card-usecase-title">
              <h6>{{ $t("solutionByIndustries.logistic.visionLogistic4.wareOp.solution") }}</h6>
            </div>
            <div class="logistic-card-usecase-warehouse">
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic4.wareOp.solutionInfo1") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogisticUseCase",
};
</script>
