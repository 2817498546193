<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleGoodsAIRamps />
    <CardGoodsCamerasRamps />
    <CardGoodsCamerasRampsSecond />
    <CareerGoodsCamerasRamps />
    <CardGoodsCamerasRampsThird />
    <ClientsGoodsCamerasRamps />
    <BlogGoodsCamerasRamps />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../Layout/Navbar.vue";
import PageTitleGoodsAIRamps from "../../../GoodsByAICamerasOnRamps/PageTitleGoodsAIRamps.vue";
import CardGoodsCamerasRamps from "../../../GoodsByAICamerasOnRamps/CardGoodsCamerasRamps.vue";
import CardGoodsCamerasRampsSecond from "../../../GoodsByAICamerasOnRamps/CardGoodsCamerasRampsSecond.vue";
import CareerGoodsCamerasRamps from "../../../GoodsByAICamerasOnRamps/CareerGoodsCamerasRamps.vue";
import CardGoodsCamerasRampsThird from "../../../GoodsByAICamerasOnRamps/CardGoodsCamerasRampsThird.vue";
import ClientsGoodsCamerasRamps from "../../../GoodsByAICamerasOnRamps/ClientsGoodsCamerasRamps.vue";
import BlogGoodsCamerasRamps from "../../../GoodsByAICamerasOnRamps/BlogGoodsCamerasRamps.vue";
import Footer from "../../../Layout/Footer";

export default {
  name: "GoodsByAICamerasOnRamps",
  components: {
    Navbar,
    PageTitleGoodsAIRamps,
    CardGoodsCamerasRamps,
    CardGoodsCamerasRampsSecond,
    CareerGoodsCamerasRamps,
    CardGoodsCamerasRampsThird,
    ClientsGoodsCamerasRamps,
    BlogGoodsCamerasRamps,
    Footer,
  },
};
</script>
