<template>
  <div class="container pt-60">
    <div class="section-title section-title-lg">
      <h2>
        {{  $t( "products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.clientsWarehouses.title" ) }}
      </h2>
    </div>
    <div class="row count-people-info-text pb-60">
      <div class="col-12 col-md-4 col-lg pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/turnstiles-id-cards/photo-1.png"
            alt="partner"
          />
          <h6>
            {{  $t( "products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.clientsWarehouses.buildingEntrances" ) }}
          </h6>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/turnstiles-id-cards/photo-2.png"
            alt="partner"
          />
          <h6>
            {{  $t( "products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.clientsWarehouses.plazas" ) }}
          </h6>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/turnstiles-id-cards/photo-3.png"
            alt="partner"
          />
          <h6>
            {{  $t( "products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.clientsWarehouses.factoryEntrances" ) }}
          </h6>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/turnstiles-id-cards/photo-4.png"
            alt="partner"
          />
          <h6>
            {{  $t( "products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.clientsWarehouses.technoparkAreas" ) }}
          </h6>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/turnstiles-id-cards/photo-5.png"
            alt="partner"
          />
          <h6>
            {{  $t( "products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.clientsWarehouses.freeZoneAreas" ) }}
          </h6>
        </div>
      </div>
    </div>
    <BlogCountPeople />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BlogCountPeople from "../SingleBlog/BlogCountPeople.vue";

export default defineComponent({
  name: "ClientsTurnstilesIDCards",
  components: {
    BlogCountPeople,
  },
});
</script>
