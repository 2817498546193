<template>
    <div class="container pb-30">
        <div class="row">
            <div class="section-image">
                <img style="max-width: 100%;" src="../../assets/images/aivisiontrainer.png" alt="shape" />
            </div>
        </div>
    </div>
    <div class="retail-section-card-our-solution">
        <div class="container pb-30">
            <div class="row">
                <div class="col-12">
                    <div class="saas-info-card">

                        <h5>{{ $t("aiVisionTrainer.AIProgrammeOffer") }} </h5>

                        <h5>{{ $t("aiVisionTrainer.thanksToThisInnovative") }}</h5>

                        <h5> {{ $t("aiVisionTrainer.prepareYourTeams") }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "SaasEmployersCard",
};
</script>
  