<template>
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Terms of
                    Service
                </h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-start">
                <div class="all">
                    <div class="row">
                        <!-- <h4>Terms of Service </h4> -->
                        <h5>Your Agreement to these Terms of Service. </h5>
                    </div>
                    <div class="row">
                        <p>Welcome to the PIXSelect website and service (collectively, the “Service”). These Terms of
                            Use for the
                            Service is a legal contract between you, an individual user or a single entity, and
                            PIXSelect regarding
                            your use of the Service. The terms “you,” “your,” and “yours” refer to you, the user of the
                            Service. The
                            terms “PIXSelect”, “Company”, “we”, “us”, and “our” refer to PIXSelect Technology Inc. We
                            may make
                            changes to these Terms of Use. It is your responsibility to review the most recent version
                            of these
                            Terms of Use frequently and remain informed of any changes to it. You agree that your
                            continued use of
                            the Service after such changes have been published to the Service shall constitute your
                            acceptance of
                            such revised Terms of Use. </p>
                    </div>
                    <div class="row">
                        <p>PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY. BY REGISTERING FOR, ACCESSING, BROWSING,
                            DOWNLOADING,
                            OR USING THE SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD, AND AGREE TO BE
                            BOUND BY, THE
                            FOLLOWING TERMS AND CONDITIONS, INCLUDING ANY ADDITIONAL GUIDELINES AND FUTURE MODIFICATIONS
                            (COLLECTIVELY, THE “TERMS OF USE” OR “TERMS”). IF AT ANY TIME YOU DO NOT AGREE TO THESE
                            TERMS, PLEASE
                            IMMEDIATELY TERMINATE YOUR USE OF THE SERVICE. </p>
                    </div>
                    <div class="row">
                        <h5>License to Use the Service </h5>
                        <div class="row">
                            <p>Subject to these Terms of Use, we grant to you a limited, personal, non-exclusive,
                                non-transferable
                                license to use the Service for your personal or internal business use and not for resale
                                or further
                                distribution. Commercial or public broadcast of any content is prohibited. Your right to
                                use the
                                Service
                                is limited by the terms set forth in these Terms of Use.</p>
                        </div>
                        <div class="row">
                            <p>
                                Except for this license granted to you, we retain all right, title, and interest in and
                                to the
                                Service,
                                including all related intellectual property rights. The Service is protected by
                                applicable
                                intellectual
                                property laws, including United States copyright law and international treaties.
                            </p>
                        </div>
                        <div class="row"></div>
                        <p>
                            Except as otherwise explicitly provided in these Terms of Use or as may be expressly
                            permitted
                            by applicable law, you will not, and will not permit or authorize third parties to: (a)
                            reproduce,
                            modify, translate, enhance, decompile, disassemble, reverse engineer, or create derivative
                            works of the
                            Service; (b) rent, lease, or sublicense access to the Service; nor (c) circumvent or disable
                            any
                            security or technological features or measures of the Service, without the consent, and
                            agreement from
                            PIXSelect.
                        </p>
                    </div>
                    <div class="row">
                        <h5>Access to the Service; Modifications to the Service </h5>
                        <div class="row">
                            <p>
                                We do not provide you with the equipment to access the Service. You are responsible for
                                all fees
                                charged by third parties to access the Service (e. g., charges by internet service
                                providers). If
                                you are using the Service on behalf of a company, entity, or organization, then you
                                represent and
                                warrant that you: (a) are an authorized representative of that organization with the
                                authority to
                                bind such organization to these Terms and (b) agree to be bound by these Terms on behalf
                                of such
                                organization.
                            </p>
                        </div>
                        <div class="row">
                            <p>
                                We reserve the right to modify or discontinue, temporarily or permanently, all or a part
                                of the
                                Service without notice. We will not be liable to you or to any third party for any
                                modification,
                                suspension, or discontinuance of the Service. We reserve the right, in our sole
                                discretion, to
                                restrict, suspend, or terminate your access to the Service at any time, for any or no
                                reason, with
                                or without prior notice, and without liability. We reserve the right, at any time, to
                                change or
                                impose fees for access to and use of the Service.
                            </p>
                        </div>
                        <div class="row">
                            <p>
                                In order to use the Service, you must be the age of majority in your jurisdiction and
                                fully able and
                                competent to enter into the terms, conditions, obligations, affirmations,
                                representations and
                                warranties set forth in these Terms and to abide by and comply with these Terms. BY
                                DOWNLOADING,
                                INSTALLING OR OTHERWISE USING THE SERVICE, YOU REPRESENT THAT YOU MEET THE ELIGIBILITY
                                REQUIREMENTS
                                IN THIS SECTION. IN ANY CASE, YOU AFFIRM THAT YOU ARE OVER THE AGE OF 13, AS THE SERVICE
                                IS NOT
                                INTENDED FOR CHILDREN UNDER 13. </p>
                        </div>

                    </div>
                    <div class="row">
                        <h5>Content</h5>
                        <div class="row">
                            <p>
                                The Service allows you and other third parties to retrieve, process and/or access
                                information using
                                the Service, including media, text, audio and video recordings, photos, graphics,
                                commentary or any
                                other content (“Content”) and to host and/or share such Content.
                            </p>
                        </div>
                        <div class="row">
                            <p>
                                You are the owner of your Content and are solely responsible for your conduct and the
                                content of
                                your Content, as well as any of the content contained in your communications with other
                                users of the
                                Service, and you are solely responsible for the consequences of accessing, retrieving or
                                making
                                available such Content. You are solely responsible for maintaining and protecting all
                                data and
                                information that you access, retrieve or make available, or that is otherwise processed,
                                through the
                                Service. In connection with Content, you affirm, represent and warrant that: (i) you
                                own, or have
                                the necessary licenses, rights, consents, and permissions to enable use of the Content
                                in the manner
                                contemplated by the Service and these Terms, and (ii) your use or making available of
                                the Content on
                                or through the Service does not and will not: (A) infringe, violate, or misappropriate
                                any
                                third-party right, including any copyright, trademark, patent, trade secret, moral
                                right, privacy
                                right, right of publicity, or any other intellectual property or proprietary right, (B)
                                slander,
                                defame, libel, or invade the right of privacy, publicity or other property rights of any
                                other
                                person, or (C) violate any applicable law or regulation. You agree to pay all royalties,
                                license
                                fees and any other monies owing any person by reason of any Content accessed, retrieved,
                                made
                                available or that is otherwise processed, by you on or through the Service.
                            </p>
                        </div>
                        <div class="row">
                            <p>
                                Any opinions, advice, statements, services, offers, or other information that
                                constitutes part of
                                the Content expressed or made available on the Service are those of the respective
                                authors or
                                producers and not of the Company, or its stockholders, directors, officers, or
                                employees. Content is
                                not controlled by PIXSelect. PIXSelect does not guarantee the accuracy, integrity or
                                quality of
                                Content and makes no representations that your Content will remain accessible via the
                                Service in any
                                way and may terminate your access to the Service in its sole discretion. YOU UNDERSTAND
                                THAT
                                PIXSelect DOES NOT GUARANTEE ANY CONFIDENTIALITY WITH RESPECT TO ANY CONTENT. If at any
                                time,
                                PIXSelect chooses, in its sole discretion, to monitor Content, PIXSelect nonetheless
                                assumes no
                                responsibility for Content, no obligation to modify or remove any inappropriate Content,
                                and no
                                responsibility for the conduct of the user submitting any such Content. You agree that
                                you must
                                evaluate, and bear all risks associated with the use of any Content, including any
                                reliance on the
                                accuracy, completeness, usefulness, or legality of such Content.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Restrictions</h5>
                        <div class="row">
                            <p>
                                The Service is controlled and hosted in the United States of America. You must comply
                                with all
                                applicable laws, including U. S. export control laws, when using the Service. PIXSelect
                                makes no
                                representations that the Service is appropriate or available for use in other locations.
                                If you are
                                accessing or using the Service from other jurisdictions, you do so at your own risk and
                                you are
                                responsible for compliance with all applicable local laws. Except as may be expressly
                                permitted by
                                applicable law or authorized by the applicable holder of intellectual property rights to
                                any of the
                                Content made available on the Service, you will not, and will not permit anyone else to:
                                (a) store,
                                copy, modify, distribute, or resell any of the Content or compile or collect any Content
                                as part of
                                a database or other work; (b) use any automated tool (e. g., robots, spiders) to use the
                                Service or
                                store, copy, modify, distribute, or resell any Content; © rent, lease, or sublicense
                                your access to
                                the Service to another person; (d) use the Service or Content for any purpose except for
                                your own
                                personal or internal business use; (e) circumvent or disable any digital rights
                                management, usage
                                rules, or other security features of the Service; (f) use the Service in a manner that
                                threatens the
                                integrity, performance, or availability of the Service; or (g) remove, alter, or obscure
                                any
                                proprietary notices (including copyright notices) on any portion of the Service or
                                Content.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Privacy Policy</h5>
                        <div class="row">
                            <p>
                                Your privacy is important to PIXSelect. Our collection and use of registration and other
                                information
                                about you is governed by our Privacy Policy available at URL ………………………………….. Please read
                                this policy
                                carefully for information relating to PIXSelect collection, use, and disclosure of your
                                personal
                                information.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Links and Third Party Content</h5>
                        <div class="row">
                            <p>
                                The Service may contain links to third party products, services, and websites. We
                                exercise no
                                control over such third party products, services, and websites and we are not
                                responsible for their
                                performance, do not endorse them, and are not responsible or liable for any content,
                                advertising, or
                                other materials available through any third party products, services, and websites. We
                                are not
                                responsible or liable, directly or indirectly, for any damage or loss caused to you by
                                your use of
                                or reliance on any goods or services available through any third party products,
                                services, and
                                websites.
                            </p>
                        </div>
                        <div class="row">
                            <p>
                                ACCESS AND USE OF ANY THIRD PARTY PRODUCTS, SERVICES OR WEBSITES, INCLUDING THE
                                INFORMATION,
                                MATERIAL, PRODUCTS, AND SERVICES ON OR AVAILABLE THROUGH ANY THIRD PARTY PRODUCTS,
                                SERVICES OR
                                WEBSITES, IS SOLELY AT YOUR OWN RISK.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Account Information</h5>
                        <div class="row">
                            <p>
                                In order to access some features of the Service, you will have to create an account. You
                                represent
                                and warrant that the information you provide to PIXSelect upon registration and, at all
                                other times,
                                will be true, accurate, current, and complete. You also represent and warrant that you
                                will ensure
                                that this information is kept accurate and up-to-date at all times. You acknowledge,
                                consent, and
                                agree that PIXSelect may access, preserve and disclose your account information if
                                required to do so
                                by law or in a good faith belief that such access preservation or disclosure is
                                reasonably necessary
                                to (a) comply with legal process; (b) enforce the Terms; (c) respond to claim that any
                                Content
                                violates the rights of third parties; (d) provide certain features of the Service to
                                you, if any,
                                (e) respond if you contact PIXSelect for any reason; or (f) protect the rights,
                                property, or
                                personal safety of PIXSelect, other users of the Service, and the public.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Password</h5>
                        <div class="row">
                            <p>
                                If you create an account, you will be asked to provide a password. You agree that you
                                are entirely
                                responsible for maintaining the confidentiality of your password, and agree to
                                immediately notify
                                PIXSelect if the password is lost, stolen, disclosed to an unauthorized third party, or
                                otherwise
                                may have been compromised. You agree that you are entirely responsible for any and all
                                activities
                                that occur under your account, whether or not you are the individual who undertakes such
                                activities.
                                YOU MAY BE LIABLE FOR THE LOSSES INCURRED BY PIXSelect OR OTHERS DUE TO ANY UNAUTHORIZED
                                USE OF YOUR
                                ACCOUNT.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Submissions</h5>
                        <div class="row">
                            <p>
                                You agree that the Company is free to use any images, comments, information, ideas,
                                concepts,
                                reviews, or techniques or any other material contained in any communication you may send
                                to us
                                (each, a “Submission”), including, without limitation, responses to questionnaires or
                                through
                                postings to the Service without further compensation, acknowledgement or payment to you
                                for any
                                purpose whatsoever including, but not limited to, developing, manufacturing and
                                marketing products
                                and creating, modifying or improving the Service and our products and services.
                                Furthermore, by
                                posting any Submission on the Service, submitting information to us, or in responding to
                                questionnaires, you grant us a perpetual, non-exclusive, fully-paid, royalty-free,
                                irrevocable,
                                sublicensable, worldwide license and right to display, use, perform, reproduce, modify,
                                distribute
                                and create derivative works of the Submission or information submitted in any media,
                                software, or
                                technology of any kind now existing or developed in the future. By posting or providing
                                a Submission
                                or information, you affirm, represent and warrant that: (i) you own all right title and
                                interest in
                                your Submissions or otherwise have the right to grant the license set forth herein, and
                                (ii) the
                                posting of your Submissions on or through the Service does not (and will not) violate
                                the privacy
                                rights, publicity rights, copyrights, contract rights or any other rights of any person.
                                You agree
                                to pay all royalties, license fees and any other monies owing any person by reason of
                                any Submission
                                posted by you on or through the Service.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Use Restrictions</h5>
                        <div class="row">
                            <p>
                                You are solely responsible for any Content and other material that you submit, publish
                                or display on
                                the Service or transmit to other users of the Service.
                            </p>
                        </div>
                        <div class="row">
                            <p>
                                You will not use the Service to: (a) upload, post, publish, or otherwise transmit or
                                make available
                                any information, including media, text, audio and video recordings, photos, graphics,
                                commentary or
                                any other content, that is unlawful, harmful, threatening, abusive, harassing, tortious,
                                defamatory,
                                vulgar, obscene, libelous, invasive of another’s privacy, hateful, or racially,
                                ethnically or
                                otherwise objectionable; (b) harm the Company or third parties in any way; (c)
                                impersonate any
                                person or entity, or otherwise misrepresent your affiliation with a person or entity;
                                (d) upload,
                                post, publish, or otherwise transmit or make available any content that you do not have
                                a right to
                                transmit or make available under any law or under contractual or fiduciary relationships
                                (such as
                                inside information, proprietary and confidential information learned or disclosed as
                                part of
                                employment relationships or under nondisclosure agreements); (e) upload, post, email or
                                otherwise
                                transmit or make available any content that infringes any patent, trademark, trade
                                secret, copyright
                                or other proprietary right of any party; (f) upload, post, publish, or otherwise
                                transmit or make
                                available any unsolicited or unauthorized advertising, promotional materials, “junk
                                mail,” “spam,”
                                “chain letters,” “pyramid schemes,” or any other forms of solicitation; (g) upload,
                                post, publish,
                                or otherwise transmit or make available any material that contains software viruses or
                                any other
                                computer code, files, or programs designed to interrupt, destroy, or limit the
                                functionality of any
                                computer software or hardware or telecommunications equipment; (h) interfere with or
                                disrupt the
                                Service or servers or networks connected to the Service, or disobey any requirements,
                                procedures,
                                policies or regulations of networks connected to the Service; (i) attempt to probe,
                                scan, or test
                                the vulnerability of any system or network or breach any security or authentication
                                measures; (j)
                                intentionally or unintentionally violate any applicable local, state, national or
                                international law
                                or regulation; (k) “stalk” or otherwise harass another; (l) write comments that in any
                                way refer to
                                persons under 18 years of age or upload, post, email, or otherwise transmit or make
                                available any
                                material that would be harmful to minors in any manner; or collect or store personal
                                data about
                                other users.
                            </p>
                        </div>
                        <div class="row">
                            <p>
                                If you see content on the Service that violates these use restrictions, please contact
                                Company at
                                [ <a href="mailto:info@pixselect.io">info@pixselect.io</a> ].
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Disclaimer of Warranties</h5>
                        <div class="row">
                            <p>
                                YOUR USE OF THE SERVICE AND CONTENT IS AT YOUR SOLE RISK. THE SERVICE AND CONTENT ARE
                                PROVIDED ON AN
                                “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO
                                APPLICABLE LAW, THE
                                COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                                INCLUDING, BUT
                                NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                                PURPOSE, TITLE,
                                AND NON-INFRINGEMENT. WE DO NOT GUARANTEE THE ACCURACY, COMPLETENESS, OR USEFULNESS OF
                                THE SERVICE
                                OR CONTENT, AND YOU RELY ON THE SERVICE AND CONTENT AT YOUR OWN RISK. ANY CONTENT
                                OBTAINED THROUGH
                                THE SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE
                                FOR ANY
                                DAMAGE TO YOUR COMPUTER OR LOSS OF DATA THAT RESULTS FROM THE ACCESS, DOWNLOAD OR USE OF
                                ANY
                                INFORMATION, MATERIALS, OR DATA THROUGH THE SERVICE. THE COMPANY DOES NOT WARRANT THAT
                                ANY
                                INFORMATION, MATERIALS, OR DATA OFFERED ON OR THROUGH THE SERVICE WILL BE UNINTERRUPTED,
                                OR FREE OF
                                ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS AND DOES NOT WARRANT THAT ANY OF THE
                                FOREGOING WILL BE
                                CORRECTED. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE
                                COMPANY OR
                                THROUGH OR FROM THE SERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. SOME
                                STATES MAY
                                PROHIBIT A DISCLAIMER OF WARRANTIES AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM STATE
                                TO STATE.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Copyright Infringement</h5>
                        <div class="row">
                            <p>
                                If you believe that your work has been copied in a way that constitutes copyright
                                infringement,
                                please contact us at info@pixselect.io and provide the Company’s copyright agent, in
                                writing, the
                                following information required by the Digital Millennium Copyright Act, 17 U.S.C. § 512:
                                (i) an
                                electronic or physical signature of the person authorized to act on behalf of the owner
                                of the
                                copyright interest; (ii) a description of the copyrighted work that you claim has been
                                infringed,
                                including the URL (i. e., web page address) of the location where the copyrighted work
                                exists or a
                                copy of the copyrighted work; (iii) identification of the URL or other specific location
                                on the
                                Service where the material that you claim is infringing is located; (iv) your address,
                                telephone
                                number, and email address; (v) a statement by you that you have a good faith belief that
                                the
                                disputed use is not authorized by the copyright owner, its agent, or the law; and (vi) a
                                statement
                                by you, made under penalty of perjury, that the above information in your notice is
                                accurate and
                                that you are the copyright owner or authorized to act on the copyright owner’s behalf.
                                The Company’s
                                agent for notice of claims of copyright infringement on this site can be reached using
                                the contact
                                information below.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Indemnification</h5>
                        <div class="row">
                            <p>
                                You will indemnify and hold the Company, and its parent, subsidiaries, affiliates, and
                                the officers,
                                directors, agents, employees and representatives of any of them, harmless from any and
                                all claims,
                                losses, obligations, costs, damages, liabilities and expenses (including attorneys’
                                fees) caused by
                                your use or misuse of the Service or Content, your violation of these Terms of Use, your
                                violation
                                of any rights of a third party through use of the Service or Content, including claims
                                that any
                                Content infringes or violates any third party intellectual property rights, or any
                                unauthorized use
                                of your account not caused by the Company.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Updates to these Terms of Use</h5>
                        <div class="row">
                            <p>
                                We may occasionally update the Service and these Terms of Use. When we do, we will
                                revise the “last
                                updated” date on these Terms of Use. You should check the Service and these Terms of Use
                                frequently
                                to see recent changes. Your continued use of the Service after such changes will be
                                subject to the
                                then-current terms of use. This version of these Terms of Use shall supersede all
                                earlier versions.
                                The latest Terms of Use can be found at the following URL:
                                <a
                                    href="https://www.pixselect.io/terms-of-service/">https://www.pixselect.io/terms-of-service/</a>.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Legal Notices</h5>
                        <div class="row">
                            <p>
                                Enforcement of these Terms of Use will be governed by California law, excluding its
                                conflict and
                                choice of law principles. The exclusive jurisdiction and venue for any claims arising
                                out of or
                                related to these Terms of Use or your use of the Service will lie in the State and
                                Federal courts
                                located in Santa Clara County, California, and you irrevocably agree to submit to the
                                jurisdiction
                                of such courts. The failure of Company to enforce any right or provision in these Terms
                                of Use will
                                not constitute a waiver of such right or provision unless acknowledged and agreed to by
                                the Company
                                in writing. In the event that a court of competent jurisdiction finds any provision of
                                these Terms
                                of Use to be illegal, invalid or unenforceable, the remaining provisions will remain in
                                full force
                                and effect.
                            </p>
                        </div>
                        <div class="row">
                            <p>
                                The failure of PIXSelect to exercise or enforce any right or provision of these Terms
                                will not
                                constitute a waiver of such right or provision.
                            </p>
                        </div>
                        <div class="row">
                            <p>
                                If any provision of these Terms shall be unlawful, void, or for any reason
                                unenforceable, then that
                                provision shall be deemed severable from these Terms and shall not affect the validity
                                and
                                enforceability of any remaining provisions. These Terms are entire and exclusive
                                agreement between
                                you and PIXSelect relating to the subject matter herein and supersedes any prior
                                agreements between
                                PIXSelect and you regarding said subject matter.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Contacting PIXSelect</h5>
                        <div class="row">
                            <p>
                                If you have any questions or concerns about the Service or these Terms of Use, please
                                send us a
                                thorough description by email to solution.center@pixselect.io,
                            </p>
                        </div>
                        <div class="row">
                            <p>
                                The most recent copy of these terms can be found here: <a
                                    href="https://www.pixselect.io/terms-of-service/">https://www.pixselect.io/terms-of-service/</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>

</template>

<script>
export default {

}
</script>

<style scoped>
.all {
    color: black;
}

p {
    color: black;
    font-weight: normal !important;
    padding-left: 20px;
    text-align: justify;
}

ul {
    padding-left: 70px;
}

h5 {
    padding-left: 40px;
}
</style>
