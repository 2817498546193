<template>
  <div class="service-section pt-min-90 bg-off-white">
    <div class="container">
      <div class="section-title section-title-lg"> <br>
        <h2>{{ $t("pixAcad.pixCoding.frontBackTraining.careerAcademy") }}</h2>
      </div>
      <div class="about-section-item about-item-image text-center desk-pad-right-70">
        <div v-if="language == 'en'">
          <img src="../../assets/images/academy2.png" alt="shape" />
        </div>
        <div v-else>
          <img src="../../assets/images/academy2_tr.png" style="height: 450px;!important" alt="shape" />
        </div>

      </div><br>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
  data() {
    return {
      language: localStorage.getItem("language")
    }
  }
};
</script>
