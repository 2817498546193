<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
        <h2>{{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content1.title") }}</h2>
        <div class="goods-ai-warehouses-shema-text">
          <p>
            {{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content1.info") }}
          </p>
        </div>
      </div>
      <div class="row pb-60">
        <div class="col-12">
          <div v-if="language == 'en'">
            <img
            src="../../assets/images/capabilities.png"
            alt="shape"
          />
          </div>
          <div v-else>
            <img
            src="../../assets/images/capabilities.png"
            alt="shape"
          />
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaasEmployersCardSecond",
  data() {
    return {
      language: localStorage.getItem("language")
    }
  }
};
</script>
