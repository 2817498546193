<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl mb-0">
        <h2>{{ $t("pixAcad.pixCoding.itSupSpecialist.itSupportProgram.title") }}</h2>
        <div class="goods-ai-warehouses-shema-text">
          <p>
            {{ $t("pixAcad.pixCoding.itSupSpecialist.itSupportProgram.info") }}
          </p>
        </div>
      </div>
      <div class="row pt-60 pb-60">
        <div class="col-12 d-flex justify-content-center">
          <div v-if="language == 'en'">
            <img src="../../assets/images/it-support-special/photo-1.png" alt="shape" />
          </div>
          <div v-else>
            <img src="../../assets/images/it-support-special/photo-1_tr.png" style="width: 1100px;" alt="shape" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItSupportProgram",
  data() {
    return {
      language: localStorage.getItem("language")
    }
  }
};
</script>
<style scoped></style>
