<template>
  <div class="container pt-10">
    <div class="section-title section-title-xl pb-30">
      <h2>{{ $t("aiVisionTrainer.useCases") }}</h2>
    </div>

    <div class="row">
      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleBankingCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center"
            :class="{ 'banking-card': isBankingCardOpen }"
          >
            <div>
              <h5>{{ $t("aiVisionTrainer.banking") }}</h5>
              <p>
                {{ $t("aiVisionTrainer.bankingTitle") }}
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleRetailCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center"
            :class="{ 'retail-card': isRetailCardOpen }"
          >
            <div>
              <h5>{{ $t("aiVisionTrainer.retail") }}</h5>
              <p>
                {{ $t("aiVisionTrainer.retailTitle") }}
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleLogisticsCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center"
            :class="{ 'logistics-card': isLogisticsCardOpen }"
          >
            <div>
              <h5>{{ $t("aiVisionTrainer.logistics") }}</h5>
              <p>
                {{ $t("aiVisionTrainer.logisticsTitle") }}
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <b-collapse class="mt-2" v-model="isBankingCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #56c5b2">{{ $t("aiVisionTrainer.fraudandSecurityMonitoring") }}</h5>
                  <p>
                    {{ $t("aiVisionTrainer.fraudandSecurityMonitoringTitle") }}
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #56c5b2"> {{ $t("aiVisionTrainer.documentandAuthentication") }}</h5>
                  <p>
                    {{ $t("aiVisionTrainer.documentandAuthenticationTitle") }}
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/2.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #56c5b2">
                    {{ $t("aiVisionTrainer.ATMandBranchTrafficManagement") }}
                  </h5>
                  <p>
                    {{ $t("aiVisionTrainer.ATMandBranchTrafficManagementTitle") }}
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/3.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <b-collapse class="mt-2" v-model="isRetailCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #ba4cf9">
                    {{ $t("aiVisionTrainer.productStockandShelfManagement") }}
                  </h5>
                  <p>
                    {{ $t("aiVisionTrainer.productStockandShelfManagementTitle") }}
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #ba4cf9">
                    {{ $t("aiVisionTrainer.personalizedProductRecommendations") }}
                  </h5>
                  <p>
                    {{ $t("aiVisionTrainer.personalizedProductRecommendationsTitle") }}
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/2.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #ba4cf9">{{ $t("aiVisionTrainer.theftandFraudPrevention") }}</h5>
                  <p>
                    {{ $t("aiVisionTrainer.theftandFraudPreventionTitle") }}
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/3.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #ba4cf9">
                    {{ $t("aiVisionTrainer.productandShelfArrangementOptimization") }}
                  </h5>
                  <p>
                    {{ $t("aiVisionTrainer.productandShelfArrangementOptimizationTitle") }}
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/4.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #ba4cf9">
                    {{ $t("aiVisionTrainer.cashAutomationandQueueManagement") }}
                  </h5>
                  <p>
                    {{ $t("aiVisionTrainer.cashAutomationandQueueManagementTitle") }}
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/5.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <b-collapse class="mt-2" v-model="isLogisticsCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #1f12fd">
                    {{ $t("aiVisionTrainer.automaticProductRecognitionandTracking") }}
                  </h5>
                  <p>
                    {{ $t("aiVisionTrainer.automaticProductRecognitionandTrackingTitle") }}
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #1f12fd">
                    {{ $t("aiVisionTrainer.safetyInspectionforTransportationVehicles") }}
                  </h5>
                  <p>
                    {{ $t("aiVisionTrainer.safetyInspectionforTransportationVehiclesTitle") }}
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/2.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #1f12fd">
                    {{ $t("aiVisionTrainer.trafficandTransportationManagement") }}
                  </h5>
                  <p>
                    {{ $t("aiVisionTrainer.trafficandTransportationManagementTitle") }}
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/3.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #1f12fd">  {{ $t("aiVisionTrainer.customsandBorderControl") }}</h5>
                  <p>
                    {{ $t("aiVisionTrainer.customsandBorderControlTitle") }}
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/4.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #1f12fd">
                    {{ $t("aiVisionTrainer.warehouseManagement") }}
                  </h5>
                  <p>
                    {{ $t("aiVisionTrainer.warehouseManagementTitle") }}
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/5.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <div class="row">
      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleCommerceCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center"
            :class="{ 'commerce-card': isCommerceCardOpen }"
          >
            <div>
              <h5>          {{ $t("aiVisionTrainer.eCommerce") }}</h5>
              <p>          {{ $t("aiVisionTrainer.eCommerceTitle") }}</p>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleProductionCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center justify-content-center"
            :class="{ 'production-card': isProductionCardOpen }"
          >
            <div>
              <h5>{{ $t("aiVisionTrainer.production") }}</h5>
              <p>{{ $t("aiVisionTrainer.productionTitle") }}</p>
              <br />
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleManagementCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center justify-content-center"
            :class="{ 'management-card': isManagementCardOpen }"
          >
            <div>
              <h5>{{ $t("aiVisionTrainer.management") }}</h5>
              <p>{{ $t("aiVisionTrainer.managementTitle") }}</p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-collapse class="mt-2" v-model="isCommerceCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #e8c91e">{{ $t("aiVisionTrainer.eCommerce") }}</h5>
                  <p>
                    {{ $t("aiVisionTrainer.eCommerceCollapse") }}
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <b-collapse class="mt-2" v-model="isProductionCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #308cd3">
                    {{ $t("aiVisionTrainer.productQualityControl") }}
                  </h5>
                  <p>
                    {{ $t("aiVisionTrainer.productQualityControlTitle") }}
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <b-collapse class="mt-2" v-model="isManagementCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #1509d8">
                    {{ $t("aiVisionTrainer.cityMonitoringwith") }}
                  </h5>
                  <p>
                    {{ $t("aiVisionTrainer.cityMonitoringwithTitle") }}
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <br />
    <div class="row">
      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleAgricultureCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center justify-content-center"
            :class="{ 'agriculture-card': isAgricultureCardOpen }"
          >
            <div>
              <h5> {{ $t("aiVisionTrainer.agriculture") }}</h5>
              <p>
                {{ $t("aiVisionTrainer.agricultureTitle") }}
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleMedicalCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center justify-content-center"
            :class="{ 'medical-card': isMedicalCardOpen }"
          >
            <div>
              <h5>{{ $t("aiVisionTrainer.medical") }}</h5>
              <p>{{ $t("aiVisionTrainer.medicalTitle") }}</p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-collapse class="mt-2" v-model="isAgricultureCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #99ca48">{{ $t("aiVisionTrainer.agriculture") }}</h5>
                  <p>
                    {{ $t("aiVisionTrainer.agricultureCollapse") }}
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <b-collapse class="mt-2" v-model="isMedicalCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #dc3545">
                    {{ $t("aiVisionTrainer.medicalImageAnalysis") }}
                  </h5>
                  <p>
                    {{ $t("aiVisionTrainer.medicalImageAnalysisTitle") }}
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

  </div>
</template>
  
<script>
export default {
  name: "AiDeveloperAwaitYou",
  data() {
    return {
      isBankingCardOpen: false,
      isRetailCardOpen: false,
      isLogisticsCardOpen: false,
      isCommerceCardOpen: false,
      isProductionCardOpen: false,
      isManagementCardOpen: false,
      isAgricultureCardOpen: false,
      isMedicalCardOpen: false,
    };
  },
  methods: {
    toggleBankingCard() {
      this.isBankingCardOpen = !this.isBankingCardOpen;
      if (this.isBankingCardOpen) {
        this.isRetailCardOpen =  false;
        this.isLogisticsCardOpen =  false;
        this.isCommerceCardOpen =  false;
        this.isProductionCardOpen = false;
        this.isManagementCardOpen = false;
        this.isAgricultureCardOpen =  false;
        this.isMedicalCardOpen = false;
      }
    },
    toggleRetailCard() {
      this.isRetailCardOpen = !this.isRetailCardOpen;
      if (this.isRetailCardOpen) {
        this.isBankingCardOpen =  false;
        this.isLogisticsCardOpen =  false;
        this.isCommerceCardOpen =  false;
        this.isProductionCardOpen = false;
        this.isManagementCardOpen = false;
        this.isAgricultureCardOpen =  false;
        this.isMedicalCardOpen = false;
      }
    },
    toggleLogisticsCard() {
      this.isLogisticsCardOpen = !this.isLogisticsCardOpen;
      if (this.isLogisticsCardOpen) {
        this.isBankingCardOpen =  false;
        this.isRetailCardOpen =  false;
        this.isCommerceCardOpen =  false;
        this.isProductionCardOpen = false;
        this.isManagementCardOpen = false;
        this.isAgricultureCardOpen =  false;
        this.isMedicalCardOpen = false;
      }
    },
    toggleCommerceCard() {
      this.isCommerceCardOpen = !this.isCommerceCardOpen;
      if (this.isCommerceCardOpen) {
        this.isBankingCardOpen =  false;
        this.isRetailCardOpen =  false;
        this.toggleLogisticsCard =  false;
        this.isProductionCardOpen = false;
        this.isManagementCardOpen = false;
        this.isAgricultureCardOpen =  false;
        this.isMedicalCardOpen = false;
      }
    },
    toggleProductionCard() {
      this.isProductionCardOpen = !this.isProductionCardOpen;
      if (this.isProductionCardOpen) {
        this.isBankingCardOpen =  false;
        this.isRetailCardOpen =  false;
        this.toggleLogisticsCard =  false;
        this.isCommerceCardOpen = false;
        this.isManagementCardOpen = false;
        this.isAgricultureCardOpen =  false;
        this.isMedicalCardOpen = false;
      }
    },
    toggleManagementCard() {
      this.isManagementCardOpen = !this.isManagementCardOpen;
      if (this.isManagementCardOpen) {
        this.isBankingCardOpen =  false;
        this.isRetailCardOpen =  false;
        this.toggleLogisticsCard =  false;
        this.isCommerceCardOpen = false;
        this.isProductionCardOpen = false;
        this.isAgricultureCardOpen =  false;
        this.isMedicalCardOpen = false;
      }
    },
    toggleAgricultureCard() {
      this.isAgricultureCardOpen = !this.isAgricultureCardOpen;
      if (this.isAgricultureCardOpen) {
        this.isBankingCardOpen =  false;
        this.isRetailCardOpen =  false;
        this.toggleLogisticsCard =  false;
        this.isCommerceCardOpen = false;
        this.isProductionCardOpen = false;
        this.isManagementCardOpen =  false;
        this.isMedicalCardOpen = false;
      }
    },

    toggleMedicalCard() {
      this.isMedicalCardOpen = !this.isMedicalCardOpen;
      if (this.isMedicalCardOpen) {
        this.isBankingCardOpen =  false;
        this.isRetailCardOpen =  false;
        this.toggleLogisticsCard =  false;
        this.isCommerceCardOpen = false;
        this.isProductionCardOpen = false;
        this.isManagementCardOpen =  false;
        this.isAgricultureCardOpen = false;
      }
    },
  },
};
</script>

<style>
.banking-card h5 {
  color: #56c5b2;
}
.retail-card h5 {
  color: #ba4cf9;
}

.logistics-card h5 {
  color: #1f12fd;
}
.commerce-card h5 {
  color: #e8c91e;
}
.production-card h5 {
  color: #308cd3;
}
.management-card h5 {
  color: #1509d8;
}
.agriculture-card h5 {
  color: #99ca48;
}
.medical-card h5 {
  color: #dc3545;
}
</style>

