<template>
  <div class="container pt-60">
    <div class="section-title section-title-xl pb-30">
      <h2>{{  $t("pixAcad.pixCoding.itSupSpecialist.itSupportAwaitYou.title") }}</h2>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <h5>
              {{  $t("pixAcad.pixCoding.itSupSpecialist.itSupportAwaitYou.info1") }}
            </h5>
          </div>
          <img
            src="../../assets/images/1.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <h5>
              {{  $t("pixAcad.pixCoding.itSupSpecialist.itSupportAwaitYou.info2") }}
            </h5>
          </div>
          <img
            src="../../assets/images/2.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <h5>
              {{  $t("pixAcad.pixCoding.itSupSpecialist.itSupportAwaitYou.info3") }}
            </h5>
          </div>
          <img
            src="../../assets/images/3.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <h5>
              {{  $t("pixAcad.pixCoding.itSupSpecialist.itSupportAwaitYou.info4") }}
            </h5>
          </div>
          <img
            src="../../assets/images/4.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <h5>
              {{  $t("pixAcad.pixCoding.itSupSpecialist.itSupportAwaitYou.info5") }}
            </h5>
          </div>
          <img
            src="../../assets/images/5.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <h5>
              {{  $t("pixAcad.pixCoding.itSupSpecialist.itSupportAwaitYou.info6") }}
            </h5>
          </div>
          <img
            src="../../assets/images/6.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>
    <div class="row pb-60">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center justify-content-center"
          >
            <h5>
              {{  $t("pixAcad.pixCoding.itSupSpecialist.itSupportAwaitYou.info7") }}
            </h5>
          </div>
          <img
            src="../../assets/images/7.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <h5>{{  $t("pixAcad.pixCoding.itSupSpecialist.itSupportAwaitYou.info8") }}</h5>
          </div>
          <img
            src="../../assets/images/8.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItSupportAwaitYou",
};
</script>
