<template>
  <div class="inner-page-header pb-0">
    <div class="container">
      <div class="row">
        <div class="col-sm d-flex align-items-center">
          <div class="inner-header-content-academy">
            <h1>AIVISIONTRAINER</h1>
            <!-- <h3>{{ $t("aiVisionTrainer.computerVisionMadeEasy") }}</h3> -->
            <br />
            <h3>{{ $t("aiVisionTrainer.artificialIntelligenceCloud") }}</h3>
            <!-- <div>
              <router-link
                to="/pixacademy-registration"
                class="btn main-btn-academy"
              >
                JOIN NOW
              </router-link>
            </div> -->
          </div>
        </div>
        <div class="col-sm d-flex justify-content-end">
          <img
            class="goods-ai-warehouses-page-header-img"
            src="../../assets/images/academy1.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitleSaasCvMembership",
};
</script>
