<template>
  <div class="pt-60 pb-60 bg-off-white">
    <div class="container">
      <div class="section-title section-title-lg">
        <h2>
          {{ $t('solutionByIndustries.retail.retailUse.title') }}
        </h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="retail-card-usecase">
            <img src="../../assets/images/retail/usecase-1.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="retail-card-usecase-title">
            <h4>
              {{ $t('solutionByIndustries.retail.retailUse.monitoring.title') }}
            </h4>
          </div>
          <div class="retail-card-usecase-problem">
            <div class="retail-card-usecase-title">
              <h6>{{ $t('solutionByIndustries.retail.retailUse.monitoring.problem') }}</h6>
            </div>
            <div class="retail-card-usecase-security">
              <p>
                {{ $t('solutionByIndustries.retail.retailUse.monitoring.problemInfo') }}
              </p>
            </div>
          </div>
          <div class="retail-card-usecase-solution">
            <div class="retail-card-usecase-title">
              <h6>{{ $t('solutionByIndustries.retail.retailUse.monitoring.solution') }}</h6>
            </div>
            <div class="retail-card-usecase-security">
              <p>
                {{ $t('solutionByIndustries.retail.retailUse.monitoring.solutionInfo') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="retail-card-usecase">
            <img src="../../assets/images/retail/usecase-2.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="retail-card-usecase-title">
            <h4>{{ $t('solutionByIndustries.retail.retailUse.visitorCount.title') }}</h4>
          </div>
          <div class="retail-card-usecase-problem">
            <div class="retail-card-usecase-title">
              <h6>{{ $t('solutionByIndustries.retail.retailUse.visitorCount.problem') }}</h6>
            </div>
            <div class="retail-card-usecase-visitor">
              <p>
                {{ $t('solutionByIndustries.retail.retailUse.visitorCount.problemInfo') }}
              </p>
            </div>
          </div>
          <div class="retail-card-usecase-solution">
            <div class="retail-card-usecase-title">
              <h6>{{ $t('solutionByIndustries.retail.retailUse.visitorCount.solution') }}</h6>
            </div>
            <div class="retail-card-usecase-visitor">
              <p>
                {{ $t('solutionByIndustries.retail.retailUse.visitorCount.solutionInfo') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="retail-card-usecase">
            <img src="../../assets/images/retail/usecase-3.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="retail-card-usecase-title">
            <h4>{{ $t('solutionByIndustries.retail.retailUse.goodsShelves.title') }}</h4>
          </div>
          <div class="retail-card-usecase-problem">
            <div class="retail-card-usecase-title">
              <h6>{{ $t('solutionByIndustries.retail.retailUse.goodsShelves.problem') }}</h6>
            </div>
            <div class="retail-card-usecase-count">
              <p>
                {{ $t('solutionByIndustries.retail.retailUse.goodsShelves.problemInfo') }}
              </p>
            </div>
          </div>
          <div class="retail-card-usecase-solution">
            <div class="retail-card-usecase-title">
              <h6>{{ $t('solutionByIndustries.retail.retailUse.goodsShelves.solution') }}</h6>
            </div>
            <div class="retail-card-usecase-count">
              <p>
                {{ $t('solutionByIndustries.retail.retailUse.goodsShelves.solutionInfo1') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RetailUseCase",
};
</script>
