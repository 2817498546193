<template>
    <div>
      <Navbar class="bg-white" />
      <PageTitleForDemoRequest />
      <DemoRequestForm />
      <Footer />
    </div>
  </template>
  
  <script>
  import Navbar from "../Layout/Navbar";
  import PageTitleForDemoRequest from "../DemoRequest/PageTitleForDemoRequest.vue";
  import DemoRequestForm from "../DemoRequest/DemoRequestForm.vue";

  import Footer from "../Layout/Footer";
  
  export default {
    name: "DemoRequest",
    components: {
      Navbar,
      PageTitleForDemoRequest,
      DemoRequestForm,
      Footer,
    },
  };
  </script>
  