<template>
  <div>
    <Navbar class="bg-white" />
    <PageTittleForPricing />
    <Pricing pageTitle="PIXSelect Finance Solution" />
    <CardPricingSecond />
    <CardPricingThird />
    <CardPricingFourth />
    <SolutionsByIndustriesClients />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
import PageTittleForPricing from "../Common/PageTittleForPricing";
import Pricing from "../Pricing/Pricing";
import CardPricingSecond from "../Cart/CardPricingSecond.vue";
import CardPricingThird from "../Cart/CardPricingThird.vue";
import CardPricingFourth from "../Cart/CardPricingFourth.vue";
import SolutionsByIndustriesClients from "../../components/Home/Partners.vue";
import Footer from "../Layout/Footer";


export default {
  name: "PricingPage",
  components: {
    Navbar,
    Pricing,
    PageTittleForPricing,
    CardPricingSecond,
    CardPricingThird,
    CardPricingFourth,
    SolutionsByIndustriesClients,
    Footer,
  },
};
</script>
