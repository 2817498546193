<template>
    <div class="container pt-60">
      <BlogPartners />
      <div class="section-title section-title-lg pt-60">
        <h2>{{  $t('partners.distributorRegions.title') }}</h2>
      </div>
      <div class="row pb-60">
        <div class="col-12 col-md-6 col-lg-3 pt-20">
          <div class="partner-distributor-card">
            <h3>{{  $t('partners.distributorRegions.region1') }}</h3>
            <button class="btn-contact">
              {{  $t('partners.distributorRegions.contact') }}
            </button>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 pt-20">
          <div class="partner-distributor-card">
            <h3>{{  $t('partners.distributorRegions.region2') }}</h3>
            <button class="btn-contact">
              {{  $t('partners.distributorRegions.contact') }}
            </button>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 pt-20">
          <div class="partner-distributor-card">
            <h3>{{  $t('partners.distributorRegions.region3') }}</h3>
            <button class="btn-contact">
              {{  $t('partners.distributorRegions.contact') }}
            </button>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 pt-20">
          <div class="partner-distributor-card">
            <h3>{{  $t('partners.distributorRegions.region4') }}</h3>
            <button class="btn-contact">
              {{  $t('partners.distributorRegions.contact') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  
  import BlogPartners from "../SingleBlog/BlogPartners.vue";
  
  export default defineComponent({
    name: "ClientsPartners",
    components: {
        BlogPartners,
    },
  });
  </script>
  