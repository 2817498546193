<template>
  <div class="container">
    <div class="section-image">
      <img  style="max-width:60%" src="../../assets/images/pixselect-academy-logo.svg" alt="shape" />
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-12 pb-30">
        <div class="pricing-card">
          <div class="pricing-category"> <br>
            <h3>
              {{ $t("pixAcad.pixCoding.frontBackTraining.pageTitle.info") }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

