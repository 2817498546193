<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleBankCctv />
    <CardRetailCctv />
    <CardRetailCctvSecond />
    <CareerRetailCctv />
    <CardRetailCctvThird />
    <ClientsRetailCctv />
    <BlogRetailCctv />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../Layout/Navbar.vue";
import PageTitleBankCctv from "../../../RetailCctv/PageTitleRetailCctv.vue";
import CardRetailCctv from "../../../RetailCctv/CardRetailCctv.vue";
import CardRetailCctvSecond from "../../../RetailCctv/CardRetailCctvSecond.vue";
import CareerRetailCctv from "../../../RetailCctv/CareerRetailCctv.vue";
import CardRetailCctvThird from "../../../RetailCctv/CardRetailCctvThird.vue";
import ClientsRetailCctv from "../../../RetailCctv/ClientsRetailCctv.vue";
import BlogRetailCctv from "../../../RetailCctv/BlogRetailCctv.vue";
import Footer from "../../../Layout/Footer";

export default {
  name: "ShelvesByAICamerasInStores",
  components: {
    Navbar,
    PageTitleBankCctv,
    CardRetailCctv,
    CardRetailCctvSecond,
    CareerRetailCctv,
    CardRetailCctvThird,
    ClientsRetailCctv,
    BlogRetailCctv,
    Footer,
  },
};
</script>
