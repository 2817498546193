<template>
    <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
        <h2>{{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content3.title") }}</h2>
      </div>
  
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="partners-card-features-general">
            <div class="partners-card-features d-flex align-items-center">
              <h5>
                {{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content3.card1") }}
              </h5>
            </div>
            <img
              src="../../assets/images/1.png"
              alt="shape"
              class="partners-card-features-img"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="partners-card-features-general">
            <div class="partners-card-features d-flex align-items-center">
              <h5>
                {{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content3.card2") }}
              </h5>
            </div>
            <img
              src="../../assets/images/2.png"
              alt="shape"
              class="partners-card-features-img"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="partners-card-features-general">
            <div class="partners-card-features d-flex align-items-center">
              <h5>    {{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content3.card3") }}</h5>
            </div>
            <img
              src="../../assets/images/3.png"
              alt="shape"
              class="partners-card-features-img"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="partners-card-features-general">
            <div
              class="partners-card-features d-flex align-items-center justify-content-center"
            >
              <h5>    {{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content3.card4") }}</h5>
            </div>
            <img
              src="../../assets/images/4.png"
              alt="shape"
              class="partners-card-features-img"
            />
          </div>
        </div>
      </div>
 
    </div>
  </template>
  
  <script>
  export default {
    name: "AiDeveloperAwaitYou",
  };
  </script>
  