<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleBankCctv />
    <CardBankCctv />
    <CardBankCctvSecond />
    <CareerBankCctv />
    <CardBankCctvThird />
    <ClientsBankCctv />
    <BlogBankCctv />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../Layout/Navbar.vue";
import PageTitleBankCctv from "../../../BankCctv/PageTitleBankCctv.vue";
import CardBankCctv from "../../../BankCctv/CardBankCctv.vue";
import CardBankCctvSecond from "../../../BankCctv/CardBankCctvSecond.vue";
import CareerBankCctv from "../../../BankCctv/CareerBankCctv.vue";
import CardBankCctvThird from "../../../BankCctv/CardBankCctvThird.vue";
import ClientsBankCctv from "../../../BankCctv/ClientsBankCctv.vue";
import BlogBankCctv from "../../../BankCctv/BlogBankCctv.vue";
import Footer from "../../../Layout/Footer";

export default {
  name: "BankCctv",
  components: {
    Navbar,
    PageTitleBankCctv,
    CardBankCctv,
    CardBankCctvSecond,
    CareerBankCctv,
    CardBankCctvThird,
    ClientsBankCctv,
    BlogBankCctv,
    Footer,
  },
};
</script>
