<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/warehouse-cctv/photo-card-1.png"
          alt="shape"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>
              {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.CardWarehouses.question1" )}}
            </h4>
          </div>

          <div class="goods-ai-warehouses-card-info-text">
            <p>
              {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.CardWarehouses.aswer1-1" )}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row pb-60">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>
              {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.CardWarehouses.question2" )}}
            </h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.CardWarehouses.aswer2-1" )}}
            </p>
            <br />
            <p>
              {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.CardWarehouses.aswer2-2" )}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/warehouse-cctv/photo-card-2.png"
          alt="shape"
        />
      </div>

      <div class="row pt-30">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img
                src="../../assets/images/goods-cameras-ramps/difference-1.png"
                alt="shape"
              />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.CardWarehouses.info-1" )}}
                </h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img
                src="../../assets/images/goods-cameras-ramps/difference-3.png"
                alt="shape"
              />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.CardWarehouses.info-3" )}}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img
                src="../../assets/images/goods-cameras-ramps/difference-2.png"
                alt="shape"
              />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.CardWarehouses.info-2" )}}
                </h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header bank-cctv-info-header">
              <img
                src="../../assets/images/goods-cameras-ramps/difference-4.png"
                alt="shape"
              />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.CardWarehouses.info-4.title" )}}
                </h6>
                <p>
                  - {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.CardWarehouses.info-4.content.line1" )}} <br />
                  - {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.CardWarehouses.info-4.content.line2" )}}<br />
                  - {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.CardWarehouses.info-4.content.line3" )}}<br />
                  - {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.CardWarehouses.info-4.content.line4" )}}<br />
                  - {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.CardWarehouses.info-4.content.line5" )}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardWarehousesCctv",
};
</script>
