import axios from "axios";

class MainService {
  postFormDemoRequest(data) {
    return axios.post("https://dev.pixselect.com.tr:5051/api/demo", {
      name_surname: data.name,
      phone: data.phone,
      email: data.eMail,
      company: data.company,
      comments: data.comments,
      company_operation: data.sector,
      company_need: data.whatNeed,
      privacy_policy: data.isPrivacyPolicy,
    });
  }

  postFormAcademy(data) {
    return axios.post("https://dev.pixselect.com.tr:5051/api/cv", {
      name_surname: data.name,
      email: data.email,
      password: data.password,
      phone: data.phone,
      birthday: data.birthday,
      education_status: data.educationStatus,
      university: data.university,
      department: data.departmant,
      engineer_departmant: data.departmant,
      gradutation_date: data.gradutationDate,
      gender: data.gender,
      city: data.city,
      job_city: data.jobCity,
      job_status: data.jobStatus,
      english_status: data.englishStatus,
      experience: data.experience,
      github: data.github,
      linkedin: data.linkedin,
      job_departmant: data.jobDepartmant,
      kvkk_approval: data.isPrivacyPolicy,
      contact_place: data.contactPlace,
      experience1: data.experience1,
      experience2: data.experience2,
      experience3: data.experience3
    });
  }

  postContact(data) {
    return axios.post("https://dev.pixselect.com.tr:5051/api/contact", {
      name_surname: data.name_surname,
      phone: data.phone,
      email: data.email,
      subject: data.subject,
      question: data.question,
      privacy_policy: data.isPrivacyPolicy,
    });
  }

  postContactAcademy(data) {
    return axios.post("https://dev.pixselect.com.tr:5051/api/contact_academy", {
      name_surname: data.name_surname,
      phone: data.phone,
      email: data.email,
      company: data.company,
      comments: data.comments,
      privacy_policy: data.isPrivacyPolicy,
    });
  }

  postFormPartnerRequest(data) {
    return axios.post("https://dev.pixselect.com.tr:5051/api/partner", {
      name_surname: data.name,
      phone: data.phone,
      email: data.eMail,
      company: data.company,
      company_operation: data.sector,
      company_need: data.whatNeed,
      privacy_policy: data.isPrivacyPolicy,
      company_position : data.position
    });
  }

}

export default new MainService();
