<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleGoodsBarcodeRamps />
    <CardGoodsBarcodeRamps />
    <CardGoodsBarcodeRampsSecond />
    <CareerGoodsBarcodeRamps />
    <CardGoodsBarcodeRampsThird />
    <ClientsGoodsBarcodeRamps />
    <BlogGoodsBarcodeRamps />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../Layout/Navbar.vue";
import PageTitleGoodsBarcodeRamps from "../../../GoodsByBarcodeOnRamps/PageTitleGoodsBarcodeRamps.vue";
import CardGoodsBarcodeRamps from "../../../GoodsByBarcodeOnRamps/CardGoodsBarcodeRamps.vue";
import CardGoodsBarcodeRampsSecond from "../../../GoodsByBarcodeOnRamps/CardGoodsBarcodeRampsSecond.vue";
import CareerGoodsBarcodeRamps from "../../../GoodsByBarcodeOnRamps/CareerGoodsBarcodeRamps.vue";
import CardGoodsBarcodeRampsThird from "../../../GoodsByBarcodeOnRamps/CardGoodsBarcodeRampsThird.vue";
import ClientsGoodsBarcodeRamps from "../../../GoodsByBarcodeOnRamps/ClientsGoodsBarcodeRamps.vue";
import BlogGoodsBarcodeRamps from "../../../GoodsByBarcodeOnRamps/BlogGoodsBarcodeRamps.vue";
import Footer from "../../../Layout/Footer";

export default {
  name: "GoodsByBarcodeOnRamps",
  components: {
    Navbar,
    PageTitleGoodsBarcodeRamps,
    CardGoodsBarcodeRamps,
    CardGoodsBarcodeRampsSecond,
    CareerGoodsBarcodeRamps,
    CardGoodsBarcodeRampsThird,
    ClientsGoodsBarcodeRamps,
    BlogGoodsBarcodeRamps,
    Footer,
  },
};
</script>
