<template>
  <div class="pt-60 pb-60 bg-off-white">
    <div class="container">
      <div class="section-title section-title-xl">
        <h2>
          {{ $t("solutionByIndustries.logistic.visionLogistic2.title") }}
        </h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="logistic-card">
            <div class="logistic-card-info-img">
              <img
                src="../../assets/images/logistic/power-efficiency-card-1.png"
                alt="shape"
              />
            </div>
            <div class="logistic-card-info">
              <h5>
                {{ $t("solutionByIndustries.logistic.visionLogistic2.securATM.title") }}
              </h5>
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic2.securATM.info1") }}

                <br />
                <br />
                {{ $t("solutionByIndustries.logistic.visionLogistic2.securATM.info2") }}
              </p>
              <img src="../../assets/images/1.png" alt="shape" />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="logistic-card">
            <div class="logistic-card-info-img">
              <img
                src="../../assets/images/logistic/power-efficiency-card-2.png"
                alt="shape"
              />
            </div>

            <div class="logistic-card-info">
              <h5>
                {{ $t("solutionByIndustries.logistic.visionLogistic2.secureBank.title") }}
              </h5>
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic2.secureBank.info1") }}
                <br />
                <br />
                {{ $t("solutionByIndustries.logistic.visionLogistic2.secureBank.info2") }}
              </p>
              <img src="../../assets/images/2.png" alt="shape" />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="logistic-card">
            <div class="logistic-card-info-img">
              <img
                src="../../assets/images/logistic/power-efficiency-card-3.png"
                alt="shape"
              />
            </div>

            <div class="logistic-card-info">
              <h5>
                {{ $t("solutionByIndustries.logistic.visionLogistic2.secureCash.title") }}
              </h5>
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic2.secureCash.info1") }}
                <br />
                <br />
                {{ $t("solutionByIndustries.logistic.visionLogistic2.secureCash.info2") }}
              </p>
              <img src="../../assets/images/3.png" alt="shape" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogisticPowerEfficiency",
};
</script>
