<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleForVehicleEntrancesLicense />
    <CardVehicleEntrancesLicense />
    <CardVehicleEntrancesLicenseSecond />
    <CareerVehicleEntrancesLicense />
    <CardVehicleEntrancesLicenseThird />
    <ClientsVehicleEntrancesLicense />
    <BlogVehicleEntrancesLicense />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../Layout/Navbar";
import PageTitleForVehicleEntrancesLicense from "../../../VehicleEntrancesLicense/PageTitleForVehicleEntrancesLicense.vue";
import CardVehicleEntrancesLicense from "../../../VehicleEntrancesLicense/CardVehicleEntrancesLicense.vue";
import CardVehicleEntrancesLicenseSecond from "../../../VehicleEntrancesLicense/CardVehicleEntrancesLicenseSecond.vue";
import CareerVehicleEntrancesLicense from "../../../VehicleEntrancesLicense/CareerVehicleEntrancesLicense.vue";
import CardVehicleEntrancesLicenseThird from "../../../VehicleEntrancesLicense/CardVehicleEntrancesLicenseThird.vue";
import ClientsVehicleEntrancesLicense from "../../../VehicleEntrancesLicense/ClientsVehicleEntrancesLicense.vue";
import BlogVehicleEntrancesLicense from "../../../VehicleEntrancesLicense/BlogVehicleEntrancesLicense.vue";
import Footer from "../../../Layout/Footer";

export default {
  name: "VehicleEntrancesLicense",
  components: {
    Navbar,
    PageTitleForVehicleEntrancesLicense,
    CardVehicleEntrancesLicense,
    CardVehicleEntrancesLicenseSecond,
    CareerVehicleEntrancesLicense,
    CardVehicleEntrancesLicenseThird,
    ClientsVehicleEntrancesLicense,
    BlogVehicleEntrancesLicense,
    Footer,
  },
};
</script>
