<template>
  <div class="retail-section-card-our-solution">
    <div class="container pt-60 pb-30">
      <div class="section-title section-title-lg">
        <h2>
          {{ $t('solutionByIndustries.retail.solDifferent.title') }}
        </h2>
      </div>
      <div class="row mb-100">
        <div class="col-12 col-sm-8 col-lg-4 pb-30">
          <div class="contact-info-item">
            <div class="retail-info-our-solution">
              <img
                src="../../assets/images/pricing-difference-1.png"
                alt="shape"
              />
              <img
                src="../../assets/images/pricing-difference-icon-1.png"
                alt="shape"
                class="retail-img"
              />
              <div class="retail-info-our-solution-text">
                <h6>
                  {{ $t('solutionByIndustries.retail.solDifferent.easyIntegration.title') }}
                </h6>
                <p>
                  {{ $t('solutionByIndustries.retail.solDifferent.easyIntegration.info') }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-8 col-lg-4 pb-30">
          <div class="contact-info-item">
            <div class="retail-info-our-solution">
              <img
                src="../../assets/images/pricing-difference-2.png"
                alt="shape"
              />
              <img
                src="../../assets/images/pricing-difference-icon-2.png"
                alt="shape"
                class="retail-img"
              />
              <div class="retail-info-our-solution-text">
                <h6>{{ $t('solutionByIndustries.retail.solDifferent.easySetupAndActivation.title') }}</h6>
                <p>
                  {{ $t('solutionByIndustries.retail.solDifferent.easySetupAndActivation.info') }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-8 col-lg-4 pb-30">
          <div class="contact-info-item">
            <div class="retail-info-our-solution">
              <img
                src="../../assets/images/pricing-difference-3.png"
                alt="shape"
              />
              <img
                src="../../assets/images/pricing-difference-icon-3.png"
                alt="shape"
                class="retail-img"
              />
              <div class="retail-info-our-solution-text">
                <h6>{{ $t('solutionByIndustries.retail.solDifferent.multioptionalArchitecture.title') }}</h6>
                <p>
                  {{ $t('solutionByIndustries.retail.solDifferent.multioptionalArchitecture.info') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BlogCountPeople />
    </div>
  </div>
</template>

<script>
import BlogCountPeople from "../SingleBlog/BlogCountPeople.vue";
export default {
  name: "RetailOurSolution",
  components: {
    BlogCountPeople,
  },
};
</script>
