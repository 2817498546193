<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img src="../../assets/images/bank-cctv/photo-card-1.png" alt="shape" />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>{{ $t( "products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank.q1" ) }}</h4>
          </div>

          <div class="goods-ai-warehouses-card-info-text">
            <p>{{ $t( "products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank.ans1" ) }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row pb-60">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>{{ $t( "products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank.q2" ) }}</h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>{{ $t( "products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank.ans2.first" ) }}</p>
            <br />
            <p>{{ $t( "products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank.ans2.second" ) }} </p>
          </div>
        </div>
        <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img
                src="../../assets/images/goods-cameras-warehouses/difference-1.png"
                alt="shape"
              />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>{{ $t( "products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank.info-1" ) }}</h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header bank-cctv-info-header">
              <img
                src="../../assets/images/goods-cameras-warehouses/difference-2.png"
                alt="shape"
              />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank.info-2.title" ) }}
                </h6>
                <p>
                  - {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank.info-2.content.line1" ) }} <br />
                  - {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank.info-2.content.line2" ) }}<br />
                  - {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank.info-2.content.line3" ) }}<br />
                  - {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank.info-2.content.line4" ) }}<br />
                  - {{ $t( "products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank.info-2.content.line5" ) }}
                </p>
              </div>
            </div>
          </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/bank-cctv/photo-card-2.png"
          alt="shape"
          style="height: 815px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardBankCctv",
};
</script>
