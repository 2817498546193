<template>
    <div class="logistic-section-card-our-solution">
      <div class="container pb-20 pt-60">
        <div class="section-title section-title-lg">
          <h2>{{  $t("saasCloudTagMembership.section6.title") }}</h2>
        </div>
        <div class="row mb-100">
          <div class="col-12 col-md-8 col-lg-4 pb-30">
            <div class="contact-info-item">
              <div class="logistic-info-our-solution">
                <img
                  src="../../assets/images/pricing-difference-1.png"
                  alt="shape"
                />
                <img
                  src="../../assets/images/pricing-difference-icon-1.png"
                  alt="shape"
                  class="logistic-img"
                />
                <div class="logistic-info-our-solution-text">
                  <h6>Import</h6>
                  <p>
                    {{ $t("saasCloudTagMembership.section6.card1") }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-8 col-lg-4 pb-30">
            <div class="contact-info-item">
              <div class="logistic-info-our-solution">
                <img
                  src="../../assets/images/pricing-difference-2.png"
                  alt="shape"
                />
                <img
                  src="../../assets/images/pricing-difference-icon-2.png"
                  alt="shape"
                  class="logistic-img"
                />
                <div class="logistic-info-our-solution-text">
                  <h6>
                    {{ $t("saasCloudTagMembership.section6.card2Title") }}

                  </h6>
                  <p>
                    {{ $t("saasCloudTagMembership.section6.card2") }}

                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-8 col-lg-4 pb-30">
            <div class="contact-info-item">
              <div class="logistic-info-our-solution">
                <img
                  src="../../assets/images/pricing-difference-3.png"
                  alt="shape"
                />
                <img
                  src="../../assets/images/pricing-difference-icon-3.png"
                  alt="shape"
                  class="logistic-img"
                />
                <div class="logistic-info-our-solution-text">
                  <h6>Export</h6>
                  <p>
                   
                   
                    {{ $t("saasCloudTagMembership.section6.card3") }}
                    
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
