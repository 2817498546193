<template>
    <div class="inner-page-header pb-0">
      <div class="container">
        <div class="row">
          <div class="col-sm-6 d-flex align-items-center">
            <div class="inner-header-content-academy">
              <h1>{{ $t("aiVisionTrainer.saaSCVMembership") }}</h1>
              <br />
              <h3>{{ $t("aiVisionTrainer.discoverSaaSCVMembership") }}</h3>
              <br />
            </div>
          </div>
          <div class="col-sm-6 d-flex justify-content-end">
            <img
              style="max-width: 60%; max-height: 100%;"
              class="goods-ai-warehouses-page-header-img"
              src="../../assets/images/academy1.png"
            />
          </div>
        </div>
      </div>
    </div>
    <br>
  </template>
  <style scoped>
  .inner-page-header {
    padding-top:20px
  }
</style>