<template>
  <div class="container pt-60">
    <div class="section-title section-title-xl pb-30">
      <h2>{{ $t("aiVisionTrainer.whyChooseAIVISION") }}</h2>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-6">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <div>
              <h5>{{ $t("aiVisionTrainer.cloudBased") }}</h5>
              <p>
                {{ $t("aiVisionTrainer.asACloudbased") }}
              </p>
              <br />
            </div>
          </div>

          <img
            src="../../assets/images/1.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <div>
              <h5>{{ $t("aiVisionTrainer.easyAndSecure") }}</h5>
              <p>
                {{ $t("aiVisionTrainer.applicationThat") }}
              </p>
              <br />
            </div>
          </div>
          <img
            src="../../assets/images/2.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <div>
              <h5>{{ $t("aiVisionTrainer.lowCost") }}</h5>
              <p>
                {{ $t("aiVisionTrainer.labelingQuickly") }}
              </p>
              <br />
            </div>
          </div>
          <img
            src="../../assets/images/3.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <div>
              <h5>{{ $t("aiVisionTrainer.endToEnd") }}</h5>
              <p>
                {{ $t("aiVisionTrainer.endToEndTitle") }}
              </p>
            </div>
          </div>
          <img
            src="../../assets/images/4.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-6">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <div>
              <h5>{{ $t("aiVisionTrainer.easyIntegration") }}</h5>
              <p>
                {{ $t("aiVisionTrainer.easyIntegrationTitle") }}
              </p>
              <br />
            </div>
          </div>
          <img
            src="../../assets/images/5.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AiDeveloperAwaitYou",
};
</script>
