<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 count-people-card-info-img">
        <img src="../../assets/images/count-people/photo-card-1.png" alt="shape" />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>
              {{ $t("products.PIXAIASol.pixCountPeople.cardCount.question1") }}
            </h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p style="line-height: 2.1">
              {{ $t("products.PIXAIASol.pixCountPeople.cardCount.aswer1-1") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img src="../../assets/images/goods-cameras-warehouses/seperator.png" alt="shape" />
      </div>
    </div>
    <div class="row pb-60">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>{{ $t("products.PIXAIASol.pixCountPeople.cardCount.question2") }}</h4>
          </div>

          <div class="goods-ai-warehouses-card-info-text">
            <p>
              {{ $t("products.PIXAIASol.pixCountPeople.cardCount.aswer2-1") }}
            </p>
          </div>
        </div>
        <div class="contact-info-item pt-20">
          <div class="goods-ai-warehouses-info-header">
            <img src="../../assets/images/goods-cameras-ramps/1.png" alt="shape" />
            <div class="count-people-info-header-text">
              <h6>{{ $t("products.PIXAIASol.pixCountPeople.cardCount.info-1") }}</h6>
            </div>
          </div>
        </div>

        <div class="contact-info-item pt-20">
          <div class="goods-ai-warehouses-info-header">
            <img src="../../assets/images/goods-cameras-ramps/3.png" alt="shape" />
            <div class="count-people-info-header-text">
              <h6>{{ $t("products.PIXAIASol.pixCountPeople.cardCount.info-2") }}</h6>
            </div>
          </div>
        </div>

        <div class="contact-info-item pt-20">
          <div class="goods-ai-warehouses-info-header">
            <img src="../../assets/images/goods-cameras-ramps/5.png" alt="shape" />
            <div class="count-people-info-header-text">
              <h6>{{ $t("products.PIXAIASol.pixCountPeople.cardCount.info-3") }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div v-if="language == 'en'">
          <img src="../../assets/images/count-people/photo-card-2.png" alt="shape"
            style="padding: 20px 0px 0px 0px; height: 725px" />
        </div>
        <div v-else>
          <img src="../../assets/images/count-people/photo-card-2.png" alt="shape"
            style="padding: 20px 0px 0px 0px; height: 725px" />
        </div>
        <div class="contact-info-item pt-20">
          <div class="goods-ai-warehouses-info-header">
            <img src="../../assets/images/goods-cameras-ramps/2.png" alt="shape" />
            <div class="count-people-info-header-text">
              <h6>{{ $t("products.PIXAIASol.pixCountPeople.cardCount.info-4") }}</h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item pt-20">
          <div class="goods-ai-warehouses-info-header">
            <img src="../../assets/images/goods-cameras-ramps/4.png" alt="shape" />
            <div class="count-people-info-header-text">
              <h6>
                {{ $t("products.PIXAIASol.pixCountPeople.cardCount.info-5") }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardCountPeople",
  data() {
    return {
      language: localStorage.getItem("language")
    }
  }
};
</script>
