<template>
  <div class="container pt-60 pb-60">
    <SystemArchitecture :title="title" :imageUrl="url" />

    <div class="section-title section-title-xl pt-60">
      <h2>
        {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.monSysArc.suppHardware' ) }}
      </h2>
    </div>
    <div class="goods-ai-warehouses-info">
      <div class="row">
        <div class="col-12 col-md-12 col-lg">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-4.png"
            alt="shape"
          />
          <h6>
            {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.monSysArc.hardware1' ) }}
          </h6>
        </div>
        <div class="col-12 col-md-12 col-lg-1">
          <img
            src="../../assets/images/line.png"
            alt="shape"
            class="goods-ai-warehouses-info-line"
          />
        </div>
        <div class="col-12 col-md-12 col-lg">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-4.png"
            alt="shape"
          />
          <h6>
            {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.monSysArc.hardware2' ) }}
          </h6>
        </div>
        <div class="col-12 col-md-12 col-lg-1">
          <img
            src="../../assets/images/line.png"
            alt="shape"
            class="goods-ai-warehouses-info-line"
          />
        </div>
        <div class="col-12 col-md-12 col-lg">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-4.png"
            alt="shape"
          />
          <h6>
            {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.monSysArc.hardware3' ) }}
          </h6>
        </div>
      </div>
    </div>

    <div class="section-title section-title-xl pt-60">
      <h2>
        {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.monSysArc.question' ) }}
      </h2>
    </div>
    <div class="goods-ai-warehouses-info-solution">
      <div class="row">
        <div class="col-12 col-md-12 col-lg">
          <h6 class="p-0 mt-30 mb-30">
            {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.monSysArc.answer' ) }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import i18n from "../../plugins/i18n";
import SystemArchitecture from "../Common/SystemArchitecture.vue";

export default defineComponent({
  name: "CareerRetailCctv",
  components: {
    SystemArchitecture,
  },
  data() {
    return {
      url: require("../../assets/images/retail-cctv/isometric.png"),
      title: i18n.global.t('products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.monSysArc.title'),
    };
  },
});
</script>
