<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 count-people-card-info-img">
        <img
          src="../../assets/images/turnstiles-face-recognition/photo-card-1.png"
          alt="shape"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>
              {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles.question1") }}
            </h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles.aswer1-1") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>

    <div class="row pb-60">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>
              {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles.question1") }}
            </h4>
          </div>

          <div class="goods-ai-warehouses-card-info-text">
            <p>
              {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles.aswer2-1") }}
            </p>
            <p>
              {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles.aswer2-2") }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-md-12 col-lg-6 count-people-card-info-img d-flex justify-content-end"
      >
        <img
          src="../../assets/images/turnstiles-face-recognition/photo-card-2.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row pb-60">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="contact-info-item pt-10">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/difference-1.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles.info-1") }}
              </h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item pt-10">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/difference-3.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles.info-2") }}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="contact-info-item pt-10">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/difference-2.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles.info-3") }}
              </h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item pt-10">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/difference-4.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles.info-4") }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardTurnstilesFaceRecognition",
};
</script>
