<template>
  <div class="container pt-60 pb-60">
    <div class="section-title section-title-xl">
      <h2>{{ $t('home.products.title') }}</h2>
      <div class="home-page-partner">
        <p>{{ $t('home.products.info') }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-4 mb-20">
        <div class="home-page-product-card">
          <img
            src="../../assets/images/homepage/product-item-1.png"
            alt="shape"
          />
          <img
            src="../../assets/images/homepage/product-item-1-hover.png"
            alt="shape"
            class="hover-icon"
          />
          <h5>{{ $t('home.products.pixSvmsCctvMonitoringSolutions.title') }}</h5>
          <p>{{ $t('home.products.pixSvmsCctvMonitoringSolutions.info') }}</p>
          <img
            src="../../assets/images/homepage/product-item-line.png"
            alt="shape"
            class="line-icon"
          />
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-4 mb-20">
        <div class="home-page-product-card">
          <img
            src="../../assets/images/homepage/product-item-2.png"
            alt="shape"
          />
          <img
            src="../../assets/images/homepage/product-item-2-hover.png"
            alt="shape"
            class="hover-icon"
          />
          <h5>{{ $t('home.products.pixPassAccessAndLogSolutions.title') }}</h5>
          <p>{{ $t('home.products.pixPassAccessAndLogSolutions.info') }}</p>
          <img
            src="../../assets/images/homepage/product-item-line.png"
            alt="shape"
            class="line-icon"
          />
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-4 mb-20">
        <div class="home-page-product-card">
          <img
            src="../../assets/images/homepage/product-item-3.png"
            alt="shape"
          />
          <img
            src="../../assets/images/homepage/product-item-3-hover.png"
            alt="shape"
            class="hover-icon"
          />
          <h5>{{ $t('home.products.pixAIArtificialIntelligenceSolutions.title') }}</h5>
          <p>{{ $t('home.products.pixAIArtificialIntelligenceSolutions.info') }}</p>
          <img
            src="../../assets/images/homepage/product-item-line.png"
            alt="shape"
            class="line-icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Products",
};
</script>
