<template>
  <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
          <h2>{{ $t("codingAiDeveloper.section1.title") }}</h2>
      </div>

      <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
              <div class="partners-card-features-general">
                  <div class="partners-card-features d-flex align-items-center">
                      <div>
                          <h5>{{ $t("codingAiDeveloper.section1.cardTitle") }} </h5>
                          <p>
                            {{ $t("codingAiDeveloper.section1.card") }}
                          </p>
                          <br>
                      </div>
                  </div>

                  <img src="../../assets/images/1.png" alt="shape" class="partners-card-features-img" />
              </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
              <div class="partners-card-features-general">
                  <div class="partners-card-features d-flex align-items-center">
                      <div>
                          <h5>{{ $t("codingAiDeveloper.section1.cardTitle1") }}</h5>
                          <p>
                            {{ $t("codingAiDeveloper.section1.card1") }}
                          </p>
                          <br>
                      </div>
                  </div>
                  <img src="../../assets/images/2.png" alt="shape" class="partners-card-features-img" />
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
              <div class="partners-card-features-general">
                  <div class="partners-card-features d-flex align-items-center">
                      <div>
                          <h5>{{ $t("codingAiDeveloper.section1.cardTitle2") }} </h5>
                          <p>
                            {{ $t("codingAiDeveloper.section1.card2") }}
                          </p>
                          <br>
                      </div>
                  </div>
                  <img src="../../assets/images/3.png" alt="shape" class="partners-card-features-img" />
              </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
              <div class="partners-card-features-general">
                  <div class="partners-card-features d-flex align-items-center">
                      <div>
                          <h5>{{ $t("codingAiDeveloper.section1.cardTitle3") }}</h5>
                          <p>
                            {{ $t("codingAiDeveloper.section1.card3") }}
                          </p>

                      </div>
                  </div>
                  <img src="../../assets/images/4.png" alt="shape" class="partners-card-features-img" />
              </div>
          </div>
      </div>

            <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
              <div class="partners-card-features-general">
                  <div class="partners-card-features d-flex align-items-center">
                      <div>
                          <h5>{{ $t("codingAiDeveloper.section1.cardTitle4") }}</h5>
                          <p>
                            {{ $t("codingAiDeveloper.section1.card4") }}
                          </p>
                          <br>
                      </div>
                  </div>
                  <img src="../../assets/images/5.png" alt="shape" class="partners-card-features-img" />
              </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
              <div class="partners-card-features-general">
                  <div class="partners-card-features d-flex align-items-center">
                      <div>
                          <h5>{{ $t("codingAiDeveloper.section1.cardTitle5") }} </h5>
                          <p>
                            {{ $t("codingAiDeveloper.section1.card5") }}
                          </p>

                      </div>
                  </div>
                  <img src="../../assets/images/6.png" alt="shape" class="partners-card-features-img" />
              </div>
          </div>
      </div>

      <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
              <div class="partners-card-features-general">
                  <div class="partners-card-features d-flex align-items-center">
                      <div>
                          <h5>{{ $t("codingAiDeveloper.section1.cardTitle6") }}</h5>
                          <p>
                            {{ $t("codingAiDeveloper.section1.card6") }}
                          </p>

                      </div>
                  </div>
                  <img src="../../assets/images/7.png" alt="shape" class="partners-card-features-img" />
              </div>
          </div>
      </div>


  </div>
</template>

<script>
export default {
  name: "AiDeveloperAwaitYou",
};
</script>
