<template>
  <div class="process-section pt-90 pb-70">
    <div class="container">
      <div class="section-title section-title-lg">
        <h2>{{ $t("saasCloudTagMembership.section4.title") }}</h2>
      </div>
      <div class="process-content">
        <div class="row justify-content-center">
          <div class="col-md-6 col-lg-4 pb-30">
            <div class="process-item process-item-center border">
              <div class="process-thumb">
                <img src="../../assets/images/icon-1.png" alt="shape" />
              </div>
              <div class="process-text">
                <h3>{{ $t("saasCloudTagMembership.section4.card1Title") }}</h3>
                <p>
                  {{ $t("saasCloudTagMembership.section4.card1") }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 pb-30">
            <div class="process-item process-item-center border">
              <div class="process-thumb">
                <img src="../../assets/images/icon-2.png" alt="shape" />
              </div>
              <div class="process-text">
                <h3>
                  {{ $t("saasCloudTagMembership.section4.card2Title") }}
                </h3>
                <p>
                  {{ $t("saasCloudTagMembership.section4.card2") }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 pb-30">
            <div class="process-item process-item-center border">
              <div class="process-thumb">
                <img src="../../assets/images/icon-3.png" alt="shape" />
              </div>
              <div class="process-text">
                <h3>{{ $t("saasCloudTagMembership.section4.card3Title") }}</h3>
                <p>
                  {{ $t("saasCloudTagMembership.section4.card3") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Process",
};
</script>
  
  <style scoped>
.process-item.border {
  padding: 30px;
  background-color: #fff;
  max-height: 300px;
  height: 350px !important;
  border-radius: 10px;
}
</style>