<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleAbout />
    <CardAbout />
    <CardAboutSecond />
    <BlogAbout />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
import PageTitleAbout from "../About/PageTitleAbout.vue";
import CardAbout from "../About/CardAbout.vue";
import CardAboutSecond from "../About/CardAboutSecond.vue";
import BlogAbout from "../About/BlogAbout.vue";
import Footer from "../Layout/Footer";

export default {
  name: "AboutPage",
  components: {
    Navbar,
    PageTitleAbout,
    CardAbout,
    CardAboutSecond,
    BlogAbout,
    Footer,
  },
};
</script>
