<template>
  <div class="pt-60 pb-60 bg-off-white">
    <div class="container">
      <div class="section-title section-title-xl">
        <h2>
          {{ $t('solutionByIndustries.retail.retailPowerEffect.title') }}
        </h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="retail-card">
            <div class="retail-card-info-img">
              <img
                src="../../assets/images/retail/power-efficiency-card-1.png"
                alt="shape"
              />
            </div>
            <div class="retail-card-info">
              <h5>{{ $t('solutionByIndustries.retail.retailPowerEffect.warehouseOp.title') }}</h5>
              <p>
                {{ $t('solutionByIndustries.retail.retailPowerEffect.warehouseOp.info1') }}
              </p>
              <img src="../../assets/images/1.png" alt="shape" />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="retail-card">
            <div class="retail-card-info-img">
              <img
                src="../../assets/images/retail/power-efficiency-card-2.png"
                alt="shape"
              />
            </div>
            <div class="retail-card-info">
              <h5>{{ $t('solutionByIndustries.retail.retailPowerEffect.storeOp.title') }}</h5>
              <p>
                {{ $t('solutionByIndustries.retail.retailPowerEffect.storeOp.info1') }}
              </p>
              <img src="../../assets/images/2.png" alt="shape" />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="retail-card">
            <div class="retail-card-info-img">
              <img
                src="../../assets/images/retail/power-efficiency-card-3.png"
                alt="shape"
              />
            </div>

            <div class="retail-card-info">
              <h5>{{ $t('solutionByIndustries.retail.retailPowerEffect.storeSecur.title') }}</h5>
              <p>
                {{ $t('solutionByIndustries.retail.retailPowerEffect.storeSecur.info1') }}
              </p>
              <img src="../../assets/images/3.png" alt="shape" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RetailPowerEfficiency",
};
</script>
