<template>
    <div
      style="background-image: linear-gradient(to right, #852dee, #5044ff)"
      class="inner-page-header-academy"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm-7">
            <div class="inner-header-content-demo-request">
              <h2 class="mb-0">{{  $t('partners.distributorRegions.joinTitle') }}</h2>
              <h3 class="mb-0 mt-20">{{  $t('partners.distributorRegions.joinInfo') }}</h3>
        
              <div class="pl-4 mt-20">
                <router-link to="/projects" class="btn main-btn-academy">
                  {{  $t('partners.distributorRegions.apply') }}
                </router-link>
              </div>
            </div>
          </div>
  
          <div class="col-sm d-flex justify-content-end">
            <img
              class="demo-request-img"
              src="../../assets/images/join-partner.png"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  