<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/goods-cameras-warehouses/photo-card-1.png"
          alt="shape"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>
              {{ $t("products.PIXAIASol.pixCountGoodsByAICameras.inWarehouses.cardGoodsWar.question1") }}
            </h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              {{ $t("products.PIXAIASol.pixCountGoodsByAICameras.inWarehouses.cardGoodsWar.aswer1-1") }}
            </p>
            <br />
            <p>
              {{ $t("products.PIXAIASol.pixCountGoodsByAICameras.inWarehouses.cardGoodsWar.aswer1-2") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>
              {{ $t("products.PIXAIASol.pixCountGoodsByAICameras.inWarehouses.cardGoodsWar.question2") }}
            </h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              {{ $t("products.PIXAIASol.pixCountGoodsByAICameras.inWarehouses.cardGoodsWar.aswer2-1") }}
            </p>
            <br />
            <p>
              {{ $t("products.PIXAIASol.pixCountGoodsByAICameras.inWarehouses.cardGoodsWar.aswer2-2") }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/goods-cameras-warehouses/photo-card-2.png"
          alt="shape"
          style="padding: 0px 0px 0px 18px"
        />
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/goods-cameras-warehouses/photo-card-3.png"
          alt="shape"
          style="padding: 0px 18px 0px 0px"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-text">
            <p style="line-height: 50px">
              {{ $t("products.PIXAIASol.pixCountGoodsByAICameras.inWarehouses.cardGoodsWar.another1") }}
            </p>
            <br />
            <p style="line-height: 50px">
              {{ $t("products.PIXAIASol.pixCountGoodsByAICameras.inWarehouses.cardGoodsWar.another2") }}
            </p>
            <br />
            <p style="line-height: 50px">
              {{ $t("products.PIXAIASol.pixCountGoodsByAICameras.inWarehouses.cardGoodsWar.another3") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60 pl-40">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-1.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t("products.PIXAIASol.pixCountGoodsByAICameras.inWarehouses.cardGoodsWar.info-1") }}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-6">
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-2.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>{{ $t("products.PIXAIASol.pixCountGoodsByAICameras.inWarehouses.cardGoodsWar.info-2") }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pb-60 pl-40">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-3.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>{{ $t("products.PIXAIASol.pixCountGoodsByAICameras.inWarehouses.cardGoodsWar.info-3") }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-6">
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-4.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t("products.PIXAIASol.pixCountGoodsByAICameras.inWarehouses.cardGoodsWar.info-4") }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardGoodsCamerasWarehouses",
};
</script>
