<template>
  <div class="container pt-60 pb-80">
    <div class="section-title section-title-xl pb-30">
      <h2>{{ $t('aboutUs.cuttingEdgeTech.title') }}</h2>
    </div>

    <div class="row pb-60">
      <div class="col-12 col-md-6 col-lg-3">
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center"
            style="border-color: rgb(252, 39, 48)"
          >
            <h5>{{ $t('aboutUs.cuttingEdgeTech.first') }}</h5>
          </div>
          <img
            src="../../assets/images/1.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center"
            style="border-color: rgb(0, 119, 203)"
          >
            <h5>{{ $t('aboutUs.cuttingEdgeTech.second') }}</h5>
          </div>
          <img
            src="../../assets/images/2.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center"
            style="border-color: rgb(159, 159, 159)"
          >
            <h5>{{ $t('aboutUs.cuttingEdgeTech.third') }}</h5>
          </div>
          <img
            src="../../assets/images/3.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center"
            style="border-color: rgb(7, 191, 145)"
          >
            <h5>{{ $t('aboutUs.cuttingEdgeTech.fourth') }}</h5>
          </div>
          <img
            src="../../assets/images/4.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>
    <BlogCountPeople />
  </div>
</template>

<script>
import BlogCountPeople from "../SingleBlog/BlogCountPeople.vue";

export default {
  name: "CardAboutSecond",
  components: {
    BlogCountPeople,
  },
};
</script>

<style scoped>
.partners-card-features h5{
    font-size: 16px;
    font-family: "Gilroy";
    color: rgb(0, 0, 0);
    font-weight: bold;
    line-height: 1.25;
    text-align: center;
    padding: 20px;
}

</style>