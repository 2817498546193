<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
        <h2>{{  $t("pixAcad.pixCoding.itSupSpecialist.itSupportAcademyTeam") }}</h2>
      </div>
      <div class="row pb-60">
        <div class="col-12">
          <div v-if="language == 'en'">
            <img
            src="../../assets/images/it-support-special/academy-team.png"
            alt="shape"
          />
          </div>
          <div v-else>
            <img 
            src="../../assets/images/it-support-special/academy-team_tr.png"
            alt="shape"
          />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItSupportAcademyTeam",
  data() {
    return {
      language: localStorage.getItem("language")
    }
  }
};
</script>
