<template>
  <div class="row pt-40">
      <div class="col-12 col-md-12 col-lg-6 pt-30" style="margin: 0 auto;">

      <div class="section-title section-title-xl">
        <h2>AIVISIONTRAINER</h2>
      </div>
      <div class="contact-info-card">
        <p>
          {{ $t("aiVisionTrainer.aIVISIONTRAINERisanadvanced") }}
        </p>
        <p>
          {{ $t("aiVisionTrainer.byHarnessingThePowerof") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.contact-info-card {
  border: 1px solid rgb(211, 211, 211);
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  min-height: 235px !important;
}
</style>