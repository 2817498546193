<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl">
        <h2>
          {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank2.title') }}
        </h2>
        <div class="goods-ai-warehouses-shema-text">
          <p>
            {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank2.info') }}
          </p>
        </div>
      </div>
      <div class="row pb-60">

        <div class="col-12 col-md-6 col-lg-1 pb-30">
        </div>
        <div class="col-12 col-md-8 col-lg-5 pb-30">
          <div class="bank-cctv-card-overcome">
            <img src="../../assets/images/bank-cctv/1.png" alt="shape" />
            <h5 class="mt-20">
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank2.videoLoss') }}
            </h5>
          </div>
          <div class="bank-cctv-card-overcome">
            <img src="../../assets/images/bank-cctv/2.png" alt="shape" />
            <h5 class="mt-20">
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank2.videoTamper') }}
            </h5>
          </div>
          <div class="bank-cctv-card-overcome">
            <img src="../../assets/images/bank-cctv/3.png" alt="shape" />
            <h5 class="mt-20">
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank2.videoRecordHasStopped') }}
            </h5>
          </div>
          <div class="bank-cctv-card-overcome">
            <img src="../../assets/images/bank-cctv/4.png" alt="shape" />
            <h5 class="mt-20">
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank2.videoParameterShaveChanged') }}
            </h5>
          </div>
          <div class="bank-cctv-card-overcome">
            <img src="../../assets/images/bank-cctv/5.png" alt="shape" />
            <h5 class="mt-20">
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank2.sceneChangeOnCamera') }}
            </h5>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5 pb-30">
          <div class="bank-cctv-card-info">
            <p>
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank2.info2') }}
            </p>
          </div>
          <div class="bank-cctv-card-info">
            <p>
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank2.info3') }}
            </p>
          </div>
          <div class="bank-cctv-card-info">
            <p>
              {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.cardBank2.info4') }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-1 pb-30">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardBankCctvSecond",
};
</script>
