<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl">
        <h2>
          {{ $t("products.PIXAIASol.pixCountShelvesByAICameras.onRamps.cardShelvesStore2.title")}}
        </h2>
        <div class="goods-ai-warehouses-shema-text">
          <p>
            {{ $t("products.PIXAIASol.pixCountShelvesByAICameras.onRamps.cardShelvesStore2.info")}}
          </p>
        </div>
      </div>
      <div class="section-title section-title-xl">
        <h2>
          {{ $t("products.PIXAIASol.pixCountShelvesByAICameras.onRamps.cardShelvesStore2.title2")}}
        </h2>
      </div>

      <div class="form-check form-switch form-switch-lg justify-content-center">
        <p :class="!this.toggle ? 'active' : 'passive'">{{ $t("products.PIXAIASol.pixCountShelvesByAICameras.onRamps.cardShelvesStore2.info2")}}</p>
        <input class="form-check-input" type="checkbox" v-model="toggle" />
        <p :class="this.toggle ? 'active' : 'passive'">{{ $t("products.PIXAIASol.pixCountShelvesByAICameras.onRamps.cardShelvesStore2.info3")}}</p>
      </div>
      <div class="row pb-60">
        <div
          class="col-12 col-md-12 col-lg-12 d-flex justify-content-start"
          v-if="!this.toggle"
        >
        <div v-if="language == 'en'">
          <img
            src="../../assets/images/shelves-cameras-stores/schema-1.png"
            alt="shape"
          />
          </div>
          <div v-else>
            <img
            src="../../assets/images/shelves-cameras-stores/schema-1.png"
            alt="shape"
          />
          </div>
        </div>
        <div
          class="col-12 col-md-12 col-lg-12 d-flex justify-content-start"
          v-if="this.toggle"
        >
        <div v-if="language == 'en'">
          <img
            src="../../assets/images/shelves-cameras-stores/schema-2.png"
            alt="shape"
          />
          </div>
          <div v-else>
            <img
            src="../../assets/images/shelves-cameras-stores/schema-2.png"
            alt="shape"
          />
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardShelvesCamerasStoresSecond",
  data() {
    return {
      toggle: false,
      language: localStorage.getItem("language")
    };
  },
};
</script>

<style scoped>
.active {
  color: rgb(237, 91, 177);
}
.passive {
  color: rgb(1, 1, 1);
}
</style>
