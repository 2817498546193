<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 count-people-card-info-img">
        <img
          src="../../assets/images/vehicle-entrances-license/photo-card-1.png"
          alt="shape"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>
              {{ $t( "products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.byLicensePlateRecognition.CardVehicle.question1" )}}
            </h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p style="line-height: 2.2">
              {{ $t( "products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.byLicensePlateRecognition.CardVehicle.aswer1-1" )}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>

    <div class="row pb-60">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>
              {{ $t( "products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.byLicensePlateRecognition.CardVehicle.question2" )}}
            </h4>
          </div>

          <div class="goods-ai-warehouses-card-info-text">
            <p>
              {{ $t( "products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.byLicensePlateRecognition.CardVehicle.aswer2-1" )}}
            </p>
          </div>
        </div>
        <div class="contact-info-item pt-10">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/difference-1.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t( "products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.byLicensePlateRecognition.CardVehicle.info-1" )}}
              </h6>
            </div>
          </div>
        </div>
       
        <div class="contact-info-item pt-10">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/difference-3.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t( "products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.byLicensePlateRecognition.CardVehicle.info-2" )}}
              </h6>
            </div>
          </div>
        </div> 
     
      </div>
      <div class="col-12 col-md-12 col-lg-6 count-people-card-info-img d-grid justify-content-end">
        <img
          src="../../assets/images/vehicle-entrances-license/photo-card-2.png"
          alt="shape"
          style="height: 445px"
        />
        <div class="contact-info-item pt-10">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/difference-2.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t( "products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.byLicensePlateRecognition.CardVehicle.info-3" )}}
              </h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item pt-10">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/difference-4.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t( "products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.byLicensePlateRecognition.CardVehicle.info-4" )}}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardVehicleEntrancesLicense",
};
</script>
