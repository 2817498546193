<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img align-items-center">
        <img
          src="../../assets/images/goods-barcode-ramps/photo-card-1.png"
          alt="shape"
          style="height: 445px;"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>{{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.cardGoodsBarcode.question1") }}</h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              {{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.cardGoodsBarcode.aswer1-1") }}
            </p>
            <br />
            <p>
              {{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.cardGoodsBarcode.aswer1-2") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>{{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.cardGoodsBarcode.question2") }}</h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              {{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.cardGoodsBarcode.aswer2-1") }}
            </p>
            <br />
            <p>
              {{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.cardGoodsBarcode.aswer2-2") }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/goods-cameras-warehouses/photo-card-2.png"
          alt="shape"
        />
      </div>
    </div>  

    <div class="row pt-60 pb-60">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/goods-cameras-warehouses/photo-card-3.png"
          alt="shape"
          style="padding: 0px 18px 0px 0px"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6 d-grid justify-content-center">
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-1.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>{{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.cardGoodsBarcode.info-1") }}</h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-2.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>{{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.cardGoodsBarcode.info-2") }}</h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-3.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>{{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.cardGoodsBarcode.info-3") }}</h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-4.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                {{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.cardGoodsBarcode.info-4") }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardGoodsBarcodeRamps",
};
</script>
