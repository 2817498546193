import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import BootstrapVue3 from "bootstrap-vue-3";
import "./assets/custom.scss";
import Vue3Autocounter from "vue3-autocounter";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import moshaToast from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import i18n from "./plugins/i18n";


const globalOptions = {
  mode: "auto",
};

const app = createApp(App);
app.component("vue3-autocounter", Vue3Autocounter);
app.component("multiselect", Multiselect);
app.use(VueTelInput, globalOptions);
app.use(moshaToast);
app.use(router);
app.use(BootstrapVue3);
app.use(VueAxios, axios);
app.use(store);
app.use(i18n)
app.mount("#app");
