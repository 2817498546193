<template>
  <div class="bg-off-white">
    <div class="container pt-60 pb-60">
      <div class="section-title section-title-xl">
        <h2>{{ $t('aboutUs.title') }}</h2>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6">
          <div class="about-card mb-24">
            <img src="../../assets/images/about/photo-card-1.png" alt="shape" />
          </div>
          <div class="about-card pb-15">
            <h4>{{ $t('aboutUs.question1') }}</h4>
            <p>{{ $t('aboutUs.option1') }}</p>
            <p>{{ $t('aboutUs.option2') }}</p>
            <p>{{ $t('aboutUs.option3') }}</p>
            <p>{{ $t('aboutUs.option4') }}</p>
            <p>{{ $t('aboutUs.option5') }}</p>
            <p>
              {{ $t('aboutUs.option6') }}
            </p>
            <p>{{ $t('aboutUs.option7') }}</p>
            <p>
              {{ $t('aboutUs.option8') }}
            </p>
            <p>
              {{ $t('aboutUs.option9') }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-6">
          <div class="about-card pb-38">
            <h4>{{ $t('aboutUs.question2') }}</h4>
            <p>
              {{ $t('aboutUs.answer1') }}
            </p>
            <br />
            <p>
              {{ $t('aboutUs.answer2') }}
            </p>
            <br />
            <p>
              {{ $t('aboutUs.answer3') }}
            </p>
            <br />
            <p>
              {{ $t('aboutUs.answer4') }}
            </p>
            <br />
            <p>
              {{ $t('aboutUs.answer5') }}
            </p>
            <br />
            <p>
              {{ $t('aboutUs.answer6') }}
            </p>
            <br />
            <p>
              {{ $t('aboutUs.answer7') }}
            </p>
            <br />
            <p>
              {{ $t('aboutUs.answer8') }}
            </p>
            <br />
            <p>
              {{ $t('aboutUs.answer9') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardAbout",
};
</script>
