<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl">
        <h2>
          {{ $t("products.PIXAIASol.pixCountShelvesByAICameras.inWarehouses.cardShelvesWare2.title") }}
        </h2>
        <div class="goods-ai-warehouses-shema-text">
          <p>
            {{ $t("products.PIXAIASol.pixCountShelvesByAICameras.inWarehouses.cardShelvesWare2.info") }}
          </p>
        </div>
      </div>
      <div class="section-title section-title-xl">
        <h2>{{ $t("products.PIXAIASol.pixCountShelvesByAICameras.inWarehouses.cardShelvesWare2.title2") }}</h2>
      </div>

      <div class="row pb-60">
        <div class="col-12 col-md-12 col-lg-12 d-flex justify-content-end">
          <div v-if="language == 'en'">
            <img src="../../assets/images/shelves-cameras-warehouses/schema.png" alt="shape" />
          </div>
          <div class="me-5" v-else>
            <img src="../../assets/images/shelves-cameras-warehouses/schema_tr.png" style="width: 1000px;" alt="shape" />
          </div>

        </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "CardShelvesCamerasWarehousesSecond",
  data() {
    return {
      language: localStorage.getItem("language")
    }
  }
};
</script>
