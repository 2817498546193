<template>
    <div>
      <Navbar class="bg-white" />
      <PageTitleForLogistic />
      <VisionLogistic />
      <LogisticPowerEfficiency />
      <LogisticOurSolution />
      <LogisticUseCase />
      <SolutionsByIndustriesClients />
      <Footer />
    </div>
  </template>
  
  <script>
  
  import Navbar from "../Layout/Navbar";
  import PageTitleForLogistic from "../Logistic/PageTitleForLogistic.vue";
  import VisionLogistic from "../Logistic/VisionLogistic.vue";
  import LogisticPowerEfficiency from "../Logistic/LogisticPowerEfficiency.vue";
  import LogisticOurSolution from "../Logistic/LogisticOurSolution.vue";
  import LogisticUseCase from "../Logistic/LogisticUseCase.vue";
  import SolutionsByIndustriesClients from "../../components/Home/Partners.vue";
  import Footer from "../Layout/Footer";

  export default {
    name: "LogisticPage",
    components: {
      Navbar,
      PageTitleForLogistic,
      VisionLogistic,
      LogisticPowerEfficiency,
      LogisticOurSolution,
      LogisticUseCase,
      SolutionsByIndustriesClients,
      Footer,
    },
  };
  </script>
  