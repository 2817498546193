<template>
    <div>
      <Navbar class="bg-white" />
      <PageTitleForCareers/>
      <PIXAcademyForm pageTitle="PIXSELECT CAREER REGISTRATION FORM"/>
      <Footer />
    </div>
  </template>

  <script>
  import Navbar from "../Layout/Navbar";
  import PageTitleForCareers from "../PIXSelectCareers/PageTitleForCareers.vue";
  import PIXAcademyForm from "../PIXSelectAcademy/PIXAcademyForm.vue";
  import Footer from "../Layout/Footer";
  
  export default {
    name: "PIXSelectCareers",
    components: {
      Navbar,
      PageTitleForCareers,
      PIXAcademyForm,
      Footer,
    },
  };
  </script>
