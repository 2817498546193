<template>
  <div class="container">
    <div
      style="background-image: linear-gradient(to right, #852dee, #5044ff)"
      class="inner-page-header-academy"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm-9 col-lg-9">
            <div class="inner-header-content-demo-request">
              <h2>{{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.contactForm.contactUs") }}</h2>
              <h3>
                {{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.contactForm.contactInfo") }}
              </h3>
              <br />
              <div class="pl-4 mb-10">
                <router-link
                  to="/pixacademy-registration"
                  class="btn main-btn-academy"
                >
                {{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.contactForm.contact") }}
                </router-link>
              </div>
            </div>
          </div>

          <div class="col-sm col-lg d-flex justify-content-center">
            <img
              class="demo-request-img"
              src="../../assets/images/icon-4.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
