
import { createI18n } from 'vue-i18n'
import { tr, en} from './languages/index'

const messages = {
  en: en,
  tr: tr
}
let language = localStorage.getItem("language")
// 2. Create i18n instance with options
const i18n = createI18n({
  locale: language, // set locale
  fallbackLocale: 'en' , // set fallback locale
  messages, // set locale messages
})

export default i18n;