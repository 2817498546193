<template>
    <div>
      <Navbar class="bg-white" />
      <PageTitleForPartners />
      <OfferSolutions />
      <TechnologyPartners />
      <BenefitsResellers />
      <AuthorizedReseller />
      <ClientsPartners />
      <Footer />
    </div>
  </template>
  
  <script>
  import Navbar from "../Layout/Navbar";
  import PageTitleForPartners from "../Partners/PageTitleForPartners.vue";
  import OfferSolutions from "../Partners/OfferSolutions.vue";
  import TechnologyPartners from "../Partners/TechnologyPartners.vue";
  import BenefitsResellers from "../Partners/BenefitsResellers.vue";
  import AuthorizedReseller from "../Partners/AuthorizedReseller.vue";
  import ClientsPartners from "../Partners/ClientsPartners.vue";
  import Footer from "../Layout/Footer";
  
  export default {
    name: "Partners",
    components: {
      Navbar,
      PageTitleForPartners,
      OfferSolutions,
      TechnologyPartners,
      BenefitsResellers,
      AuthorizedReseller,
      ClientsPartners,
      Footer,
    },
  };
  </script>
  