<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
        <h2>
          {{ $t(
            "products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.byLicensePlateRecognition.CardVehicle2.title"
          ) }}
        </h2>
      </div>
      <div class="row pb-60 d-flex justify-content-center">
        <div class="col-12 col-md-8 col-lg-4">
          <div class="turnstiles-overcome-card">
            <h5>
              {{ $t(
                "products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.byLicensePlateRecognition.CardVehicle2.info1"
              ) }}
            </h5>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-4">
          <div class="turnstiles-overcome-card">
            <h5>
              {{ $t(
                "products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.byLicensePlateRecognition.CardVehicle2.info2"
              ) }}
            </h5>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-4">
          <div class="turnstiles-overcome-card">
            <h5>
              {{ $t(
                "products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.byLicensePlateRecognition.CardVehicle2.info3"
              ) }}
            </h5>
          </div>
        </div>
      </div>
      <div class="section-title section-title-xl">
        <h2>
          {{ $t(
            "products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.byLicensePlateRecognition.CardVehicle2.title2"
          ) }}
        </h2>
      </div>

      <div class="row pb-60">
        <div class="col-12 col-md-12 col-lg-12 d-flex justify-content-end">
          <div v-if="language == 'en'">
            <img src="../../assets/images/vehicle-entrances-license/schema.png" alt="shape" />
          </div>
          <div v-else>
            <img src="../../assets/images/vehicle-entrances-license/schema_tr.png" alt="shape" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardVehicleEntrancesLicenseSecond",
  data() {
    return {
      language: localStorage.getItem("language")
    }
  }
};
</script>
