<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-lg">
        <h2>{{  $t('partners.howAuthorizedReseller.title') }}</h2>
      </div>
      <div class="row authorized-reseller-height">
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="authorized-reseller-card">
            <img src="../../assets/images/partners/card-1.png" alt="shape" />
            <p>{{  $t('partners.howAuthorizedReseller.info1') }}</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="authorized-reseller-card">
            <img src="../../assets/images/partners/card-2.png" alt="shape" />
            <p>{{  $t('partners.howAuthorizedReseller.info2') }}</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="authorized-reseller-card">
            <img src="../../assets/images/partners/card-3.png" alt="shape" />
            <p>
              {{  $t('partners.howAuthorizedReseller.info3') }}
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="authorized-reseller-card">
            <img src="../../assets/images/partners/card-4.png" alt="shape" />
            <p>
              {{  $t('partners.howAuthorizedReseller.info4') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AuthorizedReseller",
});
</script>
