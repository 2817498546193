<template>
  <div class="container pb-30">
    <div class="row">
      <div class="section-image">
        <img
          style="max-width: 60%"
          src="../../assets/images/pixselect-academy-logo.svg"
          alt="shape"
        />
      </div>
    </div>
  </div>
  <div class="retail-section-card-our-solution">
    <div class="container pb-30">
      <div class="row">
        <div class="col-12">
          <div class="saas-info-card">
            <h5>
              {{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.info") }}
            </h5>
            <h5>
              {{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.info2") }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaasEmployersCard",
};
</script>
