<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/retail-cctv/photo-card-1.png"
          alt="shape"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>
              {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.question1' ) }}
            </h4>
          </div>

          <div class="goods-ai-warehouses-card-info-text">
            <p>
              {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.answer1-1' ) }}
            </p>
            <br />
            <p>
              {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.answer1-2' ) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row pb-60">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>
              {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.question2' ) }}
            </h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.answer2-1' ) }}
            </p>
            <br />
            <p>
              {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.answer2-2' ) }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/retail-cctv/photo-card-2.png"
          alt="shape"
        />
      </div>

      <div class="row pt-30">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img src="../../assets/images/1.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.info-1' ) }}
                </h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header bank-cctv-info-header">
              <img src="../../assets/images/2.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.info-2' ) }}
                </h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img src="../../assets/images/3.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.info-3' ) }}
                </h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header bank-cctv-info-header">
              <img src="../../assets/images/4.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.info-4.title' ) }}
                </h6>
                <p>
                  - {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.info-4.content.line1' ) }} <br />
                  - {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.info-4.content.line2' ) }}<br />
                  - {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.info-4.content.line3' ) }}<br />
                  - {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.info-4.content.line4' ) }}<br />
                  - {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.info-4.content.line5' ) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header bank-cctv-info-header">
              <img src="../../assets/images/5.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.info-5' ) }}
                </h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img src="../../assets/images/6.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.info-6' ) }}
                </h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img src="../../assets/images/7.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.info-7' ) }}
                </h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img src="../../assets/images/8.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.info-8' ) }}
                </h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img src="../../assets/images/9.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  {{ $t( 'products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.cardRetail.info-9' ) }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardRetailCctv",
};
</script>
