<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleForTurnstilesFaceRecognition />
    <CardTurnstilesFaceRecognition />
    <CardTurnstilesFaceRecognitionSecond />
    <CareerTurnstilesFaceRecognition />
    <CardTurnstilesFaceRecognitionThird />
    <ClientsTurnstilesFaceRecognition />
    <BlogTurnstilesFaceRecognition />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../Layout/Navbar";
import PageTitleForTurnstilesFaceRecognition from "../../../TurnstilesFaceRecognition/PageTitleForTurnstilesFaceRecognition.vue";
import CardTurnstilesFaceRecognition from "../../../TurnstilesFaceRecognition/CardTurnstilesFaceRecognition.vue";
import CardTurnstilesFaceRecognitionSecond from "../../../TurnstilesFaceRecognition/CardTurnstilesFaceRecognitionSecond.vue";
import CareerTurnstilesFaceRecognition from "../../../TurnstilesFaceRecognition/CareerTurnstilesFaceRecognition.vue";
import CardTurnstilesFaceRecognitionThird from "../../../TurnstilesFaceRecognition/CardTurnstilesFaceRecognitionThird.vue";
import ClientsTurnstilesFaceRecognition from "../../../TurnstilesFaceRecognition/ClientsTurnstilesFaceRecognition.vue";
import BlogTurnstilesFaceRecognition from "../../../TurnstilesFaceRecognition/BlogTurnstilesFaceRecognition.vue";
import Footer from "../../../Layout/Footer";

export default {
  name: "TurnstilesFaceRecognition",
  components: {
    Navbar,
    PageTitleForTurnstilesFaceRecognition,
    CardTurnstilesFaceRecognition,
    CardTurnstilesFaceRecognitionSecond,
    CareerTurnstilesFaceRecognition,
    CardTurnstilesFaceRecognitionThird,
    ClientsTurnstilesFaceRecognition,
    BlogTurnstilesFaceRecognition,
    Footer,
  },
};
</script>
