<template>
  <div :class="['fixed-top', { 'non-fixed': isSticky }]">
    <div class="topbar">
      <div class="container">
        <div class="topbar-inner">
          <div class="row justify-content-center justify-content-lg-between">
            <div class="topbar-item topbar-left">
              <ul class="topbar-list">
                <li>
                  <i class="icofont-headphone"></i>
                  <a href="tel:(+90)-850-225-04-49">+90 850 225 04 49</a>
                </li>
                <li>
                  <i class="icofont-ui-message"></i>
                  <a href="mailto:info@pixselect.io">info@pixselect.io</a>
                </li>
              </ul>
            </div>
            <div class="topbar-item topbar-right">
              <ul class="topbar-list">
                <li>
                  <i class="icofont-paper-plane"></i>
                  <router-link to="/contact">{{ $t('navbar.top-bar.requestCall') }}</router-link>
                </li>
                <li class="search-option" @click="overlay = !overlay" :aria-pressed="overlay ? 'true' : 'false'"
                  v-bind:class="{ overlay: button_overlay_state }"
                  v-on:click="button_overlay_state = !button_overlay_state">
                  <i class="icofont-search-1"></i>
                  <a href="#" class="search-popup">{{ $t('navbar.top-bar.search') }}</a>
                </li>
                <li>

                  <div class="btn-group" role="group" aria-label="Basic radio toggle button group">

                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" v-bind:checked="this.language=='tr'">
                    <label class="btn btn-outline-primary btn-sm" for="btnradio1" @click="changeLanguage('tr')">TR</label>

                    <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" v-bind:checked="this.language!='tr'">
                    <label class="btn btn-outline-primary btn-sm" for="btnradio2" @click="changeLanguage('en')">EN</label>

                  </div>

                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="['navbar-area sticky-black', { 'is-sticky': isSticky }]">
      <div class="main-nav">
        <div class="container">
          <nav class="navbar navbar-expand-md navbar-light">
            <router-link class="navbar-brand" to="/">
              <img src="../../assets/images/pixselect_logo.png" alt="logo" class="logo" />
            </router-link>

            <div class="navbar-toggler" @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
              v-bind:class="{ active: button_active_state }" v-on:click="button_active_state = !button_active_state">
              <i class="icofont-navigation-menu"></i>
              <i class="icofont-close"></i>
            </div>

            <div class="collapse navbar-collapse" :class="{ show: active }">
              <ul class="navbar-nav mx-auto">
                <li class="nav-item">
                  <router-link to="/" class="nav-link"> {{ $t('home.title') }} </router-link>
                </li>
                <!-- <li class="nav-item">
                                    <router-link to="/about-us" class="nav-link">
                                        About Us
                                    </router-link>
                                </li> -->
                <li class="nav-item">
                  <a href="#" class="nav-link dropdown-toggle">{{ $t('products.title') }}</a>
                  <ul class="dropdown-menu dropdown-menu-products">
                    <div class="row m-4 lh-lg">
                      <div class="col-sm">
                        <li class="nav-item">
                          <a href="#" class="nav-link font-weight-bold">
                            <span class="fw-bold">{{ $t('products.pixSVMS-CCTVMonitoringSolutions.title') }}</span>
                          </a>
                          <ul>
                            <li class="nav-item">
                              <router-link to="/bank-cctv" class="nav-link">
                                - {{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.title')
                                }}
                              </router-link>
                            </li>
                            <li class="nav-item">
                              <router-link to="/retail-cctv" class="nav-link">
                                - {{ $t('products.pixSVMS-CCTVMonitoringSolutions.retailCCTVMonitoring.title') }}
                              </router-link>
                            </li>
                            <li class="nav-item">
                              <router-link to="/warehouses-cctv" class="nav-link">
                                - {{
                                  $t('products.pixSVMS-CCTVMonitoringSolutions.warehouseAndFactoryCCTVMonitoring.title') }}
                              </router-link>
                            </li>
                          </ul>
                        </li>
                      </div>
                      <div class="col-sm">
                        <li class="nav-item">
                          <a href="#" class="nav-link">
                            <span class="fw-bold">{{ $t('products.PIXPassAccessAndLogSolutions.title') }}</span>
                          </a>
                          <ul>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                - {{ $t('products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.title') }}
                              </a>
                              <ul class="ms-4">
                                <li class="nav-item">
                                  <router-link to="/turnstiles-id-cards" class="nav-link">
                                    {{
                                      $t('products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byIdCards.title')
                                    }}
                                  </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link to="/turnstiles-face-recognition" class="nav-link">
                                    {{
                                      $t('products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.title')
                                    }}
                                  </router-link>
                                </li>
                              </ul>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                - {{ $t('products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.title') }}
                              </a>
                              <ul class="ms-4">
                                <li class="nav-item">
                                  <router-link to="/vehicle-entrances-license" class="nav-link">
                                    {{
                                      $t('products.PIXPassAccessAndLogSolutions.vehicleEntrancesAndRamps.byLicensePlateRecognition.title')
                                    }}
                                  </router-link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </div>
                      <div class="col-sm">
                        <li class="nav-item">
                          <a href="/aivisiontrainer" class="nav-link">
                            <span class="fw-bold">AIVISIONTRAINER</span>
                          </a>
                          <ul>
                            <li class="nav-item">
                              <a href="/aivisiontrainer" class="nav-link">
                                - {{ $t("aiVisionTrainer.saaSCVMembership") }}
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="/aivisiontrainer" class="nav-link">
                                - {{ $t("aiVisionTrainer.saaSCloudTagMembership") }}
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="/aivisiontrainer" class="nav-link">
                                - {{ $t("aiVisionTrainer.saaSCloudTagFullMembership") }}
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="/aivisiontrainer" class="nav-link">
                                - {{ $t("aiVisionTrainer.AIFollowupProgramme") }}
                              </a>
                            </li>
                          </ul>
                        </li>
                      </div>
                      <div class="col-sm">
                        <li class="nav-item">
                          <a href="#" class="nav-link">
                            <span class="fw-bold">{{ $t('products.PIXAIASol.title') }}</span>
                          </a>
                          <ul>
                            <li class="nav-item">
                              <router-link to="/pixcount-people" class="nav-link">
                                - {{ $t('products.PIXAIASol.pixCountPeople.title') }}
                              </router-link>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                - {{ $t('products.PIXAIASol.pixCountGoodsByBarcodeAndQR.title') }}
                              </a>
                              <ul class="ms-4">
                                <li class="nav-item">
                                  <router-link to="/goods-barcode-warehouses" class="nav-link">
                                    {{ $t('products.PIXAIASol.pixCountGoodsByBarcodeAndQR.inWarehouses.title') }}
                                  </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link to="/goods-barcode-ramps" class="nav-link">
                                    {{ $t('products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.title') }}
                                  </router-link>
                                </li>
                              </ul>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                - {{ $t('products.PIXAIASol.pixCountGoodsByAICameras.title') }}
                              </a>
                              <ul class="ms-4">
                                <li class="nav-item">
                                  <router-link to="/goods-cameras-warehouses" class="nav-link">
                                    {{ $t('products.PIXAIASol.pixCountGoodsByAICameras.inWarehouses.title') }}
                                  </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link to="/goods-cameras-ramps" class="nav-link">
                                    {{ $t('products.PIXAIASol.pixCountGoodsByAICameras.onRamps.title') }}
                                  </router-link>
                                </li>
                              </ul>
                            </li>
                            <li class="nav-item">
                              <a href="#" class="nav-link">
                                - {{ $t('products.PIXAIASol.pixCountShelvesByAICameras.title') }}
                              </a>
                              <ul class="ms-4">
                                <li class="nav-item">
                                  <router-link to="/shelves-cameras-warehouses" class="nav-link">
                                    {{ $t('products.PIXAIASol.pixCountShelvesByAICameras.inWarehouses.title') }}
                                  </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link to="/shelves-cameras-stores" class="nav-link">
                                    {{ $t('products.PIXAIASol.pixCountShelvesByAICameras.onRamps.title') }}
                                  </router-link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </div>
                    </div>
                  </ul>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link dropdown-toggle">
                    {{ $t('solutionByIndustries.title') }}
                  </a>
                  <ul class="dropdown-menu dropdown-menu-solution-by-ınd">
                    <div class="row m-4">
                      <div class="col-sm">
                        <li class="nav-item text-end">
                          <router-link to="/finance" class="nav-link">
                            <span class="fw-bold">{{ $t('solutionByIndustries.finance.title') }}</span>
                          </router-link>
                        </li>
                      </div>
                      <div class="col-sm">
                        <li class="nav-item text-center">
                          <router-link to="/logistic" class="nav-link">
                            <span class="fw-bold">{{ $t('solutionByIndustries.logistic.title') }}</span>
                          </router-link>
                        </li>
                      </div>
                      <div class="col-sm">
                        <li class="nav-item">
                          <router-link to="/retail" class="nav-link">
                            <span class="fw-bold">{{ $t('solutionByIndustries.retail.title') }}</span>
                          </router-link>
                        </li>
                      </div>
                    </div>
                  </ul>
                </li>
                <li class="nav-item">
                  <router-link to="/aivisiontrainer" class="nav-link">AIVISIONTRAINER</router-link>
                </li>
      
                <li class="nav-item">
                  <router-link to="/partners" class="nav-link">{{ $t('partners.title') }}</router-link>
                </li>
              </ul>
            </div>
            <div class="navbar-option">
              <div class="navbar-option-item">
                <router-link to="/demo-request" class="btn main-btn">
                  {{ $t('demo.demo-request') }}
                </router-link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="search-overlay" :class="{ search: overlay }">
      <div class="search-close" @click="overlay = !overlay">
        <i class="icofont-close"></i>
      </div>
      <div class="search-form-area">
        <div class="search-area-logo">
          <router-link to="/">
            <img src="../../assets/images/pixselect-white.png" alt="logo" />
          </router-link>
        </div>
        <form>
          <div class="form-group search-form-group">
            <input type="text" placeholder="Search..." class="form-control" autofocus="autofocus" />
            <button>
              <i class="icofont-search-1"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Navbar",
  data() {
    return {
      isSticky: false,
      isMenu: false,
      active: false,
      button_active_state: false,
      overlay: false,
      button_overlay_state: false,
      language: localStorage.getItem("language")
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
  methods: {
    // this method change web language (to store)
    changeLanguage(lan) {
      localStorage.setItem('language', lan)
      this.$router.go(0)
    }
  }
};
</script>

<style scoped>
.btn-check:active+.btn,
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:active {
  color: #fff;
  background-color: #1f12fd;
  border-color: #1f12fd;
}

.navbar {
  box-shadow: 0 0px 0px 0;
}</style>
