<template>
  <div>
    <Navbar class="bg-white" />
    <MainBanner />
    <SolutionsByIndustries />
    <BenefitResellers />
    <Products />
    <Partners />
    <LatestPosts />
    <!-- <Feature />
    <About /> -->
    <!-- <Services />
    <FreeTrial />
    <Process />
    <Testimonial />
    <Blog class="bg-off-white" />
    <Newsletter /> -->
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar.vue";
import MainBanner from "../Home/MainBanner.vue";
import SolutionsByIndustries from "../Home/SolutionsByIndustries.vue";
import BenefitResellers from "../Home/BenefitResellers.vue";
import Products from "../Home/Products.vue";
import Partners from "../Home/Partners.vue";
import LatestPosts from "../Home/LatestPosts.vue";

// import Feature from "../HomeOne/Feature";
// import About from "../Common/About";
// import Services from "../Common/Services";
// import FreeTrial from "../Common/FreeTrial";
// import Process from "../HomeOne/Process";
// import Testimonial from "../HomeOne/Testimonial";
// import Blog from "../Common/Blog";
// import Newsletter from "../Common/Newsletter";
import Footer from "../Layout/Footer";

export default {
  name: "HomePageOne",
  components: {
    Navbar,
    MainBanner,
    SolutionsByIndustries,
    BenefitResellers,
    Products,
    Partners,
    LatestPosts,
    // Feature,
    // About,
    // Services,
    // FreeTrial,
    // Process,
    // Testimonial,
    // Blog,
    // Newsletter,
    Footer,
  },
};
</script>
