<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl">
        <h2>
          {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam2.title" )}}
        </h2>
        <div class="goods-ai-warehouses-shema-text">
          <p>
            {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam2.info" )}}
          </p>
        </div>
      </div>
      <div class="row pb-60">
        <div class="col-12 col-md-6 col-lg-3">
          <div class="goods-card-left-top">
            <div class="goods-card-left-top-info">
              <img
                src="../../assets/images/goods-cameras-warehouses/left-top.png"
                alt="shape"
              />
              <h6>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam2.info1" )}}
              </h6>
            </div>
          </div>
          <div class="goods-card-left-bottom mt-20">
            <div class="goods-card-left-bottom-info">
              <img
                src="../../assets/images/goods-cameras-warehouses/left-bottom.png"
                alt="shape"
              />
              <h6>{{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam2.info2" )}}</h6>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-12 col-lg-6 p-50 d-flex justify-content-center align-items-center"
        >
          <div class="goods-card-center-item">
            <h6>
              {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam2.info5" )}}
            </h6>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="goods-card-right-top">
            <div class="goods-card-right-top-info">
              <img
                src="../../assets/images/goods-cameras-warehouses/right-top.png"
                alt="shape"
              />
              <h6>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam2.info3" )}}
              </h6>
            </div>
          </div>
          <div class="goods-card-right-bottom mt-20">
            <div class="goods-card-right-bottom-info">
              <img
                src="../../assets/images/goods-cameras-warehouses/right-bottom.png"
                alt="shape"
              />
              <h6>{{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam2.info4" )}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardGoodsCamerasRampsSecond",
};
</script>
