<template>
    <div class="contact-info-section pt-100 pb-100">
        <div class="container">
            <div class="section-title section-title-lg">
                <small>{{ $t("contact.title") }}</small>
                <h2>{{ $t("contact.pageTitle.info") }}</h2>
                <p>{{ $t("contact.pageTitle.info2") }}</p>
            </div>
            <!-- Contact-info -->
            <div class="contact-info mb-15">
                <div class="row">
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-location-pin"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>{{ $t("contact.header.location.title") }}</h3>
                                    <p>{{ $t("contact.header.location.visit") }}</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                <p>{{ $t("contact.header.location.address1") }} </p>
                                <p>{{ $t("contact.header.location.address2") }}</p>
                                <p>{{ $t("contact.header.location.address3") }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-ui-call"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>{{ $t("contact.header.makeCall.title") }}</h3>
                                    <p>{{ $t("contact.header.makeCall.talk") }}</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                <p><a href="tel:(+90)-850-225-04-49">(+90) 850 225 04 49</a></p>
                                <p>{{ $t("contact.header.makeCall.hour") }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30 offset-lg-0 offset-md-3">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-email"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>{{ $t("contact.header.sendEmaile.title") }}</h3>
                                    <p>{{ $t("contact.header.sendEmaile.mail") }}</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                <p><a href="mailto:info@pixselect.io">info@pixselect.io</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Contact-info -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactInfo'
}
</script>