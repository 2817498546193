<template>
  <div class="container pt-60">
    <div class="demo-request-header">
      <h1>AUTHORIZED RESELLER APPLICATION FORM</h1>
      <p>We’ll help you to find the suitable solution and pricing</p>
    </div>

    <form @submit.prevent="submit">
      <div class="demo-request-form-card">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group mb-20">
              <label>Name and Surname</label>
              <input
                type="text"
                id="name"
                name="name"
                class="form-control"
                placeholder="Enter your name"
                v-model="form.name"
                required
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group mb-20">
              <label>Phone</label>
              <vue-tel-input
                v-model="form.phone"
                mode="international"
                :preferredCountries="['us', 'gb', 'tr']"
                defaultCountry="us"
                name="phone"
                id="phone"
                required="true"
                validCharactersOnly="true"
              ></vue-tel-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group mb-20">
              <label>Email</label>
              <input
                v-model="form.eMail"
                type="email"
                id="email"
                name="email"
                class="form-control"
                placeholder="example@mail.com"
                required
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group mb-20">
              <label>Company </label>
              <input
                v-model="form.company"
                type="text"
                id="company"
                name="company-name"
                class="form-control"
                placeholder="Enter company name"
                required
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group mb-20">
              <label>Comments</label>
              <textarea
                v-model="form.comments"
                type="text"
                id="comments"
                name="comments"
                class="form-control"
                placeholder="Please add your comments"
                rows="4"
                required
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group mb-20">
              <label>Which sector is your company operating?</label>
              <div class="form-group-btn">
                <div
                  id="btn-retail"
                  class="btn-multiselect mr-10"
                  @click="addSector(1)"
                >
                  <h5>Retail</h5>
                </div>
                <div
                  id="btn-logistics"
                  class="btn-multiselect mr-10"
                  @click="addSector(2)"
                >
                  <h5>Logistics</h5>
                </div>
                <div
                  id="btn-finance"
                  class="btn-multiselect"
                  @click="addSector(3)"
                >
                  <h5>Finance</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group mb-20">
              <label>Which Product(s) do you wish to sell?</label>
              <multiselect
                v-model="form.product"
                :options="options"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="true"
                :preserve-search="true"
                placeholder="Please select item / items"
                label="text"
                track-by="text"
              ></multiselect>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group mb-20">
              <div class="form-check">
                <input
                  v-model="form.isPrivacyPolicy"
                  class="form-check-input"
                  type="checkbox"
                  id="check"
                  required
                />
                <label class="form-check-label">
                  By clicking the button, you agree to the Terms of Service and
                  Privacy Policy
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group mb-20">
              <button class="btn demo-request-form-btn" type="submit">
                <h5 class="m-0">GET STARTED</h5>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
// import { mapActions } from "vuex";
// import { createToast } from "mosha-vue-toastify";
import Multiselect from "vue-multiselect";

export default defineComponent({
  name: "ResellerForm",
  components: {
    VueTelInput,
    Multiselect,
  },
  data() {
    return {
      options: [
        { text: "PIXSVMS Retail", value: 1 },
        { text: "PIXSVMS Logistic", value: 2 },
        { text: "PIXSVMS Finance", value: 3 },
        { text: "PIXCount People", value: 4 },
        { text: "PIXCount Goods by Barcode/QR", value: 5 },
        { text: "PIXCount Goods by AI Cameras", value: 6 },
        { text: "PIXCount Shelves by AI Cameras", value: 7 },
        { text: "PIXPass", value: 8 },
      ],
      form: {
        name: "",
        phone: "",
        eMail: "",
        company: "",
        comments: "",
        sector: [],
        product: "",
        isPrivacyPolicy: false,
      },
    };
  },
  methods: {
    // ...mapActions("main", ["postFormDemoRequest"]),
    submit() {
      console.log(this.form);
      // this.postFormDemoRequest(this.form)
      //   .then((res) => {
      //     createToast(res.message, {
      //       toastBackgroundColor: "#28a745",
      //       type: "success",
      //     });
      //   })
      //   .then(() => {
      //     this.addSector(4);
      //     this.form = {
      //       name: "",
      //       phone: "",
      //       eMail: "",
      //       company: "",
      //       comments: "",
      //       sector: [],
      //       whatNeed: "",
      //       isPrivacyPolicy: false,
      //     };
      //   })
      //   .catch((res) => {
      //     createToast(res.response.data.message, {
      //       toastBackgroundColor: "#dc3545",
      //       type: "danger",
      //     });
      //   });
    },
    addSector(id) {
      if (id === 1) {
        let el = document.getElementById("btn-retail");
        el.classList.toggle("btn-multiselect-active");
        let isCheck = false;
        for (let i = 0; i < this.form.sector.length; i++) {
          if (this.form.sector[i] == "retail") {
            isCheck = true;
          }
        }
        if (isCheck) {
          this.form.sector = this.form.sector.filter(
            (value) => value !== "retail"
          );
        } else {
          this.form.sector.push("retail");
        }
      } else if (id === 2) {
        let el = document.getElementById("btn-logistics");
        el.classList.toggle("btn-multiselect-active");
        let isCheck = false;
        for (let i = 0; i < this.form.sector.length; i++) {
          if (this.form.sector[i] == "logistics") {
            isCheck = true;
          }
        }
        if (isCheck) {
          this.form.sector = this.form.sector.filter(
            (value) => value !== "logistics"
          );
        } else {
          this.form.sector.push("logistics");
        }
      } else if (id === 3) {
        let el = document.getElementById("btn-finance");
        el.classList.toggle("btn-multiselect-active");
        let isCheck = false;
        for (let i = 0; i < this.form.sector.length; i++) {
          if (this.form.sector[i] == "finance") {
            isCheck = true;
          }
        }
        if (isCheck) {
          this.form.sector = this.form.sector.filter(
            (value) => value !== "finance"
          );
        } else {
          this.form.sector.push("finance");
        }
      } else {
        let el = document.getElementById("btn-retail");
        el.classList.remove("btn-multiselect-active");
        let el1 = document.getElementById("btn-logistics");
        el1.classList.remove("btn-multiselect-active");
        let el2 = document.getElementById("btn-finance");
        el2.classList.remove("btn-multiselect-active");
      }
    },
  },
});
</script>
