<template>
  <div class="inner-page-header">
    <div class="container">
      <div class="row">
        <div class="col-sm d-flex align-items-center">
          <div class="inner-header-content-academy">
            <h1>{{ $t('solutionByIndustries.retail.pageTitle.title') }}</h1>
            <br />
            <h3>
              {{ $t('solutionByIndustries.retail.pageTitle.info') }}
            </h3>
            <br />
            <div>
              <router-link to="/demo-request" class="btn main-btn-academy">
                {{ $t('demo.demo-request') }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-sm d-flex justify-content-end">
          <img
            class="retail-page-header-img"
            src="../../assets/images/retail/retail-header.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitleForRetail",
  props: ["pageTitle"],
};
</script>
