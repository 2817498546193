<template>
  <div class="pricing-section pt-60 pb-60 bg-off-white">
    <div class="container">
      <div class="section-title section-title-lg">
        <h2>
          {{ $t("solutionByIndustries.finance.cardPricing4.title") }}
        </h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="pricing-card-usecase">
            <img src="../../assets/images/usecase-1.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="pricing-card-usecase-title">
            <h4>
              {{ $t("solutionByIndustries.finance.cardPricing4.secAtm.title") }}
            </h4>
          </div>
          <div class="pricing-card-usecase-problem">
            <div class="pricing-card-usecase-title">
              <h6>{{ $t("solutionByIndustries.finance.cardPricing4.secAtm.problem") }}</h6>
            </div>
            <div class="pricing-card-usecase-brief-atm">
              <p>
                {{ $t("solutionByIndustries.finance.cardPricing4.secAtm.problemInfo") }}
              </p>
            </div>
          </div>
          <div class="pricing-card-usecase-solution">
            <div class="pricing-card-usecase-title">
              <h6>{{ $t("solutionByIndustries.finance.cardPricing4.secAtm.solution") }}</h6>
            </div>
            <div class="pricing-card-usecase-brief-atm">
              <p>
                {{ $t("solutionByIndustries.finance.cardPricing4.secAtm.solutionInfo") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="pricing-card-usecase">
            <img src="../../assets/images/usecase-2.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="pricing-card-usecase-title">
            <h4>{{ $t("solutionByIndustries.finance.cardPricing4.secBank.title") }}</h4>
          </div>
          <div class="pricing-card-usecase-problem">
            <div class="pricing-card-usecase-title">
              <h6>{{ $t("solutionByIndustries.finance.cardPricing4.secBank.problem") }}</h6>
            </div>
            <div class="pricing-card-usecase-brief-bank">
              <p>
                {{ $t("solutionByIndustries.finance.cardPricing4.secBank.problemInfo") }}
              </p>
            </div>
          </div>
          <div class="pricing-card-usecase-solution">
            <div class="pricing-card-usecase-title">
              <h6>{{ $t("solutionByIndustries.finance.cardPricing4.secBank.solution") }}</h6>
            </div>
            <div class="pricing-card-usecase-brief-bank">
              <p>
                {{ $t("solutionByIndustries.finance.cardPricing4.secBank.solutionInfo") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="pricing-card-usecase">
            <img src="../../assets/images/usecase-3.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="pricing-card-usecase-title">
            <h4>{{ $t("solutionByIndustries.finance.cardPricing4.secCash.title") }}</h4>
          </div>
          <div class="pricing-card-usecase-problem">
            <div class="pricing-card-usecase-title">
              <h6>{{ $t("solutionByIndustries.finance.cardPricing4.secCash.problem") }}</h6>
            </div>
            <div class="pricing-card-usecase-brief-transfer">
              <p>
                {{ $t("solutionByIndustries.finance.cardPricing4.secCash.problemInfo") }}
              </p>
            </div>
          </div>
          <div class="pricing-card-usecase-solution">
            <div class="pricing-card-usecase-title">
              <h6>{{ $t("solutionByIndustries.finance.cardPricing4.secCash.solution") }}</h6>
            </div>
            <div class="pricing-card-usecase-brief-transfer">
              <p>
                {{ $t("solutionByIndustries.finance.cardPricing4.secCash.solutionInfo1") }}
              </p>
              <p>
                {{ $t("solutionByIndustries.finance.cardPricing4.secCash.solutionInfo2") }}
              </p>
              <p>
                {{ $t("solutionByIndustries.finance.cardPricing4.secCash.solutionInfo3") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardPricingFourth",
};
</script>
