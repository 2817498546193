<template>
  <div class="home-page-solution-background">
    <div class="container pt-60">
      <div class="row pb-60">
        <div class="col-12 col-md-12 col-lg-7">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="home-page-solution-card mb-30">
                <div v-if="arr[0] == 'card1'">
                  <div id="card-1" @click="changeCard(1)">
                    <img src="../../assets/images/homepage/solution-card-2.png" alt="shape" />
                    <h3>{{ $t('home.solutionsByIndustries.finance.title') }}</h3>
                    <p>{{ $t('home.solutionsByIndustries.finance.info') }}</p>
                    <div class="home-page-solution-arrow">
                      <h5>{{ $t('home.solutionsByIndustries.readMore') }}</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
                <div v-else-if="arr[0] == 'card2'">
                  <div id="card-2" @click="changeCard(2)">
                    <img src="../../assets/images/homepage/solution-card-1.png" alt="shape" />
                    <h3>{{ $t('home.solutionsByIndustries.logistic.title') }}</h3>
                    <p>{{ $t('home.solutionsByIndustries.logistic.info') }}</p>
                    <div class="home-page-solution-arrow">
                      <h5>{{ $t('home.solutionsByIndustries.readMore') }}</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
                <div v-else-if="arr[0] == 'card3'">
                  <div id="card-3" @click="changeCard(3)">
                    <img src="../../assets/images/homepage/solution-card-1.png" alt="shape" />
                    <h3>{{ $t('home.solutionsByIndustries.retail.title') }}</h3>
                    <p>{{ $t('home.solutionsByIndustries.retail.info') }}</p>
                    <div class="home-page-solution-arrow">
                      <h5>{{ $t('home.solutionsByIndustries.readMore') }}</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="home-page-solution-card pb-30">
                <div v-if="arr[1] == 'card1'">
                  <div id="card-1" @click="changeCard(1)">
                    <img src="../../assets/images/homepage/solution-card-2.png" alt="shape" />
                    <h3>{{ $t('home.solutionsByIndustries.finance.title') }}</h3>
                    <p>{{ $t('home.solutionsByIndustries.finance.info') }}</p>
                    <div class="home-page-solution-arrow">
                      <h5>{{ $t('home.solutionsByIndustries.readMore') }}</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
                <div v-else-if="arr[1] == 'card2'">
                  <div id="card-2" @click="changeCard(2)">
                    <img src="../../assets/images/homepage/solution-card-1.png" alt="shape" />
                    <h3>{{ $t('home.solutionsByIndustries.logistic.title') }}</h3>
                    <p>{{ $t('home.solutionsByIndustries.logistic.info') }}</p>
                    <div class="home-page-solution-arrow">
                      <h5>{{ $t('home.solutionsByIndustries.readMore') }}</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
                <div v-else-if="arr[1] == 'card3'">
                  <div id="card-3" @click="changeCard(3)">
                    <img src="../../assets/images/homepage/solution-card-1.png" alt="shape" />
                    <h3>{{ $t('home.solutionsByIndustries.retail.title') }}</h3>
                    <p>{{ $t('home.solutionsByIndustries.retail.info') }}</p>
                    <div class="home-page-solution-arrow">
                      <h5>{{ $t('home.solutionsByIndustries.readMore') }}</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center mt-30 mb-30">
              <div class="home-page-solution-card active-solution">
                <div v-if="arr[2] == 'card1'">
                  <div id="card-1" @click="changeCard(1)">
                    <img src="../../assets/images/homepage/solution-card-2.png" alt="shape" />
                    <h3>{{ $t('home.solutionsByIndustries.finance.title') }}</h3>
                    <p>{{ $t('home.solutionsByIndustries.finance.info') }}</p>
                    <div class="home-page-solution-arrow">
                      <h5>{{ $t('home.solutionsByIndustries.readMore') }}</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
                <div v-else-if="arr[2] == 'card2'">
                  <div id="card-2" @click="changeCard(2)">
                    <img src="../../assets/images/homepage/solution-card-1.png" alt="shape" />
                    <h3>{{ $t('home.solutionsByIndustries.logistic.title') }}</h3>
                    <p>{{ $t('home.solutionsByIndustries.logistic.info') }}</p>
                    <div class="home-page-solution-arrow">
                      <h5>{{ $t('home.solutionsByIndustries.readMore') }}</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
                <div v-else-if="arr[2] == 'card3'">
                  <div id="card-3" @click="changeCard(3)">
                    <img src="../../assets/images/homepage/solution-card-1.png" alt="shape" />
                    <h3>{{ $t('home.solutionsByIndustries.retail.title') }}</h3>
                    <p>{{ $t('home.solutionsByIndustries.retail.info') }}</p>
                    <div class="home-page-solution-arrow">
                      <h5>{{ $t('home.solutionsByIndustries.readMore') }}</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-5 d-flex align-items-center">
          <div class="home-page-solution-detail" v-if="this.card1">
            <h5>{{ $t('home.solutionsByIndustries.finance.title2') }}</h5>
            <p>
              {{ $t('home.solutionsByIndustries.finance.moreInfo') }}
            </p>
            <div>
              <router-link to="/finance" class="btn main-btn-academy" style="
                  background-image: linear-gradient(to right, #6852ed, #ba43fc);
                ">
                {{ $t('home.solutionsByIndustries.moreInfo') }}
              </router-link>
            </div>
          </div>
          <div class="home-page-solution-detail" v-else-if="this.card2">
            <h5>{{ $t('home.solutionsByIndustries.logistic.title2') }}</h5>
            <p>{{ $t('home.solutionsByIndustries.logistic.moreInfo') }}</p>
            <div>
              <router-link to="/logistic" class="btn main-btn-academy" style="
                  background-image: linear-gradient(to right, #6852ed, #ba43fc);
                ">
                {{ $t('home.solutionsByIndustries.moreInfo') }}
              </router-link>
            </div>
          </div>
          <div class="home-page-solution-detail" v-else-if="this.card3">
            <h5>{{ $t('home.solutionsByIndustries.retail.title2') }}</h5>
            <p>{{ $t('home.solutionsByIndustries.retail.moreInfo') }}</p>
            <div>
              <router-link to="/retail" class="btn main-btn-academy" style="
                  background-image: linear-gradient(to right, #6852ed, #ba43fc);
                ">
                {{ $t('home.solutionsByIndustries.moreInfo') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SolutionsByIndustries",
  data() {
    return {
      card1: false,
      card2: false,
      card3: true,
      arr: ["card1", "card2", "card3"]
    };
  },
  methods: {
    changeCard(id) {
      if (id === 1) {
        this.card1 = true;
        this.card2 = false;
        this.card3 = false;
        this.arr = ["card2", "card3", "card1"]
      } else if (id === 2) {
        this.card1 = false;
        this.card2 = true;
        this.card3 = false;
        this.arr = ["card3", "card1", "card2"]
      } else {
        this.card1 = false;
        this.card2 = false;
        this.card3 = true;
        this.arr = ["card1", "card2", "card3"]
      }
    },
  },
});
</script>
