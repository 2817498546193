<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl">
        <h2>
          {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.title" )}}
        </h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature1-1" )}}
              </h5>
              <p>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature1-2" )}}
              </p>
            </div>
            <img src="../../assets/images/1.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature2-1" )}}
              </h5>
              <p>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature2-2" )}}
              </p>
            </div>
            <img src="../../assets/images/2.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature3-1" )}}
              </h5>
              <p>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature3-2" )}}
              </p>
            </div>
            <img src="../../assets/images/3.png" alt="shape" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature4-1" )}}
              </h5>
              <p>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature4-2" )}}
              </p>
            </div>
            <img src="../../assets/images/4.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature5-1" )}}
              </h5>
              <p>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature5-2" )}}
              </p>
            </div>
            <img src="../../assets/images/5.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature6-1" )}}
              </h5>
              <p>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature6-2" )}}
              </p>
            </div>
            <img src="../../assets/images/6.png" alt="shape" />
          </div>
        </div>
      </div>

      <div class="row pb-30">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature7-1" )}}
              </h5>
              <p>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature7-2" )}}
              </p>
            </div>
            <img src="../../assets/images/7.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature8-1" )}}
              </h5>
              <p>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature8-2" )}}
              </p>
            </div>
            <img src="../../assets/images/8.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature9-1" )}}
              </h5>
              <p>
                {{ $t( "products.PIXAIASol.pixCountGoodsByAICameras.onRamps.cardGoodsCam3.feature9-2" )}}
              </p>
            </div>
            <img src="../../assets/images/9.png" alt="shape" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardGoodsCamerasRampsThird",
};
</script>
