<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleAiDeveloper />
    <AiDeveloperCard />
    <!-- <AiDeveloperProgram /> -->
    <AiDeveloperAwaitYou />
    <!-- <AiDeveloperAcademyTeam /> -->
 <AiDeveloperContributions /> 
    <ClientsAcademy />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar";
import PageTitleAiDeveloper from "../../AiDeveloper/PageTitleAiDeveloper.vue";
import AiDeveloperCard from "../../AiDeveloper/AiDeveloperCard.vue";
// import AiDeveloperProgram from "../../AiDeveloper/AiDeveloperProgram.vue";
import AiDeveloperAwaitYou from "../../AiDeveloper/AiDeveloperAwaitYou.vue";
// import AiDeveloperAcademyTeam from "../../AiDeveloper/AiDeveloperAcademyTeam.vue";
 import AiDeveloperContributions from "../../AiDeveloper/AiDeveloperContributions.vue";
import ClientsAcademy from "../../OurClients/ClientsAcademy.vue";
import Footer from "../../Layout/Footer";

export default {
  name: "AiDeveloper",
  components: {
    Navbar,
    PageTitleAiDeveloper,
    AiDeveloperCard,
    // AiDeveloperProgram,
    AiDeveloperAwaitYou,
    // AiDeveloperAcademyTeam,
     AiDeveloperContributions,
    ClientsAcademy,
    Footer,
  },
};
</script>
