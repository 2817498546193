<template>
  <footer class="bg-off-white footer" style="background-color: #0d0594">
    <div class="footer-upper pt-100 pb-80 position-relative">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="footer-content-item">
              <div class="footer-logo">
                <router-link to="/">
                  <img src="../../assets/images/pixselect-white.png" alt="logo" />
                </router-link>
              </div>
              <br />
              <div class="footer-details">
                <p>
                  {{ $t('footer.pixInfo') }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="footer-content-list footer-content-item desk-pad-left-70">
              <div class="footer-content-title">
                <h3>{{ $t('footer.products.title') }}</h3>
              </div>
              <ul class="footer-details footer-list">
                <li>
                  <router-link to="/bank-cctv">{{ $t('footer.products.product1') }}</router-link>
                </li>
                <li>
                  <router-link to="/pixcount-people">{{ $t('footer.products.product2') }}</router-link>
                </li>
                <li>
                  <router-link to="/goods-cameras-warehouses">{{ $t('footer.products.product3') }}</router-link>
                </li>
                <li>
                  <router-link to="/shelves-cameras-warehouses">{{ $t('footer.products.product4') }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-3 col-md-3 col-lg-2">
            <div class="footer-content-list footer-content-item desk-pad-left-70">
              <div class="footer-content-title">
                <h3>{{ $t('footer.company.title') }}</h3>
              </div>
              <ul class="footer-details footer-list">
                <li><router-link to="/about-us">{{ $t('footer.company.about') }}</router-link></li>
                <li>
                  <router-link to="/careers">{{ $t('footer.company.careers') }}</router-link>
                </li>
                <li>
                  <router-link to="/partners">{{ $t('footer.company.partner') }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-3 col-md-3 col-lg-2">
            <div class="footer-content-list footer-content-item desk-pad-left-70">
              <div class="footer-content-title">
                <h3>{{ $t('footer.support.title') }}</h3>
              </div>
              <ul class="footer-details footer-list">
                <li>
                  <router-link to="/contact">{{ $t('footer.support.contact') }}</router-link>
                </li>
                <li><router-link to="/projects">{{ $t('footer.support.faq') }}</router-link></li>
              </ul>
            </div>
          </div>
          <div class="col-sm-3 col-md-3 col-lg-2">
            <div class="footer-content-list footer-content-item desk-pad-left-70">
              <div class="footer-content-title">
                <h3>{{ $t('footer.contact.title') }}</h3>
              </div>
              <div class="footer-details footer-address">
                <div class="footer-address-item">
                  <div class="footer-address-text">
                    <h4>{{ $t('footer.contact.phone') }}</h4>
                    <p><a href="tel:(+90)-850-225-04-49">(+90) 850 225 04 49</a></p>
                  </div>
                </div>
                <div class="footer-address-item">
                  <div class="footer-address-text">
                    <h4>{{ $t('footer.contact.email') }}</h4>
                    <p><a href="mailto:info@pixselect.io">info@pixselect.io</a></p>
                  </div>
                </div>
                <div class="footer-address-item">
                  <div class="footer-address-text">
                    <h4>{{ $t('footer.contact.address-title') }}</h4>
                    <p>
                      {{ $t('footer.contact.address') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-lower bg-blue position-relative">
      <div class="container">
        <div class="footer-copyright-text footer-copyright-text-white">
          <p> {{ $t('footer.copyright') }}</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
