<template>
  <div class="process-section pt-90 pb-70">
    <div class="container">
      <div class="section-title section-title-lg">
        <h2>{{ $t("pixAcad.pixCoding.frontBackTraining.processAcademy.title") }}</h2>
      </div>
      <div class="process-content">
        <div class="row justify-content-center">
          <div class="col-md-6 col-lg-4 pb-30">
            <div class="process-item process-item-center border">
              <div class="process-thumb">
                <img src="../../assets/images/icon-1.png" alt="shape" />
              </div>
              <div class="process-text">
                <h3>{{ $t("pixAcad.pixCoding.frontBackTraining.processAcademy.title1") }}</h3>
                <p>
                  {{ $t("pixAcad.pixCoding.frontBackTraining.processAcademy.info1") }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 pb-30">
            <div class="process-item process-item-center border">
              <div class="process-thumb">
                <img src="../../assets/images/icon-2.png" alt="shape" />
              </div>
              <div class="process-text">
                <h3>{{ $t("pixAcad.pixCoding.frontBackTraining.processAcademy.title2") }}</h3>
                <p>
                  {{ $t("pixAcad.pixCoding.frontBackTraining.processAcademy.info2") }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 pb-30">
            <div class="process-item process-item-center border">
              <div class="process-thumb">
                <img src="../../assets/images/icon-3.png" alt="shape" />
              </div>
              <div class="process-text">
                <h3>{{ $t("pixAcad.pixCoding.frontBackTraining.processAcademy.title3") }}</h3>
                <p>
                  {{ $t("pixAcad.pixCoding.frontBackTraining.processAcademy.info3") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Process",
};
</script>
