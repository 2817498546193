import { createStore } from "vuex";
import {main} from "./main.module";

export default createStore({
    state: {
        language: "tr"
    },
    mutations: {},
    getters: {},
    computed: {},
    actions: {},
    modules: {
        main
    },
    
});
