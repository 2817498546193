<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
        <h2>
          {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles3.title") }}
        </h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="turnstiles-card-features-general">
            <div class="turnstiles-card-features">
              <h5>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles3.feature1-1") }}
              </h5>
              <p>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles3.feature1-2") }}
              </p>
            </div>
            <img
              src="../../assets/images/1.png"
              alt="shape"
              class="turnstiles-card-features-img"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="turnstiles-card-features-general">
            <div class="turnstiles-card-features">
              <h5>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles3.feature2-1") }}
              </h5>
              <p>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles3.feature2-2") }}
              </p>
            </div>
            <img
              src="../../assets/images/2.png"
              alt="shape"
              class="turnstiles-card-features-img"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="turnstiles-card-features-general">
            <div class="turnstiles-card-features">
              <h5>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles3.feature3-1") }}
              </h5>
              <p>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles3.feature3-2") }}
              </p>
            </div>
            <img
              src="../../assets/images/3.png"
              alt="shape"
              class="turnstiles-card-features-img"
            />
          </div>
        </div>
      </div>

      <div class="row pb-60">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="turnstiles-card-features-general">
            <div class="turnstiles-card-features">
              <h5>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles3.feature4-1") }}
              </h5>
              <p>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles3.feature4-2") }}
              </p>
            </div>
            <img
              src="../../assets/images/4.png"
              alt="shape"
              class="turnstiles-card-features-img"
            />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="turnstiles-card-features-general">
            <div class="turnstiles-card-features">
              <h5>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles3.feature5-1") }}
              </h5>
              <p>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles3.feature5-2") }}
              </p>
            </div>
            <img
              src="../../assets/images/5.png"
              alt="shape"
              class="turnstiles-card-features-img"
            />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="turnstiles-card-features-general">
            <div class="turnstiles-card-features">
              <h5>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles3.feature6-1") }}
              </h5>
              <p>
                {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.cardTurnstiles3.feature6-2") }}
              </p>
            </div>
            <img
              src="../../assets/images/6.png"
              alt="shape"
              class="turnstiles-card-features-img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardTurnstilesFaceRecognitionThird",
};
</script>
