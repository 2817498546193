<template>
    <div class="container pt-60">
      <div class="demo-request-header">
        <h1>{{ $t("partners.pageTitle.partnerRegistrationForm") }}</h1>
        <p>{{ $t("partners.pageTitle.title2") }}</p>
      </div>
  
      <div @submit.prevent="submit">
        <div class="demo-request-form-card">
            
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group mb-20">
                <label>{{ $t("demo.form.nameSurname") }}</label>
                <input type="text" id="name" name="name" class="form-control" placeholder="Enter your name" :class="{ 'is-invalid': v$.form.name.$error }"
                  v-model="form.name" />
                <div class="input-errors" v-for="(error, index) of v$.form.name.$errors" :key="index">
                  <small class="text-danger">{{
                    error.$message == "Value is required" ? "Name and Surname is required" :
                      error.$message
                  }} </small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group mb-20">
                <label>{{ $t("demo.form.phone") }}</label>
                <vue-tel-input v-model="form.phone" mode="international" :preferredCountries="['us', 'gb', 'tr']"
                  defaultCountry="tr"  name="phone" :class="{ 'red-border': v$.form.phone.$error }" id="phone" required="true" validCharactersOnly="true"></vue-tel-input>
                  <div class="input-errors" v-for="(error, index) of v$.form.phone.$errors" :key="index">
                  <small class="text-danger">{{
                    error.$message == "Value is required" ? "Phone number is required" :
                      error.$message
                  }} </small>
                </div>
              </div>
            </div>
          
            
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group mb-20">
                <label>{{ $t("demo.form.enterCompany") }}</label>
                <input v-model="form.company" type="text" id="company" name="company-name" class="form-control" :class="{ 'is-invalid': v$.form.company.$error }"
                  placeholder="Enter company name" />
                <div class="input-errors" v-for="(error, index) of v$.form.company.$errors" :key="index">
                  <small class="text-danger">{{
                    error.$message == "Value is required" ? "Company name is required" :
                      error.$message
                  }} </small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group mb-20">
                <label>{{ $t("demo.form.positionintheCompany") }}</label>
                <input type="text" id="position" name="position" class="form-control" placeholder="Position in the Company" :class="{ 'is-invalid': v$.form.name.$error }"
                  v-model="form.position" />
                <div class="input-errors" v-for="(error, index) of v$.form.name.$errors" :key="index">
                  <small class="text-danger">{{
                    error.$message == "Value is required" ? "Position is required" :
                      error.$message
                  }} </small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="form-group mb-20">
                <label>{{ $t("demo.form.email") }}</label>
                <input v-model="form.eMail" type="email" id="email" name="email" class="form-control" :class="{ 'is-invalid': v$.form.eMail.$error }"
                  placeholder="example@mail.com" />
                <div class="input-errors" v-for="(error, index) of v$.form.eMail.$errors" :key="index">
                  <small class="text-danger">{{
                    error.$message == "Value is required" ? "Email is required" :
                      error.$message
                  }} </small>
                </div>
              </div>
            </div>

          </div>
  

  
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
              <div class="form-group mb-20">
                <label>{{ $t("demo.form.optionOperating") }}</label>
                <div class="form-group-btn" >
                  <div id="btn-retail" class="btn-multiselect mr-10"  @click="addSector(1)">
                    <h5>{{ $t("solutionByIndustries.finance.title") }}</h5>
                  </div>
                  <div id="btn-logistics" class="btn-multiselect mr-10" @click="addSector(2)">
                    <h5>{{ $t("solutionByIndustries.logistic.title") }}</h5>
                  </div>
                  <div id="btn-finance" class="btn-multiselect" @click="addSector(3)">
                    <h5>{{ $t("solutionByIndustries.retail.title") }}</h5>
                  </div>
                </div>
                <div class="input-errors" v-for="(error, index) of v$.form.sector.$errors" :key="index">
                  <small class="text-danger">{{
                    error.$message == "Value is required" ? "Sector  is required" :
                      error.$message
                  }} </small>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
              <div class="form-group mb-20">
                <label>{{ $t("partners.pageTitle.title3") }}</label>
                <select v-model="form.whatNeed" name="whatNeed" :class="{ 'is-invalid': v$.form.whatNeed.$error }" class="form-control">
                  <option style="font-size: 20px" :value="''">
                    {{ $t("demo.form.selectItem") }}
                  </option>
                  <option v-for="(item, index) in needOptions" :key="index" :value="item.text" style="font-size: 20px">
                    {{ item.text }}
                  </option>
                </select>
                <div class="input-errors" v-for="(error, index) of v$.form.whatNeed.$errors" :key="index">
                  <small class="text-danger">{{
                    error.$message == "Value is required" ?
                      "Select an item" : error.$message
                  }}</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
              <div class="form-group mb-20">
                <div class="form-check">
                  <input v-model="form.isPrivacyPolicy" :class="{ 'is-invalid': v$.form.isPrivacyPolicy.$error }" class="form-check-input" type="checkbox" id="check" />
                  <label class="form-check-label">
                    {{ $t("demo.form.click") }}
                    <span data-bs-toggle="modal" data-bs-target="#termsOfService" style="color: #1f12fd;">
                      {{ $t("demo.form.terms") }}  
                    </span>
                    <!-- Terms of Service -->
                    <div class="modal fade" id="termsOfService" tabindex="-1" aria-labelledby="exampleModalLabel"
                      aria-hidden="true">
                      <TermsOfService />
                    </div>
                    {{ $t("demo.form.and") }}
                    <span data-bs-toggle="modal" data-bs-target="#privacyPolicy" style="color: #1f12fd;">
                      {{ $t("demo.form.privacy") }}  
                    </span>
                    <!-- Privacy Policy -->
                    <div class="modal fade" id="privacyPolicy" tabindex="-1" aria-labelledby="exampleModalLabel"
                      aria-hidden="true">
                      <PrivacyPolicy />
                    </div>
                  </label>
                </div>
                <div class="input-errors" v-for="(error, index) of v$.form.isPrivacyPolicy.$errors" :key="index">
                  <small class="text-danger">{{
                    error.$message == "The value must be equal to the other value" ?
                      "Please read and click !" : error.$message
                  }}</small>
                </div>
              </div>
            </div>
          </div>
  
          <div class="row">
            <div class="col-12 col-md-12 col-lg-12">
              <div class="form-group mb-20">
                <button class="btn demo-request-form-btn" type="submit" @click="submit">
                  <h5 class="m-0">{{ $t("demo.form.submite") }} </h5>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import { VueTelInput } from "vue-tel-input";
  import "vue-tel-input/dist/vue-tel-input.css";
  import { mapActions } from "vuex";
  import { createToast } from "mosha-vue-toastify";
  import useVuelidate from "@vuelidate/core";
  import { required, email, sameAs } from "@vuelidate/validators";
  import i18n from "../../plugins/i18n"
  
  import PrivacyPolicy from "../../components/Privacy/PrivacyPolicy.vue"
  import TermsOfService from "../../components/Privacy/TermsOfService.vue"
  
  export default defineComponent({
    name: "DemoRequesForm",
    setup() {
      return { v$: useVuelidate() };
    },
    components: {
      VueTelInput,
      PrivacyPolicy,
      TermsOfService
  
    },
    data() {
      return {
        needOptions: [
          { value: 1, text: i18n.global.t("footer.products.product1") },
          { value: 2, text: i18n.global.t("footer.products.product5") },
          { value: 3, text: i18n.global.t("footer.products.product2") },
          { value: 4, text: i18n.global.t("footer.products.product6") },
        ],
        form: {
            name: "",
                phone: "",
                company: "",
                position : "",
                eMail: "",
                sector: [],
                whatNeed: "",
                isPrivacyPolicy: false,
        },
      };
    },
    validations() {
      return {
        form: {
          name: { required },
          phone: { required },
          company: { required },
          position:  { required },
          eMail: { required, email },
          sector: { required },
          whatNeed: { required },
          isPrivacyPolicy: { sameAs: sameAs(true) },
        },
      }
    },
    methods: {
      ...mapActions("main", ["postFormPartnerRequest"]),
      submit() {
        this.v$.$touch()
        if (!this.v$.form.$invalid) {
          this.postFormPartnerRequest(this.form)
            .then((res) => {
              createToast(res.message, {
                toastBackgroundColor: "#28a745",
                type: 'success',
              });
            })
            .then(() => {
              this.addSector(4);
              this.form = {
                name: "",
                phone: "",
                company: "",
                position : "",
                eMail: "",
                sector: [],
                whatNeed: "",
                isPrivacyPolicy: false,
              };
              this.v$.$reset()
            })
            .catch((res) => {
              createToast(res.response.data.message, {
                toastBackgroundColor: "#dc3545",
                type: "danger",
              });
            });
        }
  
      },
      addSector(id) {
        if (id === 1) {
          let el = document.getElementById("btn-retail");
          el.classList.toggle("btn-multiselect-active");
          let isCheck = false;
          for (let i = 0; i < this.form.sector.length; i++) {
            if (this.form.sector[i] == "retail") {
              isCheck = true;
            }
          }
          if (isCheck) {
            this.form.sector = this.form.sector.filter(
              (value) => value !== "retail"
            );
          } else {
            this.form.sector.push("retail");
          }
        } else if (id === 2) {
          let el = document.getElementById("btn-logistics");
          el.classList.toggle("btn-multiselect-active");
          let isCheck = false;
          for (let i = 0; i < this.form.sector.length; i++) {
            if (this.form.sector[i] == "logistics") {
              isCheck = true;
            }
          }
          if (isCheck) {
            this.form.sector = this.form.sector.filter(
              (value) => value !== "logistics"
            );
          } else {
            this.form.sector.push("logistics");
          }
        } else if (id === 3) {
          let el = document.getElementById("btn-finance");
          el.classList.toggle("btn-multiselect-active");
          let isCheck = false;
          for (let i = 0; i < this.form.sector.length; i++) {
            if (this.form.sector[i] == "finance") {
              isCheck = true;
            }
          }
          if (isCheck) {
            this.form.sector = this.form.sector.filter(
              (value) => value !== "finance"
            );
          } else {
            this.form.sector.push("finance");
          }
        } else {
          let el = document.getElementById("btn-retail");
          el.classList.remove("btn-multiselect-active");
          let el1 = document.getElementById("btn-logistics");
          el1.classList.remove("btn-multiselect-active");
          let el2 = document.getElementById("btn-finance");
          el2.classList.remove("btn-multiselect-active");
        }
      },
    },
  });
  </script>
  