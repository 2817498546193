<template>
  <BlogSaasEmployers class="pt-100" />
  <div class="container pt-60 pb-60">
    <div class="row pt-60">
      <div class="col-12 col-md-12 col-lg-6 pt-30">
        <div class="section-title section-title-xl">
          <h2>{{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content2.title1") }}</h2>
        </div>
        <div class="contact-info-card">
          <p>
            {{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content2.info1-1") }}
          </p>
          <p>
            {{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content2.info1-2") }}
          </p>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 pt-30">
        <div class="section-title section-title-sm">
          <h2>{{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content2.title2") }}</h2>
        </div>
        <div class="contact-info-card">
          <p>
            {{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content2.info2-1") }}
          </p>
          <p>
            {{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content2.info2-2") }}
          </p>
          <p>
            {{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content2.info2-3") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
// import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { mapActions } from "vuex";
import { createToast } from "mosha-vue-toastify";
import BlogSaasEmployers from "../SingleBlog/BlogSaasEmployers.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";

// import PrivacyPolicy from "../../components/Privacy/PrivacyPolicy.vue"
// import TermsOfService from "../../components/Privacy/TermsOfService.vue"

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  name: "SaasEmployersContact",
  components: {
    BlogSaasEmployers,
    // VueTelInput,
    // PrivacyPolicy,
    // TermsOfService
  },
  data() {
    return {
      form: {
        name_surname: "",
        phone: "",
        email: "",
        company: "",
        comments: "",
        isPrivacyPolicy: false,
      },
    };
  },
  validations() {
    return {
      form: {
        name_surname: { required },
        phone: { required },
        email: { required, email },
        company: { required },
        comments: { required },
        isPrivacyPolicy: { sameAs: sameAs(true) },
      },
    }
  },
  methods: {
    ...mapActions("main", ["postContactAcademyForm"]),
    submit() {
      this.v$.$touch()
      if (this.form.isPrivacyPolicy == true) {
        this.postContactAcademyForm(this.form)
          .then((res) => {
            createToast(res.message, {
              toastBackgroundColor: "#28a745",
              type: "success",
            });
          })
          .then(() => {
            this.form = {
              name_surname: "",
              phone: "",
              email: "",
              company: "",
              comments: "",
              isPrivacyPolicy: false,
            };
            this.v$.$reset()
          })
          .catch((res) => {
            createToast(res.response.data.message, {
              toastBackgroundColor: "#dc3545",
              type: "danger",
            });
          });
      }
    },
  },
});
</script>
