<template>
    <div
      style="background-image: linear-gradient(to right, #852dee, #5044ff)"
      class="inner-page-header-academy"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm">
            <div class="inner-header-content-demo-request">
              <h2>{{ $t("pixAcad.pixAI.SAAS-CV-Member.talents.content4.saasCVMembership") }}</h2>
  
              <br />
              <div class="pl-4 mb-10">
                <router-link to="/contact" class="btn main-btn-academy">
                  {{ $t('demo.apply') }}
                </router-link>
              </div>
            </div>
          </div>
  
          <div class="col-sm d-flex justify-content-end">
            <img
              class="demo-request-img"
              src="../../assets/images/democountpeople.png"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style></style>
  