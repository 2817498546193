<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl mb-0">
        <h2>
          {{ $t( "products.PIXAIASol.pixCountPeople.cardCount2.title" )}}
        </h2>
        <div class="goods-ai-warehouses-shema-text">
          <p>
            {{ $t( "products.PIXAIASol.pixCountPeople.cardCount2.info" )}}
          </p>
        </div>
      </div>
      <div class="row pb-60">
        <div id="count-people-1" class="col-8 col-md-6 col-lg-3">
          <div class="count-people-schema-card-top">
            <img
              src="../../assets/images/goods-cameras-warehouses/left-top.png"
              alt="shape"
              class="icon-1"
            />
            <h6>
              {{ $t( "products.PIXAIASol.pixCountPeople.cardCount2.info1" )}}
            </h6>
            <img
              src="../../assets/images/count-people/card-1.png"
              alt="shape"
              class="card-photo"
            />
          </div>
        </div>
        <div id="count-people-2" class="col-8 col-md-6 col-lg-3">
          <div class="count-people-schema-card-bottom">
            <div class="count-people-schema-card-info">
              <img
                src="../../assets/images/goods-cameras-warehouses/left-bottom.png"
                alt="shape"
                class="icon-1"
              />
              <h6>{{ $t( "products.PIXAIASol.pixCountPeople.cardCount2.info2" )}}</h6>
            </div>
            <img
              src="../../assets/images/count-people/card-2.png"
              alt="shape"
            />
          </div>
        </div>
        <div id="count-people-3" class="col-8 col-md-6 col-lg-3">
          <div class="count-people-schema-card-top">
            <img
              src="../../assets/images/goods-cameras-warehouses/right-top.png"
              alt="shape"
              class="icon-2"
            />
            <h6>
              {{ $t( "products.PIXAIASol.pixCountPeople.cardCount2.info3" )}}
            </h6>
            <img
              src="../../assets/images/count-people/card-3.png"
              alt="shape"
              class="card-photo"
            />
          </div>
        </div>
        <div id="count-people-4" class="col-8 col-md-6 col-lg-3">
          <div class="count-people-schema-card-bottom">
            <img
              src="../../assets/images/goods-cameras-warehouses/right-bottom.png"
              alt="shape"
              class="icon-2"
            />
            <h6>
              {{ $t( "products.PIXAIASol.pixCountPeople.cardCount2.info4" )}}
            </h6>
            <img
              src="../../assets/images/count-people/card-4.png"
              alt="shape"
              class="card-photo-4"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardCountPeopleSecond",
};
</script>
<style scoped></style>
