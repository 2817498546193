<template>
  <div>
    <div class="section-title section-title-xl">
      <h2>{{ title }}</h2>
    </div>
    <div class="d-flex">
      <button
        id="single"
        class="btn-architecture-passive"
        @click="changeButton(1)"
      >
        <p>{{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.monSysArc.option1')}}</p>
      </button>
      <button
        id="distributed"
        class="btn-architecture-active btn-architecture-position"
        @click="changeButton(2)"
      >
        <p>{{ $t('products.pixSVMS-CCTVMonitoringSolutions.atmAndBankBranchCCTVMonitoring.monSysArc.option2')}}</p>
      </button>
    </div>
    <div class="goods-ai-warehouses-architecture">
      <img :src="imageUrl" alt="shape" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SystemArchitecture",
  props: ["title", "imageUrl"],
  data() {
    return {
    };
  },
  methods: {
    changeButton(id) {
      if (id === 1) {
        let el = document.getElementById("distributed");
        el.classList.add("btn-architecture-passive");
        el.classList.remove("btn-architecture-active");
        let el2 = document.getElementById("single");
        el2.classList.remove("btn-architecture-passive");
        el2.classList.add("btn-architecture-active");
      } else if (id === 2) {
        let el = document.getElementById("single");
        el.classList.remove("btn-architecture-active");
        el.classList.add("btn-architecture-passive");
        let el2 = document.getElementById("distributed");
        el2.classList.add("btn-architecture-active");
        el2.classList.remove("btn-architecture-passive");
      }
    },
  },
});
</script>
