<template>
  <div class="container">
    <div class="section-image">
      <img
        style="max-width: 60%"
        src="../../assets/images/pixselect-academy-logo.svg"
        alt="shape"
      />
    </div>
    <div class="row pb-60">
      <div class="col-md-12 col-lg-12">
        <div class="it-support-card">
          <h3>
            {{ $t("pixAcad.pixAI.AI-Developer.aiDevCard") }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AiDeveloperCard",
};
</script>
