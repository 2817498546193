<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleGoodsAIWarehouses />
    <CardGoodsCamerasWarehouses />
    <CardGoodsCamerasWarehousesSecond />
    <CareerGoodsCamerasWarehouses />
    <CardGoodsCamerasWarehousesThird />
    <ClientsGoodsCamerasWarehouses />
    <BlogGoodsCamerasWarehouses />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../Layout/Navbar.vue";
import PageTitleGoodsAIWarehouses from "../../../Common/PageTitleGoodsAIWarehouses";
import CardGoodsCamerasWarehouses from "../../../Cart/CardGoodsCamerasWarehouses.vue";
import CardGoodsCamerasWarehousesSecond from "../../../Cart/CardGoodsCamerasWarehousesSecond.vue";
import CareerGoodsCamerasWarehouses from "../../../Career/CareerGoodsCamerasWarehouses.vue";
import CardGoodsCamerasWarehousesThird from "../../../Cart/CardGoodsCamerasWarehousesThird.vue";
import ClientsGoodsCamerasWarehouses from "../../../OurClients/ClientsGoodsCamerasWarehouses.vue";
import BlogGoodsCamerasWarehouses from "../../../Blog/BlogGoodsCamerasWarehouses.vue";
import Footer from "../../../Layout/Footer";

export default {
  name: "GoodsByAICamerasInWarehouses",
  components: {
    Navbar,
    PageTitleGoodsAIWarehouses,
    CardGoodsCamerasWarehouses,
    CardGoodsCamerasWarehousesSecond,
    CardGoodsCamerasWarehousesThird,
    CareerGoodsCamerasWarehouses,
    ClientsGoodsCamerasWarehouses,
    BlogGoodsCamerasWarehouses,
    Footer,
  },
};
</script>
