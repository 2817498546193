<template>
  <div class="container pt-60">
    <div class="section-title section-title-lg">
      <h2> {{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.clientsGoodsBarcode.title") }}</h2>
    </div>
    <div class="row goods-ai-warehouses-info-text pb-60">
      <div class="col-12 col-md-6 col-lg-3 pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-1.png"
            alt="partner"
          />
          <h6> {{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.clientsGoodsBarcode.factoryWarehouses") }}</h6>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3 pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-2.png"
            alt="partner"
          />
          <h6> {{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.clientsGoodsBarcode.logisticWarehouses") }}</h6>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3 pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-3.png"
            alt="partner"
          />
          <h6> {{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.clientsGoodsBarcode.retailWarehouses") }}</h6>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3 pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/goods-barcode-warehouses/photo-4.png"
            alt="partner"
          />
          <h6> {{ $t("products.PIXAIASol.pixCountGoodsByBarcodeAndQR.onRamps.clientsGoodsBarcode.shoppingMalls") }}</h6>
        </div>
      </div>
    </div>
    <BlogCountPeople />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BlogCountPeople from "../SingleBlog/BlogCountPeople.vue";

export default defineComponent({
  name: "ClientsGoodsBarcodeRamps",
  components: {
    BlogCountPeople,
  },
});
</script>
