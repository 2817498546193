<template>
  <div class="row pt-40 justify-content-center">
    <div class="col-md-5 col-lg-5 pt-30">
      <div class="section-title section-title-xl">
        <h4>{{ $t("aiVisionTrainer.developTrainandDeploy") }}</h4>
      </div>
      <div class="contact-info-card">
        <p>
          {{ $t("aiVisionTrainer.areYouLookingTobuild") }}
        </p>
      </div>
    </div>
    <div class="col-md-5 col-lg-5 pt-30">
      <div class="section-title section-title-xl">
        <h4>{{ $t("aiVisionTrainer.unleashThePower") }}</h4>
      </div>
      <div class="contact-info-card">
        <p>
          {{ $t("aiVisionTrainer.withAIVISIONTRAINERYouCanGive") }}
        </p>
      </div>
    </div>
  </div>
  <br />

  <div class="row pt-40 justify-content-center">
    <div class="col-md-5 col-lg-5 pt-30">
      <div class="section-title section-title-xl">
        <h4> {{ $t("aiVisionTrainer.successNowIn") }}</h4>
      </div>
      <div class="contact-info-card">
        <p>
          {{ $t("aiVisionTrainer.aIVISIONTRAINEREmpowersYou") }}
        </p>
      </div>
    </div>
    <div class="col-md-5 col-lg-5 pt-30">
      <div class="section-title section-title-xl">
        <h4> {{ $t("aiVisionTrainer.customModelsHassleFree") }}</h4>
      </div>
      <div class="contact-info-card">
        <p>
          {{ $t("aiVisionTrainer.TrainingAccurateModels") }}
        </p>
      </div>
    </div>
  </div>
  <br />

  <div class="row pt-40 justify-content-center">
    <div class="col-md-5 col-lg-5 pt-30">
      <div class="section-title section-title-xl">
        <h4> {{ $t("aiVisionTrainer.deployAnywhere") }}</h4>
      </div>
      <div class="contact-info-card">
        <p>
          {{ $t("aiVisionTrainer.ourManagedInfrastructure") }}
        </p>
      </div>
    </div>
    <div class="col-md-5 col-lg-5 pt-30">
      <div class="section-title section-title-xl">
        <h4> {{ $t("aiVisionTrainer.streamlineCollaboration") }}</h4>
      </div>
      <div class="contact-info-card">
        <p>
          {{ $t("aiVisionTrainer.collaborationMadesimpl") }}
        </p>
      </div>
    </div>
  </div>
  <br />

  <div class="row pt-40 justify-content-center">
    <div class="col-md-5 col-lg-5 pt-30">
      <div class="section-title section-title-xl">
        <h4>   {{ $t("aiVisionTrainer.seamlessIntegration") }}</h4>
      </div>
      <div class="contact-info-card">
        <p>
          {{ $t("aiVisionTrainer.integrateAIVISIONTRAINERint") }}
        </p>
        <p>
          {{ $t("aiVisionTrainer.readytotakeyourcomputer") }}
        </p>
        <!-- <p>
          {{ $t("aiVisionTrainer.aIVISIONTRAINERisaneasy") }}
        </p> -->
      </div>
    </div>
  </div>

  <br />
</template>
<style scoped>
.contact-info-card {
  border: 1px solid rgb(211, 211, 211);
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  min-height: 235px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>