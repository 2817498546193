import MainService from "../services/Main.service";

const initialState = {
  formDemoRequest: [],
  formPartnerRequest: [],
  changeTab: 0,
  formAcademyInfo: [],
  formAcademy: {
    email: "",
    password: "",
    confirmPassword: "",
    isPrivacyPolicy: false,
    name: "",
    phone: "",
    birthday: "",
    gender: "",
    city: "",
    educationStatus: "",
    university: "",
    departmant: "",
    gradutationDate: "",
    englishStatus: "",
    //jobCity: "",
    jobStatus: "",
    experience: "",
    jobDepartmant: "",
    github: "",
    linkedin: "",
    contactPlace: "",
    experience1: ["", "", "", ""],
    experience2: ["", "", "", ""],
    experience3: ["", "", "", ""]
  },
  formContact: [],
  formContactAcademy: []
};

export const main = {
  namespaced: true,
  state: initialState,
  actions: {
    postFormDemoRequest({ commit }, payload) {
      return MainService.postFormDemoRequest(payload).then((res) => {
        if (res.data) commit("setFormDemoRequest", res.data);
        return Promise.resolve(res.data);
      });
    },
    postFormAcademy({ commit }, payload) {
      return MainService.postFormAcademy(payload).then((res) => {
        if (res.data) commit("setFormAcademyInfo", res.data);
        return Promise.resolve(res.data);
      });
    },
    changeTabForm({ commit }, payload) {
      commit("setChangeTab", payload);
    },
    postContactForm({ commit }, payload) {
      return MainService.postContact(payload).then((res) => {
        if (res.data) commit("setContactForm", res.data);
        return Promise.resolve(res.data);
      });
    },
    postContactAcademyForm({ commit }, payload) {
      return MainService.postContactAcademy(payload).then((res) => {
        if (res.data) commit("setContactAcademyForm", res.data);
        return Promise.resolve(res.data);
      });
    },
    postFormPartnerRequest({ commit }, payload) {
      return MainService.postFormPartnerRequest(payload).then((res) => {
        if (res.data) commit("setFormPartnerRequest", res.data);
        return Promise.resolve(res.data);
      });
    },
  },
  mutations: {
    setFormDemoRequest(state, payload) {
      state.formDemoRequest = Object.assign([], payload);
    },
    setFormPartnerRequest(state, payload) {
      state.formPartnerRequest = Object.assign([], payload);
    },
    setFormAcademyInfo(state, payload) {
      state.formAcademyInfo = Object.assign([], payload);
    },
    setChangeTab(state, payload) {
      state.changeTab = payload;
    },
    setFormAcademy(state, payload) {
      state.formAcademy = Object.assign(
        {},
        {
          email: payload.email,
          password: payload.password,
          confirmPassword: payload.confirmPassword,
          isPrivacyPolicy: payload.isPrivacyPolicy,
          name: payload.name,
          phone: payload.phone,
          birthday: payload.birthday,
          gender: payload.gender,
          city: payload.city,
          educationStatus: payload.educationStatus,
          university: payload.university,
          departmant: payload.departmant,
          gradutationDate: payload.gradutationDate,
          englishStatus: payload.englishStatus,
          //jobCity: payload.jobCity,
          jobStatus: payload.jobStatus,
          experience: payload.experience,
          jobDepartmant: payload.jobDepartmant,
          linkedin: payload.linkedin,
          github: payload.github,
          contactPlace: payload.contactPlace,
          experience1: payload.experience1,
          experience2: payload.experience2,
          experience3: payload.experience3
        }
      );
    },
    setContactForm(state, payload) {
      state.formContact = Object.assign([], payload);
    },
    setContactAcademyForm(state, payload) {
      state.formContactAcademy = Object.assign([], payload);
    },
  },
};
