<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleForTurnstilesIDCards />
    <CardTurnstilesIDCards />
    <CardTurnstilesIDCardsSecond />
    <CareerTurnstilesIDCards />
    <CardTurnstilesIDCardsThird />
    <ClientsTurnstilesIDCards />
    <BlogTurnstilesIDCards />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../Layout/Navbar";
import PageTitleForTurnstilesIDCards from "../../../TurnstilesIDCards/PageTitleForTurnstilesIDCards.vue";
import CardTurnstilesIDCards from "../../../TurnstilesIDCards/CardTurnstilesIDCards.vue";
import CardTurnstilesIDCardsSecond from "../../../TurnstilesIDCards/CardTurnstilesIDCardsSecond.vue";
import CareerTurnstilesIDCards from "../../../TurnstilesIDCards/CareerTurnstilesIDCards.vue";
import CardTurnstilesIDCardsThird from "../../../TurnstilesIDCards/CardTurnstilesIDCardsThird.vue";
import ClientsTurnstilesIDCards from "../../../TurnstilesIDCards/ClientsTurnstilesIDCards.vue";
import BlogTurnstilesIDCards from "../../../TurnstilesIDCards/BlogTurnstilesIDCards.vue";
import Footer from "../../../Layout/Footer";

export default {
  name: "TurnstilesIDCards",
  components: {
    Navbar,
    PageTitleForTurnstilesIDCards,
    CardTurnstilesIDCards,
    CardTurnstilesIDCardsSecond,
    CareerTurnstilesIDCards,
    CardTurnstilesIDCardsThird,
    ClientsTurnstilesIDCards,
    BlogTurnstilesIDCards,
    Footer,
  },
};
</script>
