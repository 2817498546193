<template>
  <div class="pricing-section pt-60 pb-60">
    <div class="pricing-section-card-third">
      <div class="container">
        <div class="section-title section-title-lg">
          <h2>{{ $t("solutionByIndustries.finance.solInnovative.title") }}</h2>
        </div>
        <div class="row mb-100">
          <div class="col-12 col-md-8 col-lg-4 pb-30">
            <div class="contact-info-item">
              <div class="pricing-info-header">
                <img
                  src="../../assets/images/pricing-difference-1.png"
                  alt="shape"
                />
                <img
                  src="../../assets/images/pricing-difference-icon-1.png"
                  alt="shape"
                  class="pricing-img"
                />
                <div class="pricing-info-header-text">
                  <h6>{{ $t("solutionByIndustries.finance.solInnovative.easyIntegration.title") }}</h6>
                  <p>
                    {{ $t("solutionByIndustries.finance.solInnovative.easyIntegration.info") }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-8 col-lg-4 pb-30">
            <div class="contact-info-item">
              <div class="pricing-info-header">
                <img
                  src="../../assets/images/pricing-difference-2.png"
                  alt="shape"
                />
                <img
                  src="../../assets/images/pricing-difference-icon-2.png"
                  alt="shape"
                  class="pricing-img"
                />
                <div class="pricing-info-header-text">
                  <h6>{{ $t("solutionByIndustries.finance.solInnovative.easySetupAndActivation.title") }}</h6>
                  <p>
                    {{ $t("solutionByIndustries.finance.solInnovative.easySetupAndActivation.info") }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-8 col-lg-4 pb-30">
            <div class="contact-info-item">
              <div class="pricing-info-header">
                <img
                  src="../../assets/images/pricing-difference-3.png"
                  alt="shape"
                />
                <img
                  src="../../assets/images/pricing-difference-icon-3.png"
                  alt="shape"
                  class="pricing-img"
                />
                <div class="pricing-info-header-text">
                  <h6>{{ $t("solutionByIndustries.finance.solInnovative.multioptionalArchitecture.title") }}</h6>
                  <p>
                    {{ $t("solutionByIndustries.finance.solInnovative.multioptionalArchitecture.info") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BlogCountPeople />
      </div>
    </div>
  </div>
</template>

<script>
import BlogCountPeople from "../SingleBlog/BlogCountPeople.vue";
export default {
  name: "CardPricingThird",
  components: {
    BlogCountPeople,
  },
};
</script>
