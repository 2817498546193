<template>
    <div>
        <div class="chat-position" v-if="isActive">
            <section>
                <div class="">

                    <div class="row d-flex justify-content-center">
                        <div class="col-md-10 col-lg-8 col-xl-11">

                            <div class="card" id="chat2">
                                <div class="card-header d-flex justify-content-between align-items-center p-3">
                                    <h5 class="mb-0">PIXBot</h5>
                                    <!-- <button type="button" class="btn btn-primary btn-sm"
                                        style="background-color: #1f12fd;">Let's
                                        Chat
                                        App</button> -->
                                </div>

                                <div class="card-body" style="position: relative; height: 450px;">
                                    <div id="scrollbarItem" class="scrollbar scrollbar-primary" ref="scrollbarItem">
                                        <div class="force-overflow px-3">

                                            <div class="d-flex flex-row justify-content-start mb-2 pe-1">
                                                <div>
                                                    <div class="small p-1 rounded-3" style="background-color: #f5f6f7;">
                                                        <p>
                                                            {{ $t("pixBot.intro1") }}
                                                        </p>

                                                        <p>
                                                           {{ $t("pixBot.intro2") }}</p>
                                                    </div>
                                                    <div v-for="(item, index) in chatBotQuestionsAndAnswers" :value="index"
                                                        :key="item">
                                                        <button
                                                            class="small p-2 ms-1 m-1 rounded-3 text-start hover-shadow hover-overlay"
                                                            style="background-color: #f5f6f7; color: #767676;"
                                                            @click="botsResponse(item.id)">
                                                            {{ item.id }}. {{ item.question }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="d-flex flex-row mb-2" v-for="item in messages" :key="item"
                                                :class="classObjectForMessage(item.author)">
                                                <div v-if="item.id != 6">
                                                    <p class="small p-2 me-3 mb-1 text-white rounded-3"
                                                        style="background-color: #1f12fd;" v-if="item.author == 'visitor'">
                                                        {{ item.message }}
                                                    </p>
                                                    <p class="small p-2 me-3 mb-1 rounded-3"
                                                        style="background-color: #f5f6f7;" v-if="item.author == 'pixbot'">
                                                        {{ item.message }}
                                                    </p>
                                                </div>
                                                <!-- for 6. question -->
                                                <div v-else>
                                                    <p class="small p-2 me-3 mb-1 text-white rounded-3"
                                                        style="background-color: #1f12fd;" v-if="item.author == 'visitor'">
                                                        {{ item.message }}
                                                    </p>
                                                    <div v-if="item.message[0] == '1' || item.message[0] == '2'">
                                                        <button
                                                            class="small p-2 ms-1 m-1 rounded-3 text-start hover-shadow hover-overlay"
                                                            style="background-color: #f5f6f7; color: #767676;"
                                                            @click="routePage(item.message[0])">
                                                            {{ item.message }}
                                                        </button>
                                                    </div>
                                                    <div v-else>
                                                        <p class="small p-2 me-3 mb-1 rounded-3"
                                                            style="background-color: #f5f6f7;"
                                                            v-if="item.author == 'pixbot'">
                                                            {{ item.message }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-3" v-if="showTyping">
                                                <a class="small p-2 rounded-3 text-start"
                                                    style="background-color: #f5f6f7; color: #767676;">
                                                    Typing...
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer text-muted d-flex justify-content-start align-items-center px-4">
                                    <!-- <input type="text" class="form-control form-control-lg" id="exampleFormControlInput1"
                                        placeholder="Type message"> -->
                                    <!--   <a class="ms-3 text-muted" href="#!"><i class="fas fa-smile"></i></a>
                                    <a class="ms-3" href="#!"><i class="fas fa-paper-plane"></i></a> -->
                                    <div @click="clearMessages" class="btn rounded-pill"
                                        style="color: #f5f6f7 ;background-color: #1f12fd ;height: 40px; width: 150px;">
                                        <div class="row">
                                            <div class="col-8"
                                                style="font-weight: bold;font-size: 12px; padding-top: 3px; padding-left: 16px;">
                                                {{$t("pixBot.clearChat")}}</div>
                                            <div class="col-4" style="padding-top: 2px; padding-right: 37px;"><i
                                                    class="fas fa-trash "></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="bot-position" @click="chatBox">
            <button class="bg-transparent" data-bs-toggle="tooltip" style="color : #1f12fd;">
                <i class="fa fa-comments fa-3x" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</template>

<script>
import i18n from '../../plugins/i18n';

export default {
    
    data() {
        return {
            isActive: false,
            activeQuestion: null,
            showTyping: false,
            waitingTime: 2000,
            input: "",
            message: "",
            messages: [],
            chatBotQuestionsAndAnswers: [
                {
                    id: 1,
                    question: i18n.global.t("pixBot.question1"),
                    answer: i18n.global.t("pixBot.answer1")
                },
                {
                    id: 2,
                    question: i18n.global.t("pixBot.question2"),
                    answer: i18n.global.t("pixBot.answer2")
                },
                {
                    id: 3,
                    question: i18n.global.t("pixBot.question3"),
                    answer: i18n.global.t("pixBot.answer3")
                },
                {
                    id: 4,
                    question: i18n.global.t("pixBot.question4"),
                    answer: {
                        ans_1: i18n.global.t("pixBot.answer4.ans_1"),
                        ans_2: i18n.global.t("pixBot.answer4.ans_2")
                    }
                },
                {
                    id: 5,
                    question: i18n.global.t("pixBot.question5"),
                    answer: i18n.global.t("pixBot.answe5")
                },
                {
                    id: 6,
                    question: i18n.global.t("pixBot.question6"),
                    answer: {
                        ans_1:i18n.global.t("pixBot.answer6.ans_1"),
                        ans_2: i18n.global.t("pixBot.answer6.ans_2"),
                        ans_3: i18n.global.t("pixBot.answer6.ans_3"),
                        ans_4: i18n.global.t("pixBot.answer6.ans_4")
                    }
                },
            ]
        };
    },
    methods: {
        chatBox() {
            this.isActive = !this.isActive;
        },
        clearMessages() {
            this.messages = []
        },
        classObjectForMessage(from) {
            return {
                'justify-content-end': from == "visitor",
                'justify-content-start': from == "pixbot",
            }
        },
        routePage(item) {
            if (item == '1') {
                this.$router.push("/PIXCoding-frontend-backend")
            } else if (item == '2') {
                this.$router.push("/saas-cv-membership")
            }
        },
        async answerEditor(id) {
            this.showTyping = true

            if (typeof (this.chatBotQuestionsAndAnswers[id - 1].answer) === "object") {
                for (let index = 0; index < Object.keys(this.chatBotQuestionsAndAnswers[id - 1].answer).length; index++) {

                    await setTimeout(() => {
                        var ans_index = Object.keys(this.chatBotQuestionsAndAnswers[id - 1].answer)[index]
                        this.showTyping = false
                        this.messages.push({
                            id: id,
                            message: this.chatBotQuestionsAndAnswers[id - 1].answer[ans_index],
                            author: "pixbot"
                        })
                    }, this.waitingTime);
                    this.scrollDown()
                }
            } else {
                await setTimeout(() => {
                    this.showTyping = false
                    this.messages.push({
                        id: id,
                        message: this.chatBotQuestionsAndAnswers[id - 1].answer,
                        author: "pixbot"
                    })
                }, this.waitingTime);
                this.scrollDown()
            }
        },
        async botsResponse(id) {
            await this.messages.push({
                id: id,
                message: this.chatBotQuestionsAndAnswers[id - 1].question,
                author: "visitor"
            })
            this.answerEditor(id)
            setTimeout(() => {
                this.scrollDown()
                if (id == 1) {
                    this.$router.push('/contact')
                } else if (id == 2) {
                    this.$router.push('/demo-request')
                } else if (id == 3) {
                    this.$router.push('/contact')
                } else if (id == 4) {
                    this.$router.push('/contact')
                } else if (id == 5) {
                    this.$router.push('/contact')
                } /* else if (id == 6) {
                this.$router.push('/demo-request')
            } */
            }, this.waitingTime + 8);

        },
        scrollDown() {
            this.$refs.scrollbarItem.scrollTop = this.$refs.scrollbarItem.scrollTop + 1000000000
        },
    },

};
</script>

<style scoped>
.card {}

.card-header {
    border-bottom: solid;
    border-color: #1f12fd;
}

.card-footer {}

.bot-position {
    position: fixed;
    bottom: 15px;
    right: 70px;
    font-size: 18px;
}

.chat-position {
    position: fixed;
    bottom: 80px;
    right: 50px;
    font-size: 18px;
}

.scrollbar {
    float: left;
    height: 440px;
    background: #fff;
    overflow-y: scroll;
    margin-bottom: 25px;
}

.force-overflow {
    min-height: 50px;
}

.scrollbar-primary::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #1f12fd;
}

.scrollbar-primary {
    scrollbar-color: #1f12fd #F5F5F5;
}

.small {
    max-width: 550px;
}
</style>
