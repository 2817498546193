<template>
  <div @submit.prevent="onSubmit">
    <div class="row d-flex justify-content-center mt-30">
      <div class="col-md-12">
        <div class="form-group mb-20">
          <label>{{ $t('pixselectAcademy.form.technicalInfo.info1') }}</label>
          <select name="experience" class="form-control" v-model="formAcademy.experience"
            :class="{ 'is-invalid': v$.formAcademy.experience.$error }">
            <option :value="''" style="font-size: 20px">
              Select a total experience
            </option>
            <option v-for="(item, index) in experience" :key="index" :value="item.text" style="font-size: 20px">
              {{ item.text }}
            </option>
          </select>
          <div class="input-errors" v-for="(error, index) of v$.formAcademy.experience.$errors" :key="index">
            <small class="text-danger">{{
              error.$message ==
              "Value is required"
              ? "Total experience is required"
              : error.$message
            }}</small>
          </div>
        </div>
      </div>
      <div v-if="formAcademy.experience != ''">

        <div class="row" v-if="true">
          <div class="col-md-4">
            <div class="form-group mb-20">
              <label>Company Name</label>
              <input type="text" id="companyName" name="companyName" class="form-control" placeholder="Enter Company Name"
                v-model="formAcademy.experience1[0]" />

            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-20">
              <label>Position</label>
              <input type="text" id="position" name="position" class="form-control" placeholder="Enter Company Name"
                v-model="formAcademy.experience1[1]" />

            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-20">
              <label>Start Date</label>
              <Datepicker class="form-control" placeholder="Select a date" inputFormat="dd-MM-yyyy"
                v-model="formAcademy.experience1[2]" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-20">
              <label>End Date</label>
              <Datepicker class="form-control" placeholder="Select a date" inputFormat="dd-MM-yyyy"
                v-model="formAcademy.experience1[3]" />
            </div>
          </div>
        </div>

        <div class="row"
          v-if="formAcademy.experience1[0] != '' && formAcademy.experience1[1] != '' && formAcademy.experience1[2] != ''">
          <div class="col-md-4">
            <div class="form-group mb-20">
              <label>Company Name</label>
              <input type="text" id="companyName" name="companyName" class="form-control" placeholder="Enter Company Name"
                v-model="formAcademy.experience2[0]" />

            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-20">
              <label>Position</label>
              <input type="text" id="position" name="position" class="form-control" placeholder="Enter Company Name"
                v-model="formAcademy.experience2[1]" />

            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-20">
              <label>Start Date</label>
              <Datepicker class="form-control" placeholder="Select a date" inputFormat="dd-MM-yyyy"
                v-model="formAcademy.experience2[2]" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-20">
              <label>End Date</label>
              <Datepicker class="form-control" placeholder="Select a date" inputFormat="dd-MM-yyyy"
                v-model="formAcademy.experience2[3]" />
            </div>
          </div>
        </div>

        <div class="row"
          v-if="formAcademy.experience2[0] != '' && formAcademy.experience2[1] != '' && formAcademy.experience2[2] != ''">
          <div class="col-md-4">
            <div class="form-group mb-20">
              <label>Company Name</label>
              <input type="text" id="companyName" name="companyName" class="form-control" placeholder="Enter Company Name"
                v-model="formAcademy.experience3[0]" />

            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-20">
              <label>Position</label>
              <input type="text" id="position" name="position" class="form-control" placeholder="Enter Company Name"
                v-model="formAcademy.experience3[1]" />

            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-20">
              <label>Start Date</label>
              <Datepicker class="form-control" placeholder="Select a date" inputFormat="dd-MM-yyyy"
                v-model="formAcademy.experience3[2]" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-20">
              <label>End Date</label>
              <Datepicker class="form-control" placeholder="Select a date" inputFormat="dd-MM-yyyy"
                v-model="formAcademy.experience3[3]" />
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-6">
        <div class="form-group mb-20">
          <label for="">
            {{ $t('pixselectAcademy.form.technicalInfo.info2') }}
          </label>
          <input class="form-control" placeholder="Enter your GitHub link" type="text" v-model="formAcademy.github"
          name="github-link" id="github-link" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-20">
          <label>
            {{ $t('pixselectAcademy.form.technicalInfo.info3') }}
          </label>
          <input class="form-control" placeholder="Enter your Linkedin link" type="text" v-model="formAcademy.linkedin"
          name="linkedin-link" id="linkedin-link" />
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-20">
          <label for="">
            {{ $t('pixselectAcademy.form.technicalInfo.info4') }}
          </label>
          <select name="job_departmant" class="form-control" v-model="formAcademy.jobDepartmant">
          
            <option :value="''" style="font-size: 20px">
              Select a field you want to work
            </option>
            <option v-for="(item, index) in jobDepartmants" :key="index" :value="item.text" style="font-size: 20px">
              {{ item.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-20">
          <label for="">
            {{ $t('pixselectAcademy.form.technicalInfo.info5') }}
          </label>
          <select name="job_departmant" class="form-control" v-model="formAcademy.contactPlace"
            :class="{ 'is-invalid': v$.formAcademy.contactPlace.$error }">
            <option :value="''" style="font-size: 20px">
              Select a reach
            </option>
            <option v-for="(item, index) in contactPlace" :key="index" :value="item.text" style="font-size: 20px">
              {{ item.text }}
            </option>
          </select>
          <div class="input-errors" v-for="(error, index) of v$.formAcademy.contactPlace.$errors" :key="index">
            <small class="text-danger">{{ error.$message }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-20">
          aaa
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";
import Datepicker from "vue3-datepicker";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },

  name: "TechnicalInfoForm",
  components: {
    Datepicker,
  },
  data() {
    return {
      jobDepartmants: [
        {
          value: 1,
          text: "Front-end Developer (Javascript, React, Angular,Vue)",
        },
        { value: 2, text: " Back-end Developer (Java, .NET, PHP, Node.js)" },
        { value: 3, text: " Mobile Developer (iOS, Android, React Native)" },
        { value: 4, text: " Data Analyst / Data Scientist" },
        { value: 5, text: " Data Engineer / DevOps / Cloud / Infrastructure" },
        { value: 6, text: "Game Developer" },
        { value: 7, text: "Game Artist" },
        { value: 8, text: "UI/UX Designer" },
        { value: 9, text: " Test Automation / QA" },
        {
          value: 10,
          text: "Business Analyst / Project Manager / Product Manager",
        },
      ],
      experience: [
      { value: 1, text: "0 / 6 months" },
        { value: 2, text: "6 / 12 months" },
        { value: 3, text: "12 / 18 months" },
        { value: 4, text: "18+ months" },
      ],
      contactPlace: [
        { value: 0, text: "Social Medias" },
        { value: 1, text: "Friends" },
        { value: 3, text: "LinkedIn" },
        { value: 4, text: "Career Platforms" },
        { value: 5, text: "Fair" },
        { value: 6, text: "Advertising" },
      ]
    };
  },

  validations() {
    return {
      formAcademy: {
        experience: { required },
        jobDepartmant: { required },
        contactPlace: { required },
      },
    };
  },
  computed: {
    ...mapState("main", ["formAcademy"]),
  },
});
</script>
