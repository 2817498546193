<template>
    <div class="container pt-60 pb-60">
      <div class="section-title section-title-lg">
        <h2>
          {{ $t("solutionByIndustries.logistic.visionLogistic.title") }}
        </h2>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="logistic-visionAi-img">
            <img src="../../assets/images/logistic/logistic-vision.png" alt="shape" />
          </div>
        </div>
        <div class="col-lg-6 p-0">
          <div class="logistic-visionAi">
            <div class="logistic-visionAi-title">
              <h5>
                {{ $t("solutionByIndustries.logistic.visionLogistic.bank") }}
              </h5>
            </div>
          </div>

          <div class="logistic-visionAi-list pt-20">
            <div class="logistic-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic.bank1") }}
              </p>
            </div>

            <div class="logistic-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                {{ $t("solutionByIndustries.logistic.cardPricing.bank2") }}
              </p>
            </div>
            <div class="logistic-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic.bank3") }}
              </p>
            </div>
            <div class="logistic-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic.bank4") }}
              </p>
            </div>
            <div class="logistic-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic.bank5") }}
              </p>
            </div>
            <div class="logistic-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                {{ $t("solutionByIndustries.logistic.visionLogistic.bank6") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "VisionLogistic",
};
</script>
