<template>
    <div class="inner-page-header">
      <div class="container">
        <div class="row">
          <div class="col-sm d-flex align-items-center">
            <div class="inner-header-content-academy">
              <h1>
                {{  $t('partners.pageTitle.title') }}
              </h1>
              <h2>{{  $t('partners.pageTitle.info') }}</h2>
              <br />
            </div>
          </div>
          <div class="col-sm d-flex justify-content-end">
            <img
              class="goods-ai-warehouses-page-header-img"
              src="../../assets/images/partners/header.png"
              style="height: 325px;"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PageTitleForPartners",
  };
  </script>
  