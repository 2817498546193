<template>
  <div class="container pt-60 pb-60">
    <SystemArchitecture :title="title" :imageUrl="url" />

    <div class="section-title section-title-xl pt-60">
      <h2>
        {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.monSysArc.suppHardware") }}
      </h2>
    </div>
    <div class="goods-ai-warehouses-info">
      <div class="row">
        <div class="col-12 col-md-12 col-lg">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-4.png"
            alt="shape"
          />
          <h6>
            {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.monSysArc.hardware1") }}
          </h6>
        </div>
        <div class="col-12 col-md-12 col-lg-1">
          <img
            src="../../assets/images/line.png"
            alt="shape"
            class="goods-ai-warehouses-info-line"
          />
        </div>
        <div class="col-12 col-md-12 col-lg">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-4.png"
            alt="shape"
          />
          <h6>
            {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.monSysArc.hardware2") }}
          </h6>
        </div>
        <div class="col-12 col-md-12 col-lg-1">
          <img
            src="../../assets/images/line.png"
            alt="shape"
            class="goods-ai-warehouses-info-line"
          />
        </div>
        <div class="col-12 col-md-12 col-lg">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-4.png"
            alt="shape"
          />
          <h6>
            {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.monSysArc.hardware3") }}
          </h6>
        </div>
      </div>
    </div>

    <div class="section-title section-title-xl pt-60">
      <h2>
        {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.monSysArc.question") }}
      </h2>
    </div>

    <div class="turnstiles-info-solution">
      <div class="row align-items-center">
        <div class="col-12 col-md col-lg">
          <h6>
            {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.monSysArc.answer-1.title") }}
          </h6>
          <p>
            {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.monSysArc.answer-1.ans-1") }}
          </p>
        </div>
        <div class="col-12 col-md-1 col-lg-1 d-flex justify-content-center">
          <img
            src="../../assets/images/line.png"
            alt="shape"
            class="goods-ai-warehouses-info-line"
          />
        </div>
        <div class="col-12 col-md col-lg">
          <h6>
            {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.monSysArc.answer-2.title") }}
          </h6>
          <p>
            {{ $t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.monSysArc.answer-2.ans-1") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SystemArchitecture from "../Common/SystemArchitecture.vue";
import i18n from "../../plugins/i18n";

export default defineComponent({
  name: "CareerTurnstilesFaceRecognition",
  components: {
    SystemArchitecture,
  },
  data() {
    return {
      url: require("../../assets/images/turnstiles-face-recognition/isometric.png"),
      title: i18n.global.t("products.PIXPassAccessAndLogSolutions.turnstilesAndDoorsAccess.byFaceRecognition.monSysArc.title"),
    };
  },
});
</script>
